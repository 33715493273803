import firebase from 'firebase/app'
import 'firebase/analytics'

// FROM: https://medium.com/@BenWynneSimmons/adding-firebase-analytics-and-firestore-to-a-react-next-js-app-bffffc2f638e

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

console.log('Attempting to add Firebase Analytics')

if (process.env.REACT_APP_FIREBASE_APP_ID === '') {
  console.log('Ignoring firebase, no REACT_APP_FIREBASE_APP_ID set.')
}
// Check that `window` is in scope for the analytics module
else if (typeof window === 'undefined') {
  console.log(
    'Ignoring firebase, no available window (typeof window === "undefined")',
  )
} else if (firebase.apps.length > 0) {
  console.log('Firebase already set up, firebase.apps.length > 0')
} else {
  firebase.initializeApp(firebaseConfig)
  // Enable analytics
  if (firebaseConfig['measurementId'] === '') {
    console.log('Ignoring firebase/analytics, no measurementId set.')
  } else {
    firebase.analytics()
  }
}

export default firebase
