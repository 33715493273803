import {createStore, applyMiddleware} from 'redux'
import createHistory from 'history/createBrowserHistory'
import throttle from 'lodash/throttle'
import logger from './utils/logger'
import reducers from './redux/reducers/index'
import * as localStorage from './utils/localStorage'

export const history = createHistory()

const configureStore = () => {
  var store
  const persistedState = localStorage.loadState()

  if (process.env.NODE_ENV === 'development') {
    const createStoreWithMiddleware = applyMiddleware(logger)(createStore)

    store = createStoreWithMiddleware(
      reducers,
      persistedState,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__(),
    )
  } else {
    const createStoreWithMiddleware = applyMiddleware()(createStore)

    store = createStoreWithMiddleware(reducers, persistedState)
  }

  store.subscribe(
    throttle(() => {
      const state = store.getState()
      localStorage.saveState(state)
    }, 1000),
  )

  return store
}

export const store = configureStore()
