import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import queryString from 'query-string'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import transactions_icon from '../../img/transactions_icon.svg'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import {buildColumnDefault, buildTableEmpty} from '../../utils/table'

class SearchParties extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      error: '',
      loading: true,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search)
    const search = values.q

    this.setState(
      {
        search: search,
      },
      this.getTableData,
    )
  }

  componentDidUpdate() {
    const values = queryString.parse(this.props.location.search)
    const search = values.q

    if (this.state.search !== search) {
      this.setState(
        {
          search: search,
        },
        this.getTableData,
      )
    }
  }

  getTableData() {
    NProgress.start()

    this.setState({
      data: [],
      loading: true,
    })

    const companyId = this.state.userAccount.company.id
    const searchString = encodeURI(this.state.search)

    return this.props.api
      .partySearch(companyId, searchString)
      .then((response) => {
        let flatData = jsonApiSpecToFlatObject(
          response.data.data,
          response.data.included,
        )

        if (flatData.length > 0) {
          flatData[0].transaction_count =
            response.data.meta.raw[0].transaction_count
        }

        this.setState({
          data: flatData,
        })
      })
      .catch((error) => {
        this.setState({
          data: [],
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
        NProgress.done()
      })
  }

  render() {
    const columns = [
      buildColumnDefault('client_name', 'Name'),
      buildColumnDefault('phone_sms', 'Phone Number'),
      buildColumnDefault('address', 'Address'),
      {
        id: 'transaction_count',
        Header: 'Transactions',
        headerClassName: 'text-centered',
        accessor: (d) => d.transaction_count,
        Cell: (row) => <div style={{textAlign: 'center'}}>{row.value}</div>,
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(appPaths.PartiesRead(rowInfo.original.id))
        },
      }
    }

    const tableNoResults = () => {
      return buildTableEmpty(transactions_icon, this.state.error)
    }

    return (
      <React.Fragment>
        <div>
          <h2>Results</h2>
          <div>
            <ReactTableCommon
              tableData={this.state.data}
              isLoading={this.state.loading}
              columns={columns}
              onRowClick={onRowClick}
              tableNoResults={tableNoResults}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(SearchParties)))
