import React from 'react'
import {validateDateInputValue} from '../../utils/validate'
import {formatDateField} from '../../utils/formater'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import _ from 'lodash'

/**
 * Component for handling the input of dates from the user.
 * For validation use, make sure the field id ends with "Date"
 *
 * @param {Props} props The props of the DateInput. Expected:
 *  id - Id of the field (used to make the label and error ids)
 *  title - Title of the field
 *  date - The date to show on the input
 *  isRequired - (default: true) Boolean if the field is required or not
 *  isEditing - (default: false) Boolean if the field is being edited or not
 *  onChanged - The callback for the value that is entered (if validate by the internal component). Returns the id and value properly formatted for the UI
 */
const DateInput = (props) => {
  const {id, title, date, isRequired, isEditing, onChange} = props

  const handleInput = (e) => {
    const nextValue = e.target.value

    if (!validateDateInputValue(nextValue)) {
      return
    }

    onChange(e, id, formatDateField(date, nextValue))
  }

  return (
    <div className="form__group">
      {!_.isNil(title) && (
        <div className="row">
          <label id={id + 'Label'}>{title}</label>
        </div>
      )}
      {isEditing ? (
        <div>
          <input
            type="text"
            name={id}
            className="form__control"
            value={date}
            onChange={handleInput}
            placeholder="yyyy-mm-dd"
            title={title}
            required={isRequired}
          />
          <div className="error" id={id + 'Error'} />
        </div>
      ) : (
        <p>
          {date === 'N/A' || _.isNil(date) || _.isEmpty(date) ? (
            'N/A'
          ) : (
            <Moment format="YYYY-MM-DD">{date}</Moment>
          )}
        </p>
      )}
    </div>
  )
}

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

DateInput.defaultProps = {
  isRequired: true,
  isEditing: false,
}

export default DateInput
