import {
  MAX_DATE_LENGTH,
  YEAR_LENGTH,
  DATE_SEPARATOR,
  YEAR_SEPARATOR_MONTH_LENGTH,
} from '../constants'
import _ from 'lodash'

/**
 * Formats the string as characters are being entered to follow the date pattern of yyyy-mm-dd
 *
 * @param {string} previousValue - The previous date field string. Used to tell if we are deleting or adding a character.
 * @param {string} nextValue - The next date field string.
 *
 * @returns A string formatted to follow the yyyy-mm-dd format
 */
export const formatDateField = (previousValue, nextValue) => {
  if (nextValue === '') {
    return ''
  }

  var finalValue = previousValue

  // Value is not at max length
  if (nextValue.length <= MAX_DATE_LENGTH) {
    // Backspace
    if (!_.isNil(previousValue) && nextValue.length < previousValue.length) {
      if (
        nextValue.length === YEAR_LENGTH ||
        nextValue.length === YEAR_SEPARATOR_MONTH_LENGTH
      ) {
        finalValue = nextValue.substring(0, nextValue.length - 1)
      } else {
        finalValue = nextValue
      }
    } else {
      if (nextValue.length === YEAR_LENGTH) {
        finalValue = nextValue + DATE_SEPARATOR
      } else if (nextValue.length === YEAR_SEPARATOR_MONTH_LENGTH) {
        finalValue = nextValue + DATE_SEPARATOR
      } else {
        finalValue = nextValue
      }
    }
  }

  return finalValue
  // Return the value as entered value + DATE_FORMAT to show what is required next
  // return (
  //   finalValue +
  //   DATE_FORMAT.substring(
  //     finalValue.length - 1,
  //     DATE_FORMAT - finalValue.length,
  //   )
  // )
}
