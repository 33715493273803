import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

/**
 * Component for handling the input of dates from the user.
 * For validation use, make sure the field id ends with "Date"
 *
 * @param {Props} props The props of the DateInput. Expected:
 *  id - Id of the field (used to make the label and error ids)
 *  title - Title of the field
 *  placeholder - (default: '') The placeholder text to show
 *  rows - The number of rows to show
 *  value - The date to show on the input
 *  isRequired - (default: true) Boolean if the field is required or not
 *  isEditing - (default: false) Boolean if the field is being edited or not
 *  onChanged - The callback for the value that is entered (if validate by the internal component). Returns the id and value properly formatted for the UI
 */
const TextArea = (props) => {
  const {
    id,
    title,
    value,
    placeholder,
    rows,
    isRequired,
    isEditing,
    onChange,
  } = props

  const handleInput = (e) => {
    onChange(e, id, e.target.value)
  }

  return (
    <div className="form__group">
      {!_.isNil(title) && (
        <div className="row">
          <label id={id + 'Label'}>{title}</label>
        </div>
      )}
      {isEditing ? (
        <div>
          <textarea
            type="text"
            name={id}
            rows={rows}
            className="form__control"
            value={value}
            onChange={handleInput}
            placeholder={placeholder}
            title={title}
            required={isRequired}
          />
          <div className="error" id={id + 'Error'} />
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  )
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

TextArea.defaultProps = {
  placeholder: '',
  rows: 3,
  isRequired: true,
  isEditing: false,
}

export default TextArea
