import {daysPerMonth} from '../constants'

export const MONTH_INDEX_JAN = 1
export const MONTH_INDEX_FEB = 2
export const MONTH_INDEX_MAR = 3
export const MONTH_INDEX_APR = 4
export const MONTH_INDEX_MAY = 5
export const MONTH_INDEX_JUN = 6
export const MONTH_INDEX_JUL = 7
export const MONTH_INDEX_AUG = 8
export const MONTH_INDEX_SEP = 9
export const MONTH_INDEX_OCT = 10
export const MONTH_INDEX_NOV = 11
export const MONTH_INDEX_DEC = 12

/**
 * Tells you the number of days for a given month. The month should be given using the index as
 * 1  - Jan
 * 2  - Feb
 * 3  - Mar
 * 4  - Apr
 * 5  - May
 * 6  - Jun
 * 7  - Jul
 * 8  - Aug
 * 9  - Sep
 * 10 - Oct
 * 11 - Nov
 * 12 - Dec
 *
 * @param {int} year The year so that we can account for a leap year
 * @param {int} month The month to send back the number of days for
 *
 * @returns The number of days in the given month or null if outside the index of the array
 */
export const daysForMonth = (year, month) => {
  if (month < MONTH_INDEX_JAN || month > daysPerMonth.length) {
    return null
  }

  if (month === MONTH_INDEX_FEB && year % 4 === 0) {
    return daysPerMonth[month - 1] + 1
  } else {
    return daysPerMonth[month - 1]
  }
}
