import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Utils
import {appPaths} from '../../utils/appPaths'
import {validateInput, showFormErrors} from '../../utils/validate'
// Components
import FormError from '../common/FormError'
// Images
import hand_shake from '../../img/hand_shake.png'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      viaEmail: false,
      error: '',
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    // Validate email on input
    if (e.target.name === 'email') {
      validateInput(this, e)
    }
  }

  handleHideModal = (e) => {
    this.props.history.push(appPaths.Login)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      // Check for form errors
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()
      this.props.api
        .passwordForgot({email: this.state.email})
        .then((response) => {
          this.props.history.push(appPaths.PasswordForgotSent + '/1')
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
        })
    }
  }

  handleViaEmail = (e) => {
    // Show via email input
    this.setState({viaEmail: true})
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal modal__two">
          <button
            type="button"
            className="close link"
            onClick={this.handleHideModal}
          >
            <i className="fas fa-times" />
          </button>
          <div className="modal__form">
            <div className="modal__inner">
              <h1>Forgot Password</h1>
              {this.state.viaEmail ? (
                <form method="post" onSubmit={this.handleSubmit} noValidate>
                  <div className="form__group">
                    <div className="row">
                      <label id="emailLabel">Email</label>
                    </div>
                    <input
                      type="email"
                      name="email"
                      ref="email"
                      value={this.state.email}
                      onChange={this.handleInput}
                      placeholder="Enter email"
                      title="Email"
                      required
                    />
                    <div className="error" id="emailError" />
                  </div>
                  <FormError error={this.state.error} />
                  <div className="form__group">
                    <div className="row">
                      <button
                        type="submit"
                        className="btn btn--blue-solid"
                        value="Send Recovery Email"
                      >
                        Send Recovery Email
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div>
                  <p>Select Via Email to send password reset instructions</p>
                  <br />
                  <div className="form__group">
                    <button
                      type="button"
                      className="recovery link"
                      onClick={this.handleViaEmail}
                    >
                      <div>
                        <div className="left">
                          <i className="far fa-envelope-open" />
                        </div>
                        <div>
                          <p>Via Email</p>
                          <span className="circle__group">
                            <span className="circle circle--blue" />
                            <span className="circle circle--blue" />
                            <span className="circle circle--blue" />
                          </span>
                          <span className="circle__group">
                            <span className="circle circle--blue" />
                            <span className="circle circle--blue" />
                            <span className="circle circle--blue" />
                          </span>
                          <span>ey@mail.com</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal__mark">
            <img src={hand_shake} className="modal__img" alt="logo" />
            <div className="top__angle" />
            <div className="bottom__angle" />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withApi(withRouter(ForgotPassword))
