import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
import TransactionCreate from './TransactionCreate'
// Images
import large_add from '../../img/large_add.svg'
import transactions_icon from '../../img/transactions_icon.svg'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'

class TransactionReadAllTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: this.props.match.params.ac,
      error: '',
      handleCreate: false,
      helpfulHint: false,
      loading: true,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.getTableData()

    if (
      !localStorage.helpHintTransaction &&
      this.state.userAccount.show_hints
    ) {
      this.setState({
        helpfulHint: true,
      })
    }
  }

  handleCloseCreate = (e) => {
    this.setState({
      handleCreate: false,
    })
  }

  handleCreate = (e) => {
    this.setState({
      handleCreate: true,
    })
  }

  handleDataRefresh = (e) => {
    this.getTableData()
  }

  handleHelpfulHint = (e) => {
    this.setState({
      helpfulHint: false,
    })

    localStorage.helpHintTransaction = true
  }

  getTableData() {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    this.setState({
      loading: true,
    })

    return this.props.api
      .transactionReadAll(companyId, null, null)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const columns = [
      {
        id: 'id',
        Header: 'Transaction ID',
        accessor: (d) => d.guid,
      },
      {
        id: 'name',
        Header: 'Transaction Name',
        accessor: (d) => d.name,
      },
      {
        id: 'createdAt',
        Header: 'Creation Date',
        accessor: (d) => {
          if (d.created_at === null || d.created_at === '') {
            return 'N/A'
          } else {
            return <Moment format="MMM D, YYYY">{d.created_at.date}</Moment>
          }
        },
      },
      {
        id: 'closingDate',
        Header: 'Transaction Date',
        accessor: (d) => {
          if (
            d.closing_date === null ||
            d.closing_date === undefined ||
            d.closing_date === ''
          ) {
            return 'N/A'
          } else {
            return <Moment format="MMM D, YYYY">{d.closing_date.date}</Moment>
          }
        },
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(appPaths.TransactionRead(rowInfo.original.id))
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={transactions_icon} alt="Transactions Icon" />
            <p>
              {this.state.error ? this.state.error : ' No Transactions Found'}
            </p>
          </div>
        </div>
      )
    }

    let tableData = []
    if (this.props.accountId) {
      tableData = _.filter(this.props.transactions, (transaction) => {
        return transaction.owner.id === this.props.accountId
      })
    } else {
      tableData = this.props.transactions
    }

    return (
      <React.Fragment>
        {(this.state.userAccount.role.id === 2 ||
          this.state.userAccount.role.id === 3) &&
          !this.state.accountId && (
            <button
              type="button"
              className="link right"
              onClick={this.handleCreate}
            >
              <img src={large_add} alt="Large Add" />
            </button>
          )}

        <div>
          {this.state.helpfulHint && (
            <div className="helpful__hint" onClick={this.handleHelpfulHint}>
              <div className="arrow-up" />
              <h2>Transactions</h2>
              <p>
                Each transaction is composed of multiple documents. You can
                create a transaction and then add documents, or vice-versa.
              </p>
            </div>
          )}
          <ReactTableCommon
            tableData={tableData}
            columns={columns}
            onRowClick={onRowClick}
            tableNoResults={tableNoResults}
            isLoading={this.state.loading}
          />
        </div>

        {this.state.handleCreate && (
          <TransactionCreate
            handleCloseCreate={this.handleCloseCreate}
            handleDataRefresh={this.handleDataRefresh}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let transactions = _.map(
    state.transactions.data,
    (transaction, key) => transaction,
  )

  if (!_.isNil(ownProps.accountId)) {
    transactions = _.filter(
      transactions,
      (transaction) => transaction.owner.id === ownProps.accountId,
    )
  }

  return {
    account: state.account,
    transactions,
  }
}

export default withApi(
  withRouter(connect(mapStateToProps)(TransactionReadAllTable)),
)
