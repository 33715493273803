import React from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from './utils/appPaths'
// Components
import ForgotPassword from './components/account/ForgotPassword'
import Login from './components/account/Login'
import Home from './components/Home'
import Payment from './components/subscribers/Payment'
import ValidateChangePassword from './components/account/ValidateChangePassword'
import ChangePassword from './components/account/ChangePassword'
// CSS
import './css/vendor/nprogress.css'
import './css/vendor/all.min.css'

import './utils/firebase'

const App = () => {
  return (
    <Switch>
      {/* Login */}
      <Route exact path="/" component={Login} />

      {/* Password */}
      <Route path={appPaths.PasswordForgotSent + '/:pfs'} component={Login} />
      <Route path={appPaths.ForgotPassword} component={ForgotPassword} />
      <Route
        path={appPaths.AccountValidation}
        component={ValidateChangePassword}
      />
      <Route
        path={appPaths.ChangePassword + '/:pr'}
        component={ChangePassword}
      />
      <Route path={appPaths.PasswordChanged + '/:pc'} component={Login} />

      {/* Payment */}
      <Route path={appPaths.Payment} component={Payment} />

      {/* About */}
      <Route path={appPaths.TermsAndConditions} component={Home} />

      {/* Account */}
      <Route path={appPaths.Account} component={Home} />

      {/* Parties */}
      <Route path={appPaths.Parties} component={Home} />

      {/* Document */}
      <Route path={appPaths.Document(':ti')} component={Home} />

      {/* Search */}
      <Route path={appPaths.Search} component={Home} />

      {/* Subscribers */}
      <Route path={appPaths.Subscribers} component={Home} />

      {/* Transaction */}
      <Route path={appPaths.Transaction} component={Home} />

      {/* Route not found */}
      <Route component={Login} />
    </Switch>
  )
}

export default withRouter(App)
