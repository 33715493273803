import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import {Document, Page, pdfjs} from 'react-pdf'
import {Stage, Layer, Image, Group} from 'react-konva'
import _ from 'lodash'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
// Images
import left_arrow from '../../img/left_arrow.svg'
import right_arrow from '../../img/right_arrow.svg'
import withApi from '../../api/withApi'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class DocumentPDFView extends Component {
  constructor(props) {
    super(props)

    const userAccount = checkUserAccount(this)
    const companyId = userAccount.company.id
    const transactionId = props.match.params.ti
    const documentId = props.documentId || props.match.params.di

    const url = props.api.documentDownloadUrl(
      companyId,
      transactionId,
      documentId,
    )

    // Save file Options into the state
    // https://github.com/wojtekmaj/react-pdf/issues/353#issuecomment-464712419
    const fileOptions = {
      url,
      httpHeaders: {
        Authorization: `${this.props.api.token}`,
      },
    }

    this.state = {
      documentId,
      transactionId,
      loginToken: checkToken(this),
      userAccount,

      error: '',

      numPages: null,
      pageNumber: 1,
      canvasWidth: 0,
      canvasHeight: 0,
      images: [],

      fileOptions,
    }
  }

  returnPages = (numPages) => {
    let pages = []

    if (numPages === 1) {
      this.setState({
        checked: [true],
        selectAll: true,
      })
    } else {
      for (let i = 0; i < numPages; i++) {
        pages.push(false)
      }

      this.setState({
        checked: pages,
      })
    }

    this.resizeCanvas()
  }

  resizeCanvas = (e) => {
    this.container &&
      this.setState({
        canvasWidth: this.container.offsetWidth,
        canvasHeight: this.container.offsetHeight,
      })
  }

  onDocumentLoadSuccess = ({numPages}) => {
    this.setState({numPages})

    window.addEventListener('resize', _.throttle(this.resizeCanvas, 200), false)

    _.throttle(this.resizeCanvas, 200)

    this.returnPages(numPages)
  }

  onDocumentRender = () => {
    this.resizeCanvas()
  }

  handlePrevious = (e) => {
    if (this.state.pageNumber <= 1) {
      this.setState({
        pageNumber: 1,
      })
    } else {
      this.setState({
        pageNumber: this.state.pageNumber - 1,
      })
    }
  }

  handleNext = ({numPages}) => {
    if (this.state.pageNumber >= this.state.numPages) {
      this.setState({
        pageNumber: this.state.numPages,
      })
    } else {
      this.setState({
        pageNumber: this.state.pageNumber + 1,
      })
    }
  }

  render() {
    const {fileOptions, pageNumber, numPages} = this.state

    return (
      <React.Fragment>
        <div>
          <div className="pdf__arrows flex">
            <button
              type="button"
              className="link"
              onClick={this.handlePrevious}
            >
              <img src={left_arrow} alt="Left Arrow" />
            </button>
            <span>
              {_.isNil(numPages) ? '--' : pageNumber} of{' '}
              {_.isNil(numPages) ? '--' : numPages}
            </span>

            <button type="button" className="link" onClick={this.handleNext}>
              <img src={right_arrow} alt="Right arrow" />
            </button>
          </div>
          <div ref={(el) => (this.container = el)}>
            <Document
              file={fileOptions}
              onLoadSuccess={this.onDocumentLoadSuccess}
              scale={3}
            >
              <Page
                pageNumber={pageNumber}
                scale={3}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onRenderSuccess={this.onDocumentRender}
              />
            </Document>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(DocumentPDFView)))
