import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
// Components
import FormError from '../common/FormError'

class DocumentEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.documentId,
      error: '',
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseEdit()
  }

  handleSubmit = (e) => {
    this.props.history.push(
      appPaths.DocumentPrepare(this.state.transactionId, this.state.documentId),
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Edit Document</h5>
                    <p>
                      Some parties have already signed these documents. If you
                      make changes, they will need to initial all changes, or
                      revisit and resign the document.
                    </p>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <FormError error={this.state.error} />
                      <div className="form__group">
                        <button
                          type="submit"
                          className="col-40 btn btn--mod-2 btn--orange"
                          value="Create"
                        >
                          Edit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withRouter(connect(mapStateToProps)(DocumentEdit))
