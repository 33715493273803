import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Dropzone from 'react-dropzone'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
// Components
import FormError from '../common/FormError'
// Styles
import 'react-sweet-progress/lib/style.css'
// Images
import document_close from '../../img/document_close.svg'
import document_upload from '../../img/document_upload.svg'
import {buildUrl, networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'

class DocumentReplace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.match.params.di,
      error: '',
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseReplace()
  }

  handleUpload = (acceptedFiles) => {
    if (acceptedFiles) {
      acceptedFiles.forEach(function (val, index) {
        NProgress.start()

        const FormData = require('form-data')
        const formData = new FormData()
        formData.append('file', val)

        const companyId = this.state.userAccount.company.id
        const transactionId = this.state.transactionId
        const documentId = this.state.documentId
        let url = buildUrl(
          `/company/${companyId}/transaction/${transactionId}/document/${documentId}/replace`,
        )

        this.props.api
          .post(url, formData, {
            headers: {
              'Content-Type':
                'multipart/form-data; charset=utf-8; boundary=__X_PAW_BOUNDARY__',
            },
          })
          .then((response) => {
            this.props.handleCloseReplace()

            this.props.history.replace(
              appPaths.DocumentRead(
                this.state.transactionId,
                response.data.data.id,
              ),
            )
          })
          .catch((error) => {
            let errorStr = networkErrorDetail(error)
            if (errorStr.toLowerCase().indexOf('guid') >= 0) {
              errorStr = 'A document with that name already exists.'
            }
            this.setState({
              error: errorStr,
            })
          })
          .finally(() => {
            NProgress.done()
          })
      }, this)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="tile">
                <div>
                  <div className="dashbaord__tile full">
                    <div className="inner__tile">
                      <button
                        type="button"
                        className="link right"
                        onClick={this.handleHideModal}
                      >
                        <img src={document_close} alt="DOcument Close" />
                      </button>
                      <h2>Replace Document</h2>
                      <div className="docs__upload">
                        <div className="inner no-padding">
                          <div>
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                this.handleUpload(acceptedFiles)
                              }
                              acceptedFiles=".pdf"
                              multiple={true}
                              clickable={false}
                            >
                              {({getRootProps, getInputProps}) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <img
                                      src={document_upload}
                                      alt="DOcument Upload"
                                    />
                                    <p>
                                      Drag and drop to upload <br /> or{' '}
                                      <button type="button" className="link">
                                        browse
                                      </button>{' '}
                                      to choose a file(s).
                                    </p>
                                    <FormError error={this.state.error} />
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(DocumentReplace)))
