// React
import React from 'react'
// Networking
import axios from 'axios'
// Routing
import {withRouter} from 'react-router-dom'
// Storage
import {connect} from 'react-redux'
import {compose} from 'redux'
import {deleteCookie, checkToken, setUserAccount} from '../utils/loginToken'
// Utils
import {appPaths} from '../utils/appPaths'
import {
  updateLoginToken,
  clearReduxCache,
  updateUserAccount,
} from '../redux/actions/account'
import {
  updateLockedTransactionId,
  updatePricing,
  updateRegions,
} from '../redux/actions/preferences'
import {showTransactionLockedModal} from '../redux/actions/modals'
import _ from 'lodash'
import * as constants from './constants'
import {buildUrl} from '../utils/urls'
import {jsonApiSpecToFlatObject} from '../utils/jsonapispec'
import {
  updateTransactions,
  updateTransaction,
  deleteTransaction,
} from '../redux/actions/transactions'
import {updateParties, updateParty, deleteParty} from '../redux/actions/parties'
import {updateRoles} from '../redux/actions/roles'
import {
  updateCompanies,
  updateCompany,
  deleteCompany,
} from '../redux/actions/company'
import {
  updateAccounts,
  updateAccount,
  updateAccountCompany,
  deleteAccount,
} from '../redux/actions/accounts'
import {
  updateDocuments,
  updateDocumentsWithoutReplace,
  updateDocument,
  deleteDocument,
} from '../redux/actions/documents'

/**
 * A HOC that wraps the given component to include an instance of axios with the baseURL and Authorization token set if the user is logged in.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped with the API for a centralized place to create the API and add authorization headers automatically.
 *
 * @returns The WrappedComponent with the additional api prop that is an Axios instance formatted with the baseURL and Authorization token if the user is logged in.
 */
const ApiProvider = (WrappedComponent) => (props) => {
  const loginToken = checkToken(this)
  const {clearReduxCache, updateLoginToken, showTransactionLockedModal} = props

  var axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
  })

  axiosInstance.interceptors.request.use(
    (config) => {
      if (!_.has(config.headers, constants.HTTP_HEADER_AUTHORIZATION)) {
        config.headers[constants.HTTP_HEADER_AUTHORIZATION] = !_.isNil(
          loginToken,
        )
          ? `${loginToken}`
          : config.headers[constants.HTTP_HEADER_AUTHORIZATION]
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (
        !_.isNil(error) &&
        !_.isNil(error.response) &&
        !_.isNil(error.response.data) &&
        !(error.response.data instanceof Blob) &&
        !_.isNil(error.response.data.errors) &&
        error.response.data.errors.length > 0 &&
        !_.isNil(error.response.data.errors[0].detail)
      ) {
        const errorMsgLowerCase =
          error.response.data.errors[0].detail.toLowerCase()
        // Issue with the token being used. Logout the user and present the login page.
        if (
          errorMsgLowerCase.indexOf('token not found') >= 0 ||
          errorMsgLowerCase.indexOf('expired token') >= 0
        ) {
          updateLockedTransactionId(null)
          deleteCookie('loginToken')
          updateLoginToken(null)

          clearReduxCache()
          localStorage.redirect = props.location.pathname
          props.history.push(appPaths.Login)
          return
        } else if (errorMsgLowerCase.indexOf('transaction in use') >= 0) {
          updateLockedTransactionId(null)
          console.log('show transaction locked modal')
          if (
            !_.isNil(error.response.data.errors[0].meta) &&
            !_.isNil(error.response.data.errors[0].meta.name)
          ) {
            showTransactionLockedModal(error.response.data.errors[0].meta.name)
          } else {
            showTransactionLockedModal('Unknown')
          }
          return
        }
      }

      return Promise.reject(error)
    },
  )

  return (
    <WrappedComponent
      api={new API(loginToken, axiosInstance, props)}
      {...props}
    />
  )
}

class API {
  /**
   * Construct the API object
   * @param {object} token - The user token to use in the requests
   * @param {object} apiInstance - The axios api instance to make the requests with
   */
  constructor(token, apiInstance, actions) {
    this.token = token
    this.apiInstance = apiInstance
    this.actions = actions
  }

  lockTransaction = (companyId, transactionId) => {
    let url = buildUrl(
      constants.ENDPOINT_LOCK_TRANSACTION(companyId, transactionId),
    )
    return this.put(url)
  }

  unlockTransaction = (companyId, transactionId) => {
    let url = buildUrl(
      constants.ENDPOINT_UNLOCK_TRANSACTION(companyId, transactionId),
    )
    return this.put(url)
  }

  transactionReadAll = (companyId, filter, sort) => {
    let page = null
    let pageSize = null

    let filterBy = null
    if (!_.isNil(filter)) {
      filterBy = `filter=${filter}`
    }

    let sortBy = null
    if (!_.isNil(sort) && sort !== '') {
      sortBy = `sort=${sort}`
    }

    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_READ_ALL(companyId),
      page,
      pageSize,
      filterBy,
      sortBy,
    )
    return this.get(url).then((response) => {
      let transactions = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateTransactions(transactions)

      return response
    })
  }

  transactionRead = (companyId, transactionId) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_READ(companyId, transactionId),
    )
    return this.get(url).then((response) => {
      let transaction = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateTransaction(transaction)

      return response
    })
  }

  transactionCreate = (companyId, body) => {
    let url = buildUrl(constants.ENDPOINT_TRANSACTION_CREATE(companyId))
    return this.post(url, body).then((response) => {
      let transaction = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateTransaction(transaction)

      return response
    })
  }

  transactionUpdate = (companyId, transactionId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_UPDATE(companyId, transactionId),
    )
    return this.put(url, body).then((response) => {
      let transaction = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateTransaction(transaction)

      return response
    })
  }

  transactionDelete = (companyId, transactionId) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_DELETE(companyId, transactionId),
    )
    return this.delete(url).then(() => {
      this.actions.deleteTransaction({id: transactionId})
    })
  }

  transactionPartiesReadAll = (companyId, transactionId, filter, sort) => {
    let page = null
    let pageSize = null

    let filterBy = null
    if (!_.isNil(filter)) {
      filterBy = `filter=${filter}`
    }

    let sortBy = null
    if (!_.isNil(sort) && sort !== '') {
      sortBy = `sort=${sort}`
    }
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_PARTIES_READ_ALL(companyId, transactionId),
      page,
      pageSize,
      filterBy,
      sortBy,
    )
    return this.get(url)
  }

  transactionPartiesCreate = (companyId, transactionId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_PARTIES_CREATE(companyId, transactionId),
    )
    return this.post(url, body).then((response) => {
      let transaction = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateTransaction(transaction)

      return response
    })
  }

  transactionPartiesDelete = (companyId, transactionId, config) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_PARTIES_DELETE(companyId, transactionId),
    )
    return this.delete(url, config).then((response) => {
      let transaction = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateTransaction(transaction)

      return response
    })
  }

  transactionClose = (companyId, transactionId) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_CLOSE(companyId, transactionId),
    )
    return this.put(url)
  }

  transactionAbort = (companyId, transactionId) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_ABORT(companyId, transactionId),
    )
    return this.put(url).then((response) => {
      let transaction = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateTransaction(transaction)

      return response
    })
  }

  transactionDocumentsDownload = (companyId, transactionId) => {
    let url = buildUrl(
      constants.ENDPOINT_TRANSACTION_DOCUMENTS_DOWNLOAD(
        companyId,
        transactionId,
      ),
    )
    return this.get(url, {
      responseType: 'arraybuffer',
    })
  }

  transactionSearch = (companyId, term) => {
    let url = buildUrl(constants.ENDPOINT_TRANSACTION_SEARCH(companyId))
    url += `?q=${term}`

    return this.get(url)
  }

  /**
   *
   * @param {number} companyId
   * @param {number} transactionId
   * @param {boolean} uploadInProgress
   * @param {string} sort
   *
   * @returns {Promise}
   */
  documentReadAll = (
    companyId,
    transactionId,
    sort = 'document.displayOrder',
  ) => {
    let page = null
    let pageSize = null
    let filterBy = null
    let sortBy = null
    if (!_.isNil(sort)) {
      sortBy = `sort=${sort}`
    }

    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_READ_ALL(companyId, transactionId),
      page,
      pageSize,
      filterBy,
      sortBy,
    )
    return this.get(url).then((response) => {
      const {
        data: {data, included},
      } = response

      const flatData = jsonApiSpecToFlatObject(data, included)
      this.actions.updateDocuments(transactionId, flatData)
      return response
    })
  }

  documentRead = (companyId, transactionId, documentId) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_READ(companyId, transactionId, documentId),
    )
    return this.get(url).then((response) => {
      const {
        data: {data, included},
      } = response

      const flatData = jsonApiSpecToFlatObject(data, included)
      this.actions.updateDocument(flatData)
      return response
    })
  }

  documentCreate = (companyId, transactionId, formData) => {
    const url = buildUrl(
      constants.ENDPOINT_DOCUMENT_CREATE(companyId, transactionId),
    )
    return this.post(url, formData, {
      headers: {
        'Content-Type':
          'multipart/form-data; charset=utf-8; boundary=__X_PAW_BOUNDARY__',
      },
    }).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateDocument(flatData)

      return response
    })
  }

  documentUpdate = (companyId, transactionId, documentId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_UPDATE(companyId, transactionId, documentId),
    )
    return this.put(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateDocument(flatData)

      return response
    })
  }

  documentUpdateAll = (companyId, transactionId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_UPDATE_ALL(companyId, transactionId),
    )
    return this.put(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateDocumentsWithoutReplace(flatData)

      return response
    })
  }

  documentDelete = (companyId, transactionId, documentId) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_DELETE(companyId, transactionId, documentId),
    )
    return this.delete(url).then((response) => {
      this.actions.deleteDocument({id: documentId})
      return response
    })
  }

  documentUploadComplete = (companyId, transactionId) => {
    const url = buildUrl(
      constants.ENDPOINT_DOCUMENT_COMPLETE(companyId, transactionId),
    )
    return this.post(
      url,
      {},
      {
        headers: {
          'Content-Type':
            'multipart/form-data; charset=utf-8; boundary=__X_PAW_BOUNDARY__',
        },
      },
    ).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateDocumentsWithoutReplace(flatData)

      return response
    })
  }

  documentNoSignaturesRequired = (
    companyId,
    transactionId,
    documentId,
    body,
  ) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_NO_SIGNATURES_REQUIRED(
        companyId,
        transactionId,
        documentId,
      ),
    )

    return this.put(url, body)
  }

  documentDistribute = (companyId, transactionId, documentId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_DISTRIBUTE(
        companyId,
        transactionId,
        documentId,
      ),
    )
    return this.post(url, body)
  }

  documentReorder = (
    companyId,
    transactionId,
    documentId,
    oldPosition,
    newPosition,
  ) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_REORDER(companyId, transactionId, documentId),
    )

    let body = {
      old_position: oldPosition,
      new_position: newPosition,
    }
    return this.put(url, body).then((response) => {
      const {
        data: {data, included},
      } = response

      const flatData = jsonApiSpecToFlatObject(data, included)
      this.actions.updateDocuments(transactionId, flatData)
      return response
    })
  }

  documentRotate = (companyId, transactionId, documentId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_ROTATE(companyId, transactionId, documentId),
    )
    return this.put(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateDocument(flatData)

      return response
    })
  }

  documentSplit = (companyId, transactionId, documentId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_SPLIT(companyId, transactionId, documentId),
    )
    return this.post(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateDocument(flatData)

      return response
    })
  }

  documentMove = (companyId, transactionId, documentId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_MOVE(companyId, transactionId, documentId),
    )
    return this.put(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateDocument(flatData)

      return response
    })
  }

  documentDownloadUrl = (companyId, transactionId, documentId) => {
    let path = buildUrl(
      constants.ENDPOINT_DOCUMENT_DOWNLOAD(
        companyId,
        transactionId,
        documentId,
      ),
    )

    return process.env.REACT_APP_BASE_API_URL + path
  }

  documentDownload = (companyId, transactionId, documentId) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_DOWNLOAD(
        companyId,
        transactionId,
        documentId,
      ),
    )
    return this.get(url, {
      responseType: 'blob',
    })
  }

  documentPartiesReadAll = (companyId, transactionId, documentId, sort) => {
    let page = null
    let pageSize = null
    let filterBy = null

    let sortBy = `sort=${sort}`

    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_PARTIES_READ_ALL(
        companyId,
        transactionId,
        documentId,
      ),
      page,
      pageSize,
      filterBy,
      sortBy,
    )
    return this.get(url)
  }

  documentFieldsReadAll = (companyId, transactionId, documentId) => {
    let url = buildUrl(
      constants.ENDPOINT_DOCUMENT_FIELDS_READ_ALL(
        companyId,
        transactionId,
        documentId,
      ),
    )
    return this.get(url)
  }

  documentSearch = (companyId, term) => {
    let url = buildUrl(constants.ENDPOINT_DOCUMENT_SEARCH(companyId))
    url += `?q=${term}`

    return this.get(url)
  }

  login = (email, password) => {
    var basicAuth = 'Basic ' + btoa(email + ':' + password)
    return this.get(constants.ENDPOINT_LOGIN, {
      headers: {
        Authorization: basicAuth,
      },
      responseType: 'json',
    })
  }

  didAcceptTos = () => {
    return this.post(constants.ENDPOINT_DID_ACCEPT_TOS).then((response) => {
      let loggedInUser = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      setUserAccount(loggedInUser)
      this.actions.updateUserAccount(loggedInUser)

      return response
    })
  }

  welcomeShown = () => {
    return this.post(constants.ENDPOINT_WELCOME_SHOWN)
  }

  passwordChange = (body) => {
    return this.put(constants.ENDPOINT_PASSWORD_CHANGE, body)
  }

  passwordForgot = (email) => {
    let body = {email}
    return this.post(constants.ENDPOINT_PASSWORD_FORGOT, body)
  }

  passwordReset = (body) => {
    return this.post(constants.ENDPOINT_PASSWORD_RESET, body)
  }

  accountValidate = (email, validationCode) => {
    return this.get(constants.ENDPOINT_ACCOUNT_VALIDATE, {
      params: {
        e: email,
        vc: validationCode,
      },
    })
  }

  accountRead = (companyId, accountId) => {
    const url = buildUrl(constants.ENDPOINT_ACCOUNT_READ(companyId, accountId))
    return this.get(url).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateAccount(flatData)
      return response
    })
  }

  accountReadAll = (companyId, sort) => {
    let page = null
    let pageSize = null
    let filterBy = null

    let sortBy = null
    if (!_.isNil(sort)) {
      sortBy = `sort=${sortBy}`
    }
    const url = buildUrl(
      constants.ENDPOINT_ACCOUNT_READ_ALL(companyId),
      page,
      pageSize,
      filterBy,
      sortBy,
    )
    return this.get(url).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateAccounts(flatData)
      return response
    })
  }

  accountCreate = (companyId, body) => {
    let url = buildUrl(constants.ENDPOINT_ACCOUNT_CREATE(companyId))
    return this.post(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateAccount(flatData)
      return response
    })
  }

  accountUpdate = (companyId, accountId, body) => {
    const url = buildUrl(
      constants.ENDPOINT_ACCOUNT_UPDATE(companyId, accountId),
    )
    return this.put(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateAccount(flatData)
      return response
    })
  }

  accountDelete = (companyId, accountId) => {
    let url = buildUrl(constants.ENDPOINT_ACCOUNT_DELETE(companyId, accountId))
    return this.delete(url).then((response) => {
      this.actions.deleteAccount({id: accountId})
      return response
    })
  }

  partyReadAll = (companyId, sort = null) => {
    let page = null
    let pageSize = null
    let filterBy = null

    let sortBy = null
    if (!_.isNil(sort)) {
      sortBy = `sort=${sortBy}`
    }
    const url = buildUrl(
      constants.ENDPOINT_PARTY_READ_ALL(companyId),
      page,
      pageSize,
      filterBy,
      sortBy,
    )
    return this.get(url).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateParties(flatData)
      return response
    })
  }

  partyRead = (companyId, partyId) => {
    const url = buildUrl(constants.ENDPOINT_PARTY_READ(companyId, partyId))
    return this.get(url).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateParty(flatData)
    })
  }

  partyCreate = (companyId, body) => {
    const url = buildUrl(constants.ENDPOINT_PARTY_CREATE(companyId))
    return this.post(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateParty(flatData)
    })
  }

  partyUpdate = (companyId, partyId, body) => {
    const url = buildUrl(constants.ENDPOINT_PARTY_UPDATE(companyId, partyId))
    return this.put(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateParty(flatData)
    })
  }

  partyDelete = (companyId, partyId) => {
    const url = buildUrl(constants.ENDPOINT_PARTY_DELETE(companyId, partyId))
    return this.delete(url).then((response) => {
      this.actions.deleteParty({id: partyId})
    })
  }

  partyDownloadVerificationData = (companyId, partyId) => {
    const url = buildUrl(
      constants.ENDPOINT_PARTY_DOWNLOAD_VERIFICATION_DATA(companyId, partyId),
    )
    return this.get(url, {
      responseType: 'arraybuffer',
    })
  }

  partySearch = (companyId, term) => {
    let url = buildUrl(constants.ENDPOINT_PARTY_SEARCH(companyId))
    url += `?q=${term}`

    return this.get(url)
  }

  fieldsCreate = (companyId, transactionId, documentId, body) => {
    let url = buildUrl(
      constants.ENDPOINT_FIELD_CREATE(companyId, transactionId, documentId),
    )
    return this.post(url, body)
  }

  fieldsDelete = (companyId, transactionId, documentId, fieldId) => {
    let url = buildUrl(
      constants.ENDPOINT_FIELD_DELETE(
        companyId,
        transactionId,
        documentId,
        fieldId,
      ),
    )

    return this.delete(url)
  }

  fieldsDeleteAll = (companyId, transactionId, documentId) => {
    let url = buildUrl(
      constants.ENDPOINT_FIELD_DELETE_ALL(companyId, transactionId, documentId),
    )

    return this.delete(url)
  }

  companyReadAll = (filter = null, sort = null) => {
    let page = null
    let pageSize = null

    let filterBy = null
    if (!_.isNil(filter)) {
      filterBy = `filter=${filter}*`
    }

    let sortBy = null
    if (!_.isNil(sort)) {
      sortBy = `sort=${sortBy}`
    }
    const url = buildUrl(
      constants.ENDPOINT_COMPANY_READ_ALL,
      page,
      pageSize,
      filterBy,
      sortBy,
    )
    return this.get(encodeURI(url)).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateCompanies(flatData)
      return response
    })
  }

  companyRead = (companyId) => {
    let url = buildUrl(constants.ENDPOINT_COMPANY_UPDATE(companyId))
    return this.get(url).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateCompany(flatData)
      return response
    })
  }

  companyCreate = (body) => {
    return this.post(constants.ENDPOINT_COMPANY_CREATE, body).then(
      (response) => {
        let flatData = jsonApiSpecToFlatObject(
          response.data.data,
          response.data.included,
        )

        this.actions.updateCompany(flatData)
        return response
      },
    )
  }

  companyUpdate = (companyId, body) => {
    let url = buildUrl(constants.ENDPOINT_COMPANY_UPDATE(companyId))
    return this.put(url, body).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateCompany(flatData)
      this.actions.updateAccountCompany(flatData)
      return response
    })
  }

  companyDelete = (companyId) => {
    let url = buildUrl(constants.ENDPOINT_COMPANY_DELETE(companyId))
    return this.delete(url).then((response) => {
      this.actions.deleteCompany({id: companyId})
      return response
    })
  }

  pricing = () => {
    return this.get(constants.ENDPOINT_PRICING).then((response) => {
      const pricingMetadata = response.data.meta
      this.actions.updatePricing(pricingMetadata)
      return response
    })
  }

  regions = () => {
    return this.get(constants.ENDPOINT_REGIONS).then((response) => {
      const regions = response.data.meta.regions
      this.actions.updateRegions(regions)
      return response
    })
  }

  roleReadAll = () => {
    return this.get(constants.ENDPOINT_ROLE_READ_ALL).then((response) => {
      let flatData = jsonApiSpecToFlatObject(
        response.data.data,
        response.data.included,
      )

      this.actions.updateRoles(flatData)
      return response
    })
  }

  get = async (url, config = null) =>
    this.call(constants.HTTP_METHOD_GET, url, null, config)

  post = async (url, body = null, config = null) =>
    this.call(constants.HTTP_METHOD_POST, url, body, config)

  put = async (url, body = null, config = null) =>
    this.call(constants.HTTP_METHOD_PUT, url, body, config)

  patch = async (url, body = null, config = null) =>
    this.call(constants.HTTP_METHOD_PATCH, url, body, config)

  delete = async (url, config = null) =>
    this.call(constants.HTTP_METHOD_DELETE, url, null, config)

  /**
   * Configures and performs the API call to the given url endpoint.
   * This method wraps the API call in a token check when needed.
   *
   * If there is a user logged in with keycloak it will automatically add the token to the request and perform a refresh when needed.
   *
   * @param {string} method - The HTTP METHOD to use. Supports GET, POST, PATCH
   * @param {string} url - The endpoint to call
   * @param {object} body - The body of the request (only used for POST, PUT, and PATCH requests)
   * @param {object} config - The config to add to the request
   *
   * @returns {Promise} A Promise for when the API request completes.
   */
  call = async (method, url, body = null, config = null) =>
    new Promise((resolve, reject) => {
      if (_.indexOf(constants.HTTP_METHODS_AVAILABLE, method) === -1) {
        reject(
          new Error(
            `Invalid http method ${method}. Supported methods: ${_.join(
              constants.HTTP_METHODS_AVAILABLE,
              ',',
            )}`,
          ),
        )
        return
      }

      // Add all the logs to this array so that at the end of the request we can group them
      const groupedLogs = []

      const start = new Date().getTime()
      const {apiInstance} = this

      console.info(`[API] - Starting ${method} to ${url}`)

      // The raw configuration used by axios
      // See: https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
      let requestConfig = {
        url: encodeURI(url),
        method,
      }

      // Add the body if it's not null
      if (!_.isNil(body)) {
        requestConfig.data = body
      }

      // Add all the config values to the request
      for (const key in config) {
        if (config.hasOwnProperty(key)) {
          const element = config[key]
          requestConfig[key] = element
        }
      }

      // Create the perform request method here as it is repeated multiple times below so make a helper function here.
      const performRequest = (requestConfig) => {
        let finalRequestConfig = requestConfig

        // Headers doesn't exist in the config add it
        if (_.isNil(finalRequestConfig.headers)) {
          finalRequestConfig.headers = {}
        }

        if (
          // We have a token
          !_.isNil(this.token) &&
          // The user is not attempting to login
          !url.endsWith(constants.ENDPOINT_LOGIN) &&
          // No auth header has been set
          !_.has(
            finalRequestConfig.headers,
            constants.HTTP_HEADER_AUTHORIZATION,
          )
        ) {
          // Add the token to the header
          finalRequestConfig.headers[constants.HTTP_HEADER_AUTHORIZATION] =
            this.token
        }

        finalRequestConfig.headers[constants.HTTP_HEADER_IS_WEB_APP] = 'true'

        // Log the final configuration used.
        this.addToGroupedLogs(
          groupedLogs,
          'Final Request Config',
          finalRequestConfig,
        )

        // Perfrom the request with the given configuration
        apiInstance
          .request(finalRequestConfig)
          .then((response) => {
            // const {
            //   data: {data},
            // } = response
            this.addToGroupedLogs(
              groupedLogs,
              `Response took ${new Date().getTime() - start}ms`,
            )
            this.addToGroupedLogs(groupedLogs, 'Received response', response)
            const success = true
            this.printGroupedLogs(groupedLogs, method, url, success)

            // if (_.isNil(data)) {
            resolve(response)
            // } else {
            //   // let flattenedObject = jsonApiSpecToFlatObject(data, included)
            //   // TODO: Flatten response if it's valid and passed flattened response through.
            //   resolve(response)
            // }
          })
          .catch((error) => {
            // TODO: check error status code and respond appropriately
            this.addToGroupedLogs(
              groupedLogs,
              `Response took ${new Date().getTime() - start}ms`,
            )
            this.addToGroupedLogs(groupedLogs, 'Received error', error)
            const success = false
            this.printGroupedLogs(groupedLogs, method, url, success)

            reject(error)
          })
      }

      // Perform the request normally. The perform request will check if the token can be added or not
      performRequest(requestConfig)
    })

  addToGroupedLogs = (groupedLogs, message, extra = null) => {
    groupedLogs.push({message, extra})
  }

  printGroupedLogs = (groupedLogs, method, url, isSuccess) => {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.groupCollapsed(
        `[API] - ${isSuccess ? 'SUCCESS' : 'FAILURE'} ${method} ${url}`,
      )
      _.forEach(groupedLogs, (groupedLog) => {
        if (_.has(groupedLog, 'extra') && !_.isNil(groupedLog.extra)) {
          console.info(groupedLog.message, groupedLog.extra)
        } else {
          console.info(groupedLog.message)
        }
      })
      console.groupEnd()
    }
  }
}

const mapStateToProps = (state) => ({
  loginToken: state.loginToken,
})

const mapDispatchToProps = (dispatch) => ({
  updatePricing: (payload) => dispatch(updatePricing(payload)),
  updateRegions: (payload) => dispatch(updateRegions(payload)),

  clearReduxCache: () => dispatch(clearReduxCache()),
  updateLoginToken: (payload) => dispatch(updateLoginToken(payload)),
  updateUserAccount: (payload) => dispatch(updateUserAccount(payload)),

  updateRoles: (payload) => dispatch(updateRoles(payload)),

  updateCompanies: (payload) => dispatch(updateCompanies(payload)),
  updateCompany: (payload) => dispatch(updateCompany(payload)),
  deleteCompany: (payload) => dispatch(deleteCompany(payload)),

  updateAccounts: (payload) => dispatch(updateAccounts(payload)),
  updateAccount: (payload) => dispatch(updateAccount(payload)),
  updateAccountCompany: (payload) => dispatch(updateAccountCompany(payload)),
  deleteAccount: (payload) => dispatch(deleteAccount(payload)),

  updateTransactions: (payload) => dispatch(updateTransactions(payload)),
  updateTransaction: (payload) => dispatch(updateTransaction(payload)),
  deleteTransaction: (payload) => dispatch(deleteTransaction(payload)),

  updateDocuments: (transactionId, payload) =>
    dispatch(updateDocuments({transactionId, data: payload})),
  updateDocumentsWithoutReplace: (payload) =>
    dispatch(updateDocumentsWithoutReplace(payload)),
  updateDocument: (payload) => dispatch(updateDocument(payload)),
  deleteDocument: (payload) => dispatch(deleteDocument(payload)),

  updateParties: (payload) => dispatch(updateParties(payload)),
  updateParty: (payload) => dispatch(updateParty(payload)),
  deleteParty: (payload) => dispatch(deleteParty(payload)),

  showTransactionLockedModal: (payload) =>
    dispatch(showTransactionLockedModal(payload)),
  updateLockedTransactionId: (payload) =>
    dispatch(updateLockedTransactionId(payload)),
})

const withApi = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  ApiProvider,
)

export default withApi
