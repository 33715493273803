import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
// Redux
import {connect} from 'react-redux'
// Components
import TransactionReadAllTable from './TransactionReadAllTable'

class TransactionReadAll extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="tile">
          <div>
            <div className="dashbaord__tile full">
              <div className="inner__tile">
                <h2>All Transactions</h2>
                <TransactionReadAllTable />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withRouter(connect(mapStateToProps)(TransactionReadAll))
