import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
import {
  updateLoginToken,
  updateUserAccount,
  clearReduxCache,
} from '../../redux/actions/account'
// Utils
import {appPaths} from '../../utils/appPaths'
import {setLoginToken, setUserAccount} from '../../utils/loginToken'
import {showFormErrors} from '../../utils/validate'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Components
import FormError from '../common/FormError'
// Images
import hand_shake from '../../img/hand_shake.png'
import logo from '../../img/logo_full_TM.png'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailX: process.env.REACT_APP_EMAIL || '',
      passwordX: process.env.REACT_APP_PASSWORD || '',
      showPassword: true,
      showPasswordValue: false,
      error: '',
    }

    props.clearReduxCache()
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    // Show or hide "Show Password" feature
    if (e.target.name === 'passwordX') {
      if (e.target.value) {
        this.setState({showPassword: true})
      } else {
        this.setState({showPassword: false})
      }
    }
  }

  handleClick = () => {
    // Show or hide password value
    var passwordTypeValue = this.state.showPasswordValue
    if (passwordTypeValue === true) {
      this.setState({showPasswordValue: false})
    } else {
      this.setState({showPasswordValue: true})
    }
  }

  handleClickForgotPassword = (e) => {
    this.props.history.push(appPaths.ForgotPassword)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      const {emailX, passwordX} = e.target

      this.props.api
        .login(emailX.value, passwordX.value)
        .then((response) => {
          // Set login cookie
          let loginToken = response.data.meta.token
          setLoginToken(loginToken)
          this.props.updateLoginToken(loginToken)

          // Flatten data
          let flatData = jsonApiSpecToFlatObject(
            response.data.data,
            response.data.included,
          )

          // Set userAccount local storage
          setUserAccount(flatData)
          this.props.updateUserAccount(flatData)

          const redirectKey = localStorage.redirect

          if (flatData.role.id !== 1) {
            if (
              flatData.force_password_reset &&
              flatData.forgot_password_code
            ) {
              this.props.history.push(
                appPaths.ChangePassword +
                  '/' +
                  flatData.forgot_password_code,
              )
            } else if (
              flatData.company.is_stripe_setup === false
            ) {
              this.props.history.push(appPaths.Payment)
            } else {
              if (redirectKey) {
                localStorage.removeItem('redirect')
                this.props.history.push(redirectKey)
              } else {
                this.props.history.push(appPaths.Transaction)
              }
            }
          } else {
            if (redirectKey) {
              localStorage.removeItem('redirect')
              this.props.history.push(redirectKey)
            } else {
              this.props.history.push(appPaths.Account)
            }
          }
        })
        .catch((error) => {
          var errorStr = networkErrorDetail(error)
          if (errorStr.toLowerCase().indexOf('authentication') >= 0) {
            errorStr = 'Invalid username or password'
          }

          this.setState({
            error: errorStr,
            showPassword: false,
          })
        })
        .finally(() => {
          NProgress.done()
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal">
              <div className="modal__form">
                <div className="modal__inner">
                  {this.props.match.params.pc ? (
                    <div>
                      <h1>Success!</h1>
                      <p>
                        You have created a new password. You may now log in with
                        the new credentials.
                      </p>
                      <br />
                    </div>
                  ) : this.props.match.params.pfs ? (
                    <div>
                      <h1>Success!</h1>
                      <p>
                        An email has been sent with a link to reset your
                        password.
                      </p>
                      <br />
                    </div>
                  ) : (
                    <h1>
                      Welcome to ModernXMark<sup>&reg;</sup>
                    </h1>
                  )}

                  {this.props.paymentAdded && 'Payment added'}
                  <form method="post" onSubmit={this.handleSubmit} noValidate>
                    <div className="form__group">
                      <div className="row">
                        <label id="emailXLabel">Email</label>
                      </div>
                      <input
                        type="email"
                        name="emailX"
                        ref="emailX"
                        className="form__control"
                        value={this.state.emailX}
                        onChange={this.handleInput}
                        placeholder="Enter email"
                        title="Email"
                        required
                      />
                      <div className="error" id="emailXError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="passwordXLabel" className="left">
                          Password
                        </label>
                        {this.state.showPassword && (
                          <button
                            type="button"
                            className="link right small"
                            onClick={this.handleClick}
                            tabIndex="-1"
                          >
                            {this.state.showPasswordValue
                              ? 'Hide Password'
                              : 'Show Password'}
                          </button>
                        )}
                      </div>
                      <input
                        type={
                          this.state.showPasswordValue ? 'text' : 'password'
                        }
                        className="form__control"
                        name="passwordX"
                        ref="passwordX"
                        value={this.state.passwordX}
                        onChange={this.handleInput}
                        placeholder="Enter password"
                        title="Password"
                        pattern="((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,})"
                        required
                      />
                      <div className="error" id="passwordXError" />
                    </div>
                    <FormError error={this.state.error} />
                    <div className="form__group">
                      <div className="row">
                        <button
                          type="submit"
                          className="btn btn--blue-solid"
                          value="Log In"
                        >
                          Log In
                        </button>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="link"
                      onClick={this.handleClickForgotPassword}
                    >
                      Trouble logging in?
                    </button>
                  </form>
                </div>
              </div>
              <div className="modal__mark">
                <div className="background" />
                <img src={hand_shake} className="modal__img" alt="logo" />
                <img src={logo} className="modal_logo" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  // }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
  clearReduxCache: () => dispatch(clearReduxCache()),
  updateLoginToken: (payload) => dispatch(updateLoginToken(payload)),
  updateUserAccount: (payload) => dispatch(updateUserAccount(payload)),
})

export default withApi(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)),
)
