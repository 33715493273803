import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken} from '../../utils/loginToken'
import {showFormErrors, validateInput} from '../../utils/validate'
// Components
import AccountsReadAllTable from '../account/AccountsReadAllTable'
import FormError from '../common/FormError'
import SubscribersDelete from './SubscribersDelete'
// Images
import large_edit from '../../img/large_edit.svg'
import large_delete from '../../img/large_delete.svg'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import TextInput from '../common/TextInput'
import _ from 'lodash'
import RegionAutoSuggest from '../common/RegionAutoSelect'
import SubscriberProfile from './SubscriberProfile'

class SubscriberRead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyAccountId: this.props.match.params.ac,
      editSubscriber: false,
      subscriberName: '',
      email: '',
      invoiceEmail: '',
      totalCompanyTransactionCount: 0,
      error: '',
      handleDelete: false,
      loginToken: checkToken(this),
      networkActive: false,
    }
  }

  componentWillMount() {
    NProgress.start()

    this.setState({loading: true})

    const companyAccountId = this.state.companyAccountId
    this.props.api
      .companyRead(companyAccountId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        this.setState({loading: false})
        NProgress.done()
      })
  }

  componentWillReceiveProps(nextProps) {
    this.parseSubscriberToState(nextProps.company)
  }

  componentDidMount() {
    this.parseSubscriberToState(this.props.company)
  }

  parseSubscriberToState = (flatData) => {
    if (_.isNil(flatData)) {
      this.setState({
        subscriberName: '',
        email: '',
        regionCode: '',
        totalCompanyTransactionCount: 0,
      })
      return
    }

    this.setState({
      subscriberName: flatData.name,
      email: flatData.email,
      invoiceEmail: flatData.invoice_email,
      regionCode: flatData.region_code,
      totalCompanyTransactionCount: flatData.total_company_transaction_count,
    })
  }

  handleBack = (e) => {
    this.props.history.goBack()
  }

  handleCloseDelete = (e) => {
    this.setState({
      handleDelete: false,
    })
  }

  handleDelete = (e) => {
    this.setState({
      handleDelete: true,
    })
  }

  handleEdit = (e) => {
    this.setState({
      editSubscriber: true,
    })
  }

  updateValueInState = (e, id, value) => {
    this.setState({
      [id]: value,
    })

    // Validate email on input
    if (id === 'email' || id === 'invoiceEmail') {
      validateInput(this, e)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="blue__bar menu__body flexbox">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back{' '}
              <span className="vert__lines">
                <span className="vert__line" />
              </span>
            </button>
            <h2>{this.state.subscriberName}</h2>
          </div>
        </div>
        <div className="tile user--settings">
          <SubscriberProfile />
          <div className="dashbaord__tile large no-padding">
            <div className="inner__tile">
              <h2>Subscriber User Accounts</h2>
              <AccountsReadAllTable
                companyAccountId={this.state.companyAccountId}
              />
            </div>
          </div>
        </div>

        {this.state.handleDelete && (
          <SubscribersDelete handleCloseDelete={this.handleCloseDelete} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    account: state.account,
    company: state.companies.data[ownProps.match.params.ac],
  }
}

export default withApi(withRouter(connect(mapStateToProps)(SubscriberRead)))
