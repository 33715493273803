import queryString from 'query-string'
import _ from 'lodash'
// Utils
import {appPaths} from '../utils/appPaths'

// Login Token
export const setLoginToken = (loginToken) => {
  var cname = 'loginToken'
  var cvalue = loginToken
  var days = 90
  var d = new Date()
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const getLoginToken = (that, name) => {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export const checkErrorToken = (that, error) => {
  if (
    error.toLowerCase().indexOf('token not found') >= 0 ||
    error.toLowerCase().indexOf('expired token') >= 0
  ) {
    localStorage.redirect = that.props.location.pathname
    return that.props.history.push(appPaths.Login)
  } else {
    return null
  }
}

export const checkToken = (that) => {
  if (
    that &&
    that.props &&
    that.props.account &&
    that.props.account.loginToken
  ) {
    return 'Bearer ' + [that.props.account.loginToken]
  } else {
    var loginToken = getLoginToken(that, 'loginToken')
    if (loginToken) {
      return 'Bearer ' + [loginToken]
    }
  }
}

export const deleteCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

// Company ID
export const setUserAccount = (userAccount) => {
  var cname = 'userAccount'
  var cvalue = userAccount
  localStorage.setItem(cname, JSON.stringify(cvalue))
}

export const getUserAccount = (that, name) => {
  var v = localStorage.getItem(name)
  return v ? v : null
}

export const checkUserAccount = (that) => {
  if (
    !_.isNil(that) &&
    !_.isNil(that.props) &&
    !_.isNil(that.props.account) &&
    !_.isNil(that.props.account.userAccount)
  ) {
    return that.props.account.userAccount
  } else {
    var userAccount = JSON.parse(getUserAccount(that, 'userAccount'))
    if (userAccount) {
      return userAccount
    }
  }
}

// Table Page
export const tablePageUpdateWill = (that) => {
  const values = queryString.parse(that.props.location.search)
  const pageURL = values.p

  if (pageURL !== undefined && pageURL !== '') {
    that.setState({
      page: parseInt(pageURL),
    })
  }

  that.handleDataRefresh()
}

export const tablePageUpdateDid = (that) => {
  const values = queryString.parse(that.props.location.search)
  const pageURL = values.p
  const pageState = that.state.page

  if (
    pageURL !== undefined &&
    pageURL !== '' &&
    parseInt(pageURL) !== pageState
  ) {
    that.setState({
      page: parseInt(pageURL),
    })

    _.throttle(that.handleDataRefresh, 1000)
  }
}
