import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
// Components
import DocumentAddFieldAutoSuggest from './DocumentAddFieldAutoSuggest'
import FormError from '../common/FormError'

class DocumentDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.match.params.di,
      error: '',
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseAddField()
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner overflow-unset">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Add Document Field</h5>
                    <p>
                      To associate a field with a party, please type the name of
                      the person you wish to add below.
                    </p>
                    <div className="form">
                      <FormError error={this.state.error} />
                      <DocumentAddFieldAutoSuggest
                        handleSubmit={this.props.handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withRouter(connect(mapStateToProps)(DocumentDelete))
