import React, {Component} from 'react'
import Autosuggest from 'react-autosuggest'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>

class RegionAutoSuggest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      suggestions: [],
      value: props.initialValue || '',
    }
  }

  onChange = (event, {newValue}) => {
    this.setState({
      value: newValue,
    })

    this.props.didChangeValue(newValue)
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : _.filter(
          this.props.regions,
          (subs) =>
            subs.name.toLowerCase().slice(0, inputLength) === inputValue,
        )
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({value}) => {
    let suggestions = this.getSuggestions(value)
    if (_.isEmpty(suggestions)) {
      suggestions = [{code: 'no-region', name: 'No Region'}]
    }
    this.setState({
      suggestions,
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  onSuggestionSelected = (event, {suggestion}) => {
    this.setState({
      regionCode: suggestion.code,
    })
  }

  render() {
    const {value, suggestions} = this.state
    const {regions} = this.props

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      name: 'regionCode',
      placeholder: 'Type to search regions',
      value,
      data: this.state.regionCode,
      onChange: this.onChange,
    }

    if (_.isNil(regions) || regions.length === 0) {
      return (
        <React.Fragment>
          <p className="left error">Loading Regions</p>
          <input type="hidden" name="regionCode" value={value} />
        </React.Fragment>
      )
    }
    // Add the current users company id invisibly to be used by the form
    else if (_.isNil(regions) || regions.length === 0) {
      return (
        <React.Fragment>
          <p className="left error">No Regions found</p>
          <input type="hidden" name="regionCode" value={value} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.onSuggestionSelected}
          />
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    regions: state.preferences.regions,
  }
}

export default withApi(connect(mapStateToProps)(RegionAutoSuggest))
