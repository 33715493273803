import {UPDATE_COMPANIES, UPDATE_COMPANY, DELETE_COMPANY} from '../constants'

export const updateCompanies = (payload) => ({
  type: UPDATE_COMPANIES,
  payload,
})

export const updateCompany = (payload) => ({
  type: UPDATE_COMPANY,
  payload,
})

export const deleteCompany = (payload) => ({
  type: DELETE_COMPANY,
  payload,
})
