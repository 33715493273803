import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import recently_viewed_icon from '../../img/recently_viewed_icon.svg'
import withApi from '../../api/withApi'
import {networkErrorDetail, buildUrl} from '../../utils/urls'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [
        {
          guid: '',
          name: '',
          updated_at: {
            date: '',
          },
        },
      ],
      error: '',
      loading: true,
      page: 0,
      pageSize: 5,
      pages: 1,
      hidePagination: false,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.handleDataRefresh()
  }

  handleDataRefresh = (e) => {
    this.setState({
      refresh: true,
    })
  }

  getTableData(page, pageSize, sorted, filtered, handleRetrievedData) {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    let sortBy = ''
    let filterBy = ''
    let actualPage = null
    pageSize = null

    filterBy =
      'filter=document.state:=awaiting_signature AND transaction.closedAt:=null'
    sortBy = 'sort=-document.updatedAt'

    let url = buildUrl(
      `/company/${companyId}/document/all`,
      actualPage,
      pageSize,
      filterBy,
      sortBy,
    )

    return this.props.api
      .get(url)
      .then((response) => {
        let flatData = jsonApiSpecToFlatObject(
          response.data.data,
          response.data.included,
        )

        this.setState({
          data: flatData,
          loading: false,
          page: 0,
          pages: 0,
        })
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
          loading: false,
          data: [],
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    const columns = [
      {
        id: 'name',
        Header: 'Document Name',
        accessor: (d) => d.name,
        sortable: false,
      },
      {
        id: 'updated_at',
        Header: 'Last Update',
        accessor: (d) => {
          return <Moment format="MMMM D, YYYY">{d.updated_at.date}</Moment>
        },
        sortable: false,
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(
            appPaths.DocumentRead(
              rowInfo.original.transaction.id,
              rowInfo.original.id,
            ),
          )
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={recently_viewed_icon} alt="Alt text" />
            <p>
              (5) most recently viewed documents awaiting signatures will appear
              here.
            </p>
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <span>
          <ReactTableCommon
            tableState={this}
            columns={columns}
            onRowClick={onRowClick}
            tableNoResults={tableNoResults}
          />
        </span>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(Dashboard)))
