import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
import queryString from 'query-string'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
import {isTransactionReadOnly} from '../../utils/transaction'
import {networkErrorDetail} from '../../utils/urls'
// Components
import DocumentEdit from '../document/DocumentEdit'
import DocumentUpload from '../document/DocumentUpload'
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import documents_icon from '../../img/documents_icon.svg'
import download_zip from '../../img/download_zip.svg'
import large_add_light from '../../img/large_add_light.svg'
import large_edit from '../../img/large_edit.svg'
import bars from '../../img/bars.svg'
import _ from 'lodash'
import withApi from '../../api/withApi'

class DocumentReadAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      complete: false,
      isReorderingElements: false,
      error: '',
      filterValue: '',
      handleEdit: false,
      handleSplit: false,
      handleUpload: false,
      helpfulHint: false,
      loading: true,
      selectDocumentIndex: 0,
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.getTableData()

    const values = queryString.parse(this.props.location.search)
    const upload = values.upload

    if (upload) {
      this.handleUpload()

      this.props.history.replace(
        appPaths.TransactionRead(this.state.transactionId),
      )
    }
  }

  componentDidUpdate(newProps) {
    if (
      !_.isNil(this.props.transaction) &&
      !_.isNil(newProps.transaction) &&
      !_.isEqual(
        this.props.transaction.closing_date,
        newProps.transaction.closing_date,
      )
    ) {
      this.getTableData()
    }
  }

  componentDidMount() {
    if (!localStorage.helpHintDocument && this.state.userAccount.show_hints) {
      this.setState({
        helpfulHint: true,
      })
    }
  }

  handleDataRefresh = (e) => {
    this.getTableData()
  }

  handleUpdateTransaction = (e) => {
    this.handleDataRefresh()
    this.props.handleUpdateTransaction()
  }

  handleCloseUpload = (e) => {
    this.setState({
      handleUpload: false,
    })
  }

  handleUpload = (e) => {
    this.setState({
      handleUpload: true,
    })
  }

  handleHelpfulHint = (e) => {
    this.setState({
      helpfulHint: false,
    })

    localStorage.helpHintDocument = true
  }

  handleCloseEdit = (e) => {
    this.setState({
      handleEdit: false,
    })
  }

  handleEdit = (e, doc) => {
    e.preventDefault()
    e.stopPropagation()

    if (doc.count_fields_completed > 0) {
      this.setState({
        documentId: e.target.value,
        handleEdit: true,
      })
    } else {
      this.props.history.push(
        appPaths.DocumentPrepare(this.state.transactionId, doc.id),
      )
    }
  }

  handleView = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.history.push(
      appPaths.DocumentView(this.state.transactionId, e.target.value),
    )
  }

  handleDocumentBack = (e) => {
    let selectedDocumentIdsCopy = this.state.selectedDocumentIds
    if (selectedDocumentIdsCopy.length > 0) {
      var selectDocumentIndexCopy = this.state.selectDocumentIndex - 1
      if (selectedDocumentIdsCopy[selectDocumentIndexCopy]) {
        this.setState({
          selectDocumentIndex: selectDocumentIndexCopy,
        })
      } else {
        this.setState({
          selectDocumentIndex: 0,
        })
      }
    }

    return true
  }

  removeDocumentId = (index) => {
    let selectedDocumentIdsCopy = this.state.selectedDocumentIds
    if (selectedDocumentIdsCopy.length > 0) {
      var selectDocumentIndex = this.state.selectDocumentIndex + 1
      if (selectedDocumentIdsCopy[selectDocumentIndex]) {
        this.setState({
          selectDocumentIndex: selectDocumentIndex,
        })
      } else {
        this.setState({
          selectDocumentIndex: 0,
        })
      }
    }
  }

  onDragEnd = (result) => {
    if (result.destination === null) {
      return
    }

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId
    const documentId = result.draggableId
    const oldPosition = result.source.index + 1
    const newPosition = result.destination.index + 1

    if (oldPosition === newPosition) {
      return
    }

    this.setState({loading: true})
    this.props.api
      .documentReorder(
        companyId,
        transactionId,
        documentId,
        oldPosition,
        newPosition,
      )
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }

  getTableData = () => {
    this.setState({loading: true})
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId

    return this.props.api
      .documentReadAll(companyId, transactionId)
      .then((response) => {
        let complete = false

        if (response.data.data !== undefined && response.data.data.length > 0) {
          let flatData = jsonApiSpecToFlatObject(
            response.data.data,
            response.data.included,
          )

          if (flatData[0].transaction.closed_at) {
            complete = true
          }
        }

        this.setState({
          complete: complete,
        })
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const columns = [
      {
        id: 'reorder',
        Header: '',
        className: 'flexbox vertical-center',
        width: 50,
        sortable: false,
        accessor: () => {
          return (
            <img
              style={{height: '15px', width: '15px'}}
              src={bars}
              alt="Reorder handle"
            />
          )
        },
      },
      {
        id: 'name',
        Header: 'Document Name',
        className: 'flexbox vertical-center',
        sortable: false,
        accessor: (d) => d.name,
      },
      {
        id: 'updatedAt',
        Header: 'Last Updated',
        className: 'flexbox vertical-center',
        sortable: false,
        accessor: (d) => {
          if (d.updated_at === null || d.updated_at === '') {
            return 'N/A'
          } else {
            return <Moment format="MMM D, YYYY">{d.updated_at.date}</Moment>
          }
        },
      },
      {
        id: 'closingDate',
        Header: 'Transaction Date',
        className: 'flexbox vertical-center',
        sortable: false,
        accessor: () => {
          if (
            _.isNil(this.props.transaction) ||
            _.isNil(this.props.transaction.closing_date) ||
            this.props.transaction.closing_date === ''
          ) {
            return 'N/A'
          } else {
            return (
              <Moment format="MMM D, YYYY">
                {this.props.transaction.closing_date.date}
              </Moment>
            )
          }
        },
      },
      {
        id: 'createdAt',
        Header: 'Expiration Date',
        className: 'flexbox vertical-center',
        sortable: false,
        accessor: (d) => {
          if (d.expiration_date === null || d.expiration_date === '') {
            return 'N/A'
          } else {
            return (
              <Moment format="MMM D, YYYY">{d.expiration_date.date}</Moment>
            )
          }
        },
      },
      {
        id: 'view',
        Header: '',
        className: 'flexbox vertical-center',
        width: 100,
        sortable: false,
        accessor: (d) => {
          return (
            <button
              type="button"
              className="btn btn--mod-2 btn--blue no-margin"
              value={d.id}
              onClick={this.handleView}
            >
              View
            </button>
          )
        },
      },
    ]

    if (
      this.props.documents &&
      this.props.documents.length > 0 &&
      this.props.documents[0].guid !== '' &&
      !isTransactionReadOnly(this.props.documents[0].transaction)
    ) {
      columns.push({
        id: 'edit',
        Header: '',
        className: 'flexbox vertical-center',
        width: 75,
        sortable: false,
        accessor: (d) => {
          return (
            <button
              type="button"
              className="link"
              value={d.id}
              onClick={(e) => this.handleEdit(e, d)}
              disabled={this.state.complete}
            >
              <img src={large_edit} alt="Large edit" />
            </button>
          )
        },
        Cell: (row) => (
          <div
            className="flexbox vertical-center"
            style={{textAlign: 'center'}}
          >
            {row.value}
          </div>
        ),
      })
    }

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        rowInfo,
        onClick: (e) => {
          this.props.history.push(
            appPaths.DocumentRead(
              this.state.transactionId,
              rowInfo.original.id,
            ),
          )
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={documents_icon} alt="Document Icon" />
            <p>{this.state.error ? this.state.error : ' No Documents Found'}</p>
          </div>
        </div>
      )
    }

    const tableData = this.props.documents
    const tableHeight = Math.min(50 + 50 * tableData.length, 250)

    return (
      <React.Fragment>
        <div className="dashbaord__tile large no-padding">
          <div className="inner__tile inner__tile__documents">
            <h2>All Documents</h2>
            <div>
              {this.props.canAddDocuments && (
                <button
                  type="button"
                  className="link right"
                  onClick={this.handleUpload}
                >
                  <img src={large_add_light} alt="Large Add" />
                </button>
              )}
              {!_.isNil(tableData) && tableData.length > 0 && (
                <button
                  type="button"
                  className="link right"
                  onClick={this.props.handleDownload}
                >
                  <img src={download_zip} alt="Download Zip" />
                </button>
              )}
            </div>
            <div>
              {this.state.helpfulHint ? (
                <div className="helpful__hint" onClick={this.handleHelpfulHint}>
                  <div className="arrow-up" />
                  <h2>Documents</h2>
                  <p>
                    Each document is uploaded and configured prior to being
                    signed. You can upload a document from this page.
                  </p>
                </div>
              ) : (
                <div />
              )}
              <ReactTableCommon
                style={{
                  height: `${tableHeight}px`,
                }}
                isLoading={this.state.loading}
                tableData={tableData}
                columns={columns}
                onRowClick={onRowClick}
                tableNoResults={tableNoResults}
                transaction={this.props.transaction}
                isReorderingElements={true}
                onDragEnd={this.onDragEnd}
              />
            </div>
          </div>
        </div>

        {this.state.handleEdit && (
          <DocumentEdit
            handleCloseEdit={this.handleCloseEdit}
            documentId={this.state.documentId}
          />
        )}

        {this.state.handleUpload && (
          <DocumentUpload
            handleCloseUpload={this.handleCloseUpload}
            handleSplit={this.handleSplit}
            handleDataRefresh={this.handleDataRefresh}
            handleUpdateTransaction={this.handleUpdateTransaction}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let documents = _.map(state.documents.data, (document, key) => document)
  documents = _.filter(
    documents,
    (document) =>
      `${document.transaction.id}` === `${ownProps.match.params.ti}` &&
      !document.upload_in_progress,
  )
  documents = _.sortBy(documents, (document) => document.display_order)

  return {
    account: state.account,
    documents,
  }
}

export default withApi(withRouter(connect(mapStateToProps)(DocumentReadAll)))
