import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Stripe
import {Elements, StripeProvider} from 'react-stripe-elements'
import CheckoutForm from './CheckoutForm'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
// Components
import TermsAndConditions from '../account/TermsAndConditions'
// Images
import hand_shake from '../../img/hand_shake.png'
import withApi from '../../api/withApi'
import _ from 'lodash'

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginToken: checkToken(this),
      userAccount: checkUserAccount(this),
    }
  }

  handleDeclineTermsAndConditions = (e) => {
    this.props.history.push(appPaths.Login)
  }

  handleHideModal = (e) => {
    if (this.props.handleClosePayment) {
      this.props.handleClosePayment()
    } else {
      this.props.history.push(appPaths.Login)
    }
  }

  render() {
    const isUpdate = !_.isNil(this.props.handleClosePayment)

    return (
      <React.Fragment>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
          <div className="fixed">
            <div className="row">
              <div className="modal modal__two">
                <button
                  type="button"
                  className="close link"
                  onClick={this.handleHideModal}
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal__form">
                  <div className="modal__inner">
                    <div className="modal__container">
                      {isUpdate ? (
                        <h1>Update Payment Details</h1>
                      ) : (
                        <h1>Add Payment Details</h1>
                      )}
                      <p />
                      <p>
                        This card will be added to the company account and used
                        for all transactions.
                      </p>
                      <br />
                      <Elements>
                        <CheckoutForm
                          handleClosePayment={this.props.handleClosePayment}
                          isUpdate={isUpdate}
                        />
                      </Elements>
                    </div>
                  </div>
                </div>
                <div className="modal__mark">
                  <img src={hand_shake} className="modal__img" alt="logo" />

                  <div className="top__angle" />
                  <div className="bottom__angle" />
                </div>
              </div>
            </div>
          </div>
        </StripeProvider>

        {!this.props.account.userAccount.did_accept_tos && (
          <TermsAndConditions
            showButtons={true}
            handleDeclineTermsAndConditions={
              this.handleDeclineTermsAndConditions
            }
            onCloseClick={this.handleDeclineTermsAndConditions}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(Payment)))
