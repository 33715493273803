import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import Autosuggest from 'react-autosuggest'
// Redux
import {connect} from 'react-redux'
// Utils
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>

class SubscribersAutoSuggest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyId: '',
      error: '',
      suggestions: [],
      value: '',
      isLoading: false,
    }
  }

  componentWillMount() {
    NProgress.start()

    this.setState({
      isLoading: true,
    })

    this.props.api
      .companyReadAll()
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
        NProgress.done()
      })
  }

  onChange = (event, {newValue}) => {
    this.setState({
      value: newValue,
    })
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : this.props.subscribers.filter(
          (subs) =>
            subs.name.toLowerCase().slice(0, inputLength) === inputValue,
        )
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  onSuggestionSelected = (event, {suggestion}) => {
    this.setState({
      companyId: suggestion.id,
    })
  }

  render() {
    const {isLoading, value, suggestions} = this.state

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      name: 'companyId',
      placeholder: 'Type to search companies',
      value,
      data: this.state.companyId,
      onChange: this.onChange,
    }

    if (isLoading && this.props.subscribers.length === 0) {
      return (
        <React.Fragment>
          <p className="left error">Loading Companies</p>
          <input type="hidden" name="companyId" value={this.props.companyId} />
        </React.Fragment>
      )
    }
    // Add the current users company id invisibly to be used by the form
    else if (this.props.subscribers.length === 0) {
      return (
        <React.Fragment>
          <p className="left error">No companies found</p>
          <input type="hidden" name="companyId" value={this.props.companyId} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.onSuggestionSelected}
          />
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  subscribers: _.map(state.companies.data, (company, key) => company),
})

export default withApi(
  withRouter(connect(mapStateToProps)(SubscribersAutoSuggest)),
)
