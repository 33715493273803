import React from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
// Utils
import {appPaths} from '../../utils/appPaths'
// Components
import TransactionRead from './TransactionRead'
import TransactionReadAll from './TransactionReadAll'
import DocumentSwitch from '../document/DocumentSwitch'

const TransactionSwitch = () => (
  <Switch>
    <Route exact path={appPaths.Transaction} component={TransactionReadAll} />
    <Route
      exact
      path={appPaths.TransactionRead(':ti')}
      component={TransactionRead}
    />
    <Route path={appPaths.Document(':ti')} component={DocumentSwitch} />
  </Switch>
)

export default withRouter(TransactionSwitch)
