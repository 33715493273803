import React from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
// Utils
import {appPaths} from '../../utils/appPaths'
// Components
import SubscribersRead from './SubscribersRead'
import SubscribersReadAll from './SubscribersReadAll'

const CompanySwitch = () => (
  <Switch>
    <Route
      path={appPaths.SubscribersRead + '/:ac'}
      component={SubscribersRead}
    />
    <Route path={appPaths.Subscribers} component={SubscribersReadAll} />
  </Switch>
)

export default withRouter(CompanySwitch)
