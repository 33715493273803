import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {validateInput, showFormErrors} from '../../utils/validate'
import withApi from '../../api/withApi'
// Components
import FormError from '../common/FormError'
import DateInput from '../common/DateInput'
import TextInput from '../common/TextInput'
import PhoneNumberInput from '../common/PhoneNumberInput'
import {networkErrorDetail} from '../../utils/urls'

class PartiesCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      companyName: '',
      signingAuthority: '',
      employer: '',
      employerAddress: '',
      employerPhoneNumber: '',
      occupation: '',
      email: '',
      company: '',
      error: '',
      firstName: '',
      middleName: '',
      lastName: '',
      loginToken: checkToken(this),
      dateOfBirth: '',
      phoneNumber: '',
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseCreate()
  }

  handleInput = (e) => {
    const fieldName = e.target.name
    const nextValue = e.target.value

    this.updateValueInState(fieldName, nextValue)

    // Validate email
    if (fieldName === 'email') {
      validateInput(this, e)
    }
  }

  updateValueInState(key, value) {
    this.setState({
      [key]: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.setState({networkActive: true})

      const companyId = this.state.userAccount.company.id
      const {
        clientName,
        dateOfBirth,
        email,
        address,
        phoneNumber,
        occupation,
        employer,
        employerAddress,
        employerPhoneNumber,
      } = e.target

      this.props.api
        .partyCreate(companyId, {
          client_name: clientName.value,
          email: email.value,
          employer: employer.value,
          employer_address: employerAddress.value,
          employer_phone_number: employerPhoneNumber.value,
          occupation: occupation.value,
          date_of_birth: dateOfBirth.value,
          address: address.value,
          phone_voice: '',
          phone_sms: phoneNumber.value,
          phone_fax: '',
        })
        .then((response) => {
          this.props.handleDataRefresh()
          this.props.handleCloseCreate()
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()

          this.setState({networkActive: false})
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form" style={{width: '65%'}}>
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                      disabled={this.state.networkActive}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Add a Party</h5>
                    <p>Input the parties information.</p>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <TextInput
                        id="clientName"
                        title="Client Name"
                        value={this.state.clientName}
                        placeholder="Company Name, First Name, Middle Name, Last Name, Signing Authority (including title – if applicable)"
                        isRequired={true}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <DateInput
                        id="dateOfBirth"
                        title="Date of Birth"
                        date={this.state.dateOfBirth}
                        isRequired={false}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <TextInput
                        id="email"
                        title="Email"
                        value={this.state.email}
                        placeholder="Enter email"
                        isRequired={false}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <TextInput
                        id="address"
                        title="Client Address"
                        value={this.state.address}
                        placeholder="Street No., Street Name, City/Town, Province, Postal Code, Country"
                        isRequired={false}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <PhoneNumberInput
                        id="phoneNumber"
                        title="Client Phone Number"
                        placeholder="(555) 555-5555"
                        value={this.state.phoneNumber}
                        isRequired={true}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <TextInput
                        id="occupation"
                        title="Occupation"
                        value={this.state.occupation}
                        placeholder="Enter occupation"
                        isRequired={false}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <TextInput
                        id="employer"
                        title="Employer"
                        value={this.state.employer}
                        placeholder="Enter employer"
                        isRequired={false}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <TextInput
                        id="employerAddress"
                        title="Employer Address"
                        value={this.state.employerAddress}
                        placeholder="Street No., Street Name, City/Town, Province, Postal Code, Country"
                        isRequired={false}
                        isEditing={true}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <PhoneNumberInput
                        id="employerPhoneNumber"
                        title="Employer Phone Number"
                        placeholder="(555) 555-5555"
                        value={this.state.employerPhoneNumber}
                        isRequired={false}
                        isEditing={true}
                        required={false}
                        onChange={(e, id, value) =>
                          this.updateValueInState(id, value)
                        }
                      />
                      <FormError error={this.state.error} />
                      <div className="form__group">
                        <button
                          type="submit"
                          className="col-40 btn btn--mod-2 btn--green"
                          value="Create"
                          disabled={this.state.networkActive}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(PartiesCreate)))
