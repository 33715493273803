export const UPDATE_LOGIN_TOKEN = 'UPDATE_LOGIN_TOKEN'
export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT'
export const UPDATE_ACCOUNT_COMPANY_ID = 'UPDATE_ACCOUNT_COMPANY_ID'
export const CLEAR_REDUX_CACHE = 'CLEAR_REDUX_CACHE'
export const CLEAR_REDUX_CACHE_EXCLUDING_USER =
  'CLEAR_REDUX_CACHE_EXCLUDING_USER'

export const UPDATE_PRICING = 'UPDATE_PRICING'
export const UPDATE_REGIONS = 'UPDATE_REGIONS'
export const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE'
export const UPDATE_LOCKED_TRANSACTION_ID = 'UPDATE_LOCKED_TRANSACTION_ID'

export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS'
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION'
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'

export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS'
export const UPDATE_DOCUMENTS_WITHOUT_REPLACE =
  'UPDATE_DOCUMENTS_WITHOUT_REPLACE'
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'

export const UPDATE_PARTIES = 'UPDATE_PARTIES'
export const UPDATE_PARTY = 'UPDATE_PARTY'
export const DELETE_PARTY = 'DELETE_PARTY'

export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_COMPANY = 'UPDATE_ACCOUNT_COMPANY'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'

export const UPDATE_COMPANIES = 'UPDATE_COMPANIES'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'

export const UPDATE_ROLES = 'UPDATE_ROLES'

export const UPDATE_DOCUMENT_UPLOAD_EDITS = 'UPDATE_DOCUMENT_UPLOAD_EDITS'

export const SHOW_MODAL = 'SHOW_MODAL'
export const MODAL_LOCKED_TRANSACTION = 'MODAL_LOCKED_TRANSACTION'
