import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
import PartiesCreate from './PartiesCreate'
// Images
import parties_icon from '../../img/parties_icon.svg'
import large_add from '../../img/large_add.svg'
import _ from 'lodash'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'

class PartiesReadAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      handleCreate: false,
      helpfulHint: false,
      loading: true,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.getTableData()

    if (!localStorage.helpHintPerson && this.state.userAccount.show_hints) {
      this.setState({
        helpfulHint: true,
      })
    }
  }

  handleHelpfulHint = (e) => {
    this.setState({
      helpfulHint: false,
    })

    localStorage.helpHintPerson = true
  }

  handleCloseCreate = (e) => {
    this.setState({
      handleCreate: false,
    })
  }

  handleCreate = (e) => {
    this.setState({
      handleCreate: true,
    })
  }

  handleDataRefresh = (e) => {
    this.getTableData()
  }

  getTableData() {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    this.setState({
      loading: true,
    })

    return this.props.api
      .partyReadAll(companyId, null)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const columns = [
      {
        id: 'clientName',
        Header: 'Name',
        accessor: (d) => d.client_name,
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: (d) => (_.isNil(d.email) || d.email === '' ? 'N/A' : d.email),
      },
      {
        id: 'transactionCount',
        Header: 'Transactions',
        headerClassName: 'text-centered',
        sortable: false,
        accessor: (d) => d.transaction_count,
        Cell: (row) => <div style={{textAlign: 'center'}}>{row.value}</div>,
      },
      {
        id: 'updatedAt',
        Header: 'Last Interaction',
        accessor: (d) => {
          return <Moment format="MMMM D, YYYY">{d.updated_at.date}</Moment>
        },
      },
    ]
    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(appPaths.PartiesRead(rowInfo.original.id))
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={parties_icon} alt="Parties Icon" />
            <p>{this.state.error ? this.state.error : ' No Parties Found'}</p>
          </div>
        </div>
      )
    }

    const tableData = this.props.parties

    return (
      <React.Fragment>
        <div className="tile">
          <div>
            <div className="dashbaord__tile full">
              <div className="inner__tile">
                <h2>All Parties</h2>
                <button
                  type="button"
                  className="link right"
                  onClick={this.handleCreate}
                >
                  <img src={large_add} alt="Large Add" />
                </button>
                <div>
                  {this.state.helpfulHint ? (
                    <div
                      className="helpful__hint"
                      onClick={this.handleHelpfulHint}
                    >
                      <div className="arrow-up" />
                      <h2>Parties</h2>
                      <p>
                        Each person associated with a document will show up
                        here. You can also directly creates a person to be used
                        later.
                      </p>
                    </div>
                  ) : (
                    <div />
                  )}
                  <ReactTableCommon
                    tableData={tableData}
                    isLoading={this.state.loading}
                    columns={columns}
                    onRowClick={onRowClick}
                    tableNoResults={tableNoResults}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.handleCreate && (
          <PartiesCreate
            handleCloseCreate={this.handleCloseCreate}
            handleDataRefresh={this.handleDataRefresh}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  parties: _.map(state.parties.data, (party, key) => party),
})

export default withApi(withRouter(connect(mapStateToProps)(PartiesReadAll)))
