import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
// Components
import FormError from '../common/FormError'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'

class DocumentDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.match.params.di,
      error: '',
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseDelete()
  }

  handleSubmit = (e) => {
    e.preventDefault()

    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId
    const documentId = this.state.documentId

    this.props.api
      .documentDelete(companyId, transactionId, documentId)
      .then((response) => {
        this.props.history.push(
          appPaths.TransactionRead(this.state.transactionId),
        )
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Delete Document?</h5>
                    <p>Deleting a document will remove all document data.</p>

                    {this.props.isLastDocument && (
                      <p style={{color: 'red'}}>
                        WARNING: One (1) document must remain in order to keep
                        the Party(s) associated with the Transaction. Deleting
                        all documents will also delete all Party(s) associated
                        with the transaction.
                      </p>
                    )}
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <FormError error={this.state.error} />
                      <div className="form__group no-margin">
                        <button
                          type="submit"
                          className="btn btn--mod-2 btn--red"
                          value="Delete"
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="btn btn--mod-2 btn--blue"
                          onClick={this.handleHideModal}
                          value="Keep"
                        >
                          Keep
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(DocumentDelete)))
