import produce from 'immer'
import {
  UPDATE_PAGE_SIZE,
  UPDATE_LOCKED_TRANSACTION_ID,
  UPDATE_PRICING,
  UPDATE_REGIONS,
} from '../constants'

export default (
  state = {
    pageSize: 5,
    lockedTransactionId: null,
    pricing: {},
    regions: [],
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_PAGE_SIZE:
      return produce(state, (draftState) => {
        draftState.pageSize = action.payload
      })
    case UPDATE_PRICING:
      return produce(state, (draftState) => {
        draftState.pricing = action.payload
      })
    case UPDATE_REGIONS:
      return produce(state, (draftState) => {
        draftState.regions = action.payload
      })
    case UPDATE_LOCKED_TRANSACTION_ID:
      return produce(state, (draftState) => {
        draftState.lockedTransactionId = action.payload
      })
    default:
      return state
  }
}
