import produce from 'immer'
import {
  UPDATE_LOGIN_TOKEN,
  UPDATE_USER_ACCOUNT,
  UPDATE_ACCOUNT_COMPANY_ID,
  UPDATE_ACCOUNT_COMPANY,
} from '../constants'
import _ from 'lodash'

export default (
  state = {
    loginToken: null,
    userAccount: null,
    accountCompanyId: null,
  },
  action,
) => {
  return produce(state, (draft) => {
    const {payload} = action

    switch (action.type) {
      case UPDATE_LOGIN_TOKEN:
        draft.loginToken = payload
        return draft
      case UPDATE_USER_ACCOUNT:
        draft.userAccount = payload
        return draft
      case UPDATE_ACCOUNT_COMPANY:
        // Not main account
        if (draft.userAccount.id !== 1) {
          let currentUserAccount = draft.userAccount
          currentUserAccount.company = payload
          draft.userAccount = currentUserAccount
        }
        return draft
      case UPDATE_ACCOUNT_COMPANY_ID:
        draft.accountCompanyId = payload
        return draft
      default:
        return draft
    }
  })
}
