import produce from 'immer'
import {
  UPDATE_DOCUMENT_UPLOAD_EDITS,
  UPDATE_DOCUMENTS_WITHOUT_REPLACE,
  UPDATE_DOCUMENTS,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
} from '../constants'
import _ from 'lodash'

export default (
  state = {
    data: {},
    edits: {},
  },
  action,
) => {
  return produce(state, (draft) => {
    const {payload} = action

    switch (action.type) {
      case UPDATE_DOCUMENT_UPLOAD_EDITS: {
        _.forEach(payload, (value, key) => {
          if (_.isNil(draft.edits)) {
            draft.edits = {}
          }
          if (_.isNil(value)) {
            draft.edits = _.omit(draft.edits, [key])
          } else {
            draft.edits[key] = value
          }
        })
        return draft
      }
      case UPDATE_DOCUMENTS: {
        // Empty the data for the transaction we are updating documents on
        // Can't just use the document.transaction.id because what if what we recieve is empty?
        draft.data = _.pickBy(
          draft.data,
          (document, key) =>
            `${document.transaction.id}` !== `${payload.transactionId}`,
        )

        if (_.isArray(payload.data)) {
          _.forEach(payload.data, (document) => {
            draft.data[document.id] = document
          })
        } else {
          draft.data[payload.data.id] = payload.data
        }
        return draft
      }
      case UPDATE_DOCUMENTS_WITHOUT_REPLACE: {
        _.forEach(payload, (document) => {
          draft.data[document.id] = document
        })
        return draft
      }
      case UPDATE_DOCUMENT: {
        if (_.isArray(payload)) {
          _.forEach(payload, (document) => {
            draft.data[document.id] = document
          })
        } else {
          draft.data[payload.id] = payload
        }
        return draft
      }
      case DELETE_DOCUMENT: {
        delete draft.data[payload.id]
        return draft
      }
      default:
        return draft
    }
  })
}
