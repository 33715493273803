import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {showFormErrors} from '../../utils/validate'
// Components
import FormError from '../common/FormError'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'

class DocumentMove extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.match.params.di,
      error: '',
      loginToken: checkToken(this),
      moveDocTransactionId: '',
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleHideModal = (e) => {
    this.props.handleCloseMove(e)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.setState({networkActive: true})

      const companyId = this.state.userAccount.company.id
      const transactionId = this.state.transactionId
      const documentId = this.state.documentId

      this.props.api
        .documentMove(companyId, transactionId, documentId, {
          guid: this.state.moveDocTransactionId,
        })
        .then((response) => {
          let documentResponseObject = jsonApiSpecToFlatObject(
            response.data.data,
            response.data.included,
          )

          this.props.didMoveDocumentToTransaction(
            documentResponseObject.transaction.id,
          )
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()

          this.setState({networkActive: false})
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                      disabled={this.state.networkActive}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Move Document?</h5>
                    <p>
                      Input the transaction to which you want to move this
                      document:
                    </p>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <div className="form__group">
                        <div className="row">
                          <label id="moveDocTransactionIdLabel">
                            Transaction ID
                          </label>
                        </div>
                        <input
                          type="text"
                          name="moveDocTransactionId"
                          ref="moveDocTransactionId"
                          className="form__control"
                          value={this.state.moveDocTransactionId}
                          onChange={this.handleInput}
                          placeholder="Enter transaction ID"
                          title="Transaction ID"
                        />
                        <div className="error" id="moveDocTransactionIdError" />
                      </div>
                      <FormError error={this.state.error} />
                      <div className="form__group no-margin">
                        <button
                          type="submit"
                          className="
                             btn btn--mod-2 btn--green no-margin"
                          disabled={this.state.networkActive}
                        >
                          Move
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(DocumentMove)))
