import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import {pdfjs} from 'react-pdf'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
import {buildUrl, networkErrorDetail} from '../../utils/urls'
// Components
import DocumentPDFView from './DocumentPDFView'
import withApi from '../../api/withApi'
import {isTransactionReadOnly} from '../../utils/transaction'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class DocumentView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.match.params.di,
      error: '',
      handleMove: false,
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
      isReadOnly: false,
    }
  }

  componentWillMount() {
    NProgress.start()

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
      documentId,
    } = this.state

    this.props.api
      .documentRead(companyId, transactionId, documentId)
      .then((response) => {
        let flatData = jsonApiSpecToFlatObject(
          response.data.data,
          response.data.included,
        )

        this.setState({
          guid: flatData.guid,
          documentName: flatData.name,
          isReadOnly: isTransactionReadOnly(flatData.transaction),
        })
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  handleBack = (e) => {
    this.props.history.goBack()
  }

  handleCloseMove = (e) => {
    this.setState({
      handleMove: false,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className=" blue__bar menu__body flexbox space-between">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back{' '}
              <span className="vert__lines">
                <span className="vert__line" />
              </span>
            </button>
            <h2>{this.state.documentName}</h2>
          </div>
        </div>
        <div className="tile user--settings">
          <div className=" large full-width flexbox">
            <div className="dashbaord__tile large full-width col-67 no-padding center">
              <div className="inner__tile padding-none">
                <DocumentPDFView />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(DocumentView)))
