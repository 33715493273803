import {
  UPDATE_LOGIN_TOKEN,
  UPDATE_USER_ACCOUNT,
  UPDATE_ACCOUNT_COMPANY_ID,
  CLEAR_REDUX_CACHE,
  CLEAR_REDUX_CACHE_EXCLUDING_USER,
} from '../constants'

export const clearReduxCache = () => ({
  type: CLEAR_REDUX_CACHE,
})

export const clearReduxCacheExcludingUser = () => ({
  type: CLEAR_REDUX_CACHE_EXCLUDING_USER,
})

export function updateLoginToken(payload) {
  return {
    type: UPDATE_LOGIN_TOKEN,
    payload,
  }
}

export function updateUserAccount(payload) {
  return {
    type: UPDATE_USER_ACCOUNT,
    payload,
  }
}

export function updateAccountCompanyId(payload) {
  return {
    type: UPDATE_ACCOUNT_COMPANY_ID,
    payload,
  }
}
