// ==================================
// Endpoints
// ==================================

// Login
export const ENDPOINT_LOGIN = '/login'
export const ENDPOINT_WELCOME_SHOWN = `/account/welcomeshown`
export const ENDPOINT_PASSWORD_CHANGE = `/password/change`
export const ENDPOINT_PASSWORD_FORGOT = `/password/forgot`
export const ENDPOINT_PASSWORD_RESET = `/password/reset`
export const ENDPOINT_DID_ACCEPT_TOS = `/account/didaccepttos`

// Company
export const ENDPOINT_COMPANY_READ_ALL = `/company/all`
export const ENDPOINT_COMPANY_READ = (companyId) => `/company/${companyId}`
export const ENDPOINT_COMPANY_CREATE = `/company`
export const ENDPOINT_COMPANY_UPDATE = (companyId) => `/company/${companyId}`
export const ENDPOINT_COMPANY_DELETE = (companyId) => `/company/${companyId}`
export const ENDPOINT_COMPANY_SUBSCRIBE = (companyId) =>
  `/company/${companyId}/subscribe`
export const ENDPOINT_COMPANY_CANCEL_SUBSCRIPTION = (companyId) =>
  `/company/${companyId}/cancelsubscription`
export const ENDPOINT_PRICING = `/pricing`
export const ENDPOINT_REGIONS = `/public/regions`

// Role
export const ENDPOINT_ROLE_READ_ALL = `/role/all`

// Account
export const ENDPOINT_ACCOUNT_READ = (companyId, accountId) =>
  `/company/${companyId}/account/${accountId}`
export const ENDPOINT_ACCOUNT_READ_ALL = (companyId) =>
  `/company/${companyId}/account/all`
export const ENDPOINT_ACCOUNT_CREATE = (companyId) =>
  `company/${companyId}/account`
export const ENDPOINT_ACCOUNT_UPDATE = (companyId, accountId) =>
  `/company/${companyId}/account/${accountId}`
export const ENDPOINT_ACCOUNT_DELETE = (companyId, accountId) =>
  `/company/${companyId}/account/${accountId}`
export const ENDPOINT_ACCOUNT_VALIDATE = `/account/validate`

// Transactions
export const ENDPOINT_TRANSACTION_READ_ALL = (companyId) =>
  `company/${companyId}/transaction/all`
export const ENDPOINT_TRANSACTION_READ = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}`
export const ENDPOINT_TRANSACTION_CREATE = (companyId) =>
  `company/${companyId}/transaction`
export const ENDPOINT_TRANSACTION_UPDATE = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}`
export const ENDPOINT_TRANSACTION_DELETE = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}`
export const ENDPOINT_LOCK_TRANSACTION = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}/lock`
export const ENDPOINT_UNLOCK_TRANSACTION = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}/unlock`
export const ENDPOINT_TRANSACTION_PARTIES_READ_ALL = (
  companyId,
  transactionId,
) => `company/${companyId}/transaction/${transactionId}/parties`
export const ENDPOINT_TRANSACTION_PARTIES_CREATE = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}/parties`
export const ENDPOINT_TRANSACTION_PARTIES_DELETE = (companyId, transactionId) =>
  `/company/${companyId}/transaction/${transactionId}/parties`
export const ENDPOINT_TRANSACTION_CLOSE = (companyId, transactionId) =>
  `/company/${companyId}/transaction/${transactionId}/closed`
export const ENDPOINT_TRANSACTION_ABORT = (companyId, transactionId) =>
  `/company/${companyId}/transaction/${transactionId}/abort`
export const ENDPOINT_TRANSACTION_DOCUMENTS_DOWNLOAD = (
  companyId,
  transactionId,
) => `/company/${companyId}/transaction/${transactionId}/document/download`
export const ENDPOINT_TRANSACTION_SEARCH = (companyId) =>
  `company/${companyId}/search/transaction`

// Documents
export const ENDPOINT_DOCUMENT_READ_ALL = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}/document/all`
export const ENDPOINT_DOCUMENT_READ = (companyId, transactionId, documentId) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}`
export const ENDPOINT_DOCUMENT_CREATE = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}/document`
export const ENDPOINT_DOCUMENT_UPDATE = (
  companyId,
  transactionId,
  documentId,
) => `company/${companyId}/transaction/${transactionId}/document/${documentId}`
export const ENDPOINT_DOCUMENT_UPDATE_ALL = (companyId, transactionId) =>
  `company/${companyId}/transaction/${transactionId}/documents`
export const ENDPOINT_DOCUMENT_DELETE = (
  companyId,
  transactionId,
  documentId,
) => `company/${companyId}/transaction/${transactionId}/document/${documentId}`
export const ENDPOINT_DOCUMENT_COMPLETE = (companyId, transactionId) =>
  `/company/${companyId}/transaction/${transactionId}/document/complete`
export const ENDPOINT_DOCUMENT_DOWNLOAD = (
  companyId,
  transactionId,
  documentId,
) =>
  `/company/${companyId}/transaction/${transactionId}/document/${documentId}/download`
export const ENDPOINT_DOCUMENT_PARTIES_READ_ALL = (
  companyId,
  transactionId,
  documentId,
) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/parties`
export const ENDPOINT_DOCUMENT_FIELDS_READ_ALL = (
  companyId,
  transactionId,
  documentId,
) =>
  `/company/${companyId}/transaction/${transactionId}/document/${documentId}/field/all`
export const ENDPOINT_DOCUMENT_REORDER = (
  companyId,
  transactionId,
  documentId,
) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/reorder`
export const ENDPOINT_DOCUMENT_MOVE = (companyId, transactionId, documentId) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/move`
export const ENDPOINT_DOCUMENT_ROTATE = (
  companyId,
  transactionId,
  documentId,
) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/rotatePages`
export const ENDPOINT_DOCUMENT_SPLIT = (companyId, transactionId, documentId) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/split`
export const ENDPOINT_DOCUMENT_DISTRIBUTE = (
  companyId,
  transactionId,
  documentId,
) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/distribute`
export const ENDPOINT_DOCUMENT_NO_SIGNATURES_REQUIRED = (
  companyId,
  transactionId,
  documentId,
) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/nosignaturesrequired`
export const ENDPOINT_DOCUMENT_SEARCH = (companyId) =>
  `company/${companyId}/search/document`

// Party
export const ENDPOINT_PARTY_READ_ALL = (companyId) =>
  `/company/${companyId}/person/all`
export const ENDPOINT_PARTY_READ = (companyId, partyId) =>
  `/company/${companyId}/person/${partyId}`
export const ENDPOINT_PARTY_CREATE = (companyId) =>
  `/company/${companyId}/person`
export const ENDPOINT_PARTY_UPDATE = (companyId, partyId) =>
  `/company/${companyId}/person/${partyId}`
export const ENDPOINT_PARTY_DELETE = (companyId, partyId) =>
  `/company/${companyId}/person/${partyId}`
export const ENDPOINT_PARTY_DOWNLOAD_VERIFICATION_DATA = (companyId, partyId) =>
  `/company/${companyId}/person/${partyId}/verification`
export const ENDPOINT_PARTY_SEARCH = (companyId) =>
  `company/${companyId}/search/person`

// Fields
export const ENDPOINT_FIELD_CREATE = (companyId, transactionId, documentId) =>
  `company/${companyId}/transaction/${transactionId}/document/${documentId}/fields`
export const ENDPOINT_FIELD_DELETE = (
  companyId,
  transactionId,
  documentId,
  fieldId,
) =>
  `/company/${companyId}/transaction/${transactionId}/document/${documentId}/fields/${fieldId}`
export const ENDPOINT_FIELD_DELETE_ALL = (
  companyId,
  transactionId,
  documentId,
) =>
  `/company/${companyId}/transaction/${transactionId}/document/${documentId}/fields`

// ==================================
// HTTP Methods
// ==================================

export const HTTP_METHOD_GET = 'GET'
export const HTTP_METHOD_POST = 'POST'
export const HTTP_METHOD_PUT = 'PUT'
export const HTTP_METHOD_PATCH = 'PATCH'
export const HTTP_METHOD_DELETE = 'DELETE'
export const HTTP_METHODS_AVAILABLE = [
  HTTP_METHOD_GET,
  HTTP_METHOD_POST,
  HTTP_METHOD_PUT,
  HTTP_METHOD_PATCH,
  HTTP_METHOD_DELETE,
]

// ==================================
// HTTP Headers
// ==================================

export const HTTP_HEADER_ACCEPT = 'Accept'
export const HTTP_CONTENT_TYPE_VND_API_JSON = 'application/vnd.api+json'
export const HTTP_CONTENT_TYPE_JSON = 'application/json'
export const HTTP_HEADER_AUTHORIZATION = 'Authorization'
export const HTTP_HEADER_IS_WEB_APP = 'Is-Web-App'
export const AUTHORIZATION_BEARER_PREFIX = 'Bearer'

// Count Values
export const COUNT_ALL = 9999
