import _ from 'lodash'
import {
  YEAR_SEPARATOR_LENGTH,
  YEAR_SEPARATOR_MONTH_SEPARATOR_LENGTH,
  DATE_FORMAT_REGEX,
  YEAR_SEPARATOR_MONTH_LENGTH,
} from '../constants'
import {daysForMonth} from './date'

/**
 * Takes in the current full date (with next string added) and
 * validates that the last character added was an integer.
 *
 * @param {string} currentValue - The current value expected to be used as the date
 *
 * @returns true if the last character in the string is valid or false if the last character in the string isn't valid
 */
export const validateDateInputValue = (currentValue) => {
  if (
    _.isNil(currentValue) ||
    currentValue.length === 0 ||
    currentValue.length === YEAR_SEPARATOR_MONTH_SEPARATOR_LENGTH ||
    currentValue.length === YEAR_SEPARATOR_LENGTH
  ) {
    return true
  }

  if (currentValue.length > YEAR_SEPARATOR_MONTH_SEPARATOR_LENGTH) {
    const dateParts = currentValue.split('-')
    const year = parseInt(dateParts[0])
    const month = parseInt(dateParts[1])
    const daysInMonth = daysForMonth(year, month)
    const day = dateParts[2]

    if (day.length === 1) {
      if (daysInMonth >= 30) {
        return /^[0-3]$/.test(day)
      } else {
        return /^[0-2]$/.test(day)
      }
    } else {
      return parseInt(day) <= daysInMonth && parseInt(day) >= 1
    }
  } else if (currentValue.length > YEAR_SEPARATOR_LENGTH) {
    const dateParts = currentValue.split('-')
    const month = dateParts[1]

    if (month.length === 1) {
      return /^[0-1]$/.test(month)
    } else {
      return (
        /^[0-1][0-9]$/.test(month) &&
        parseInt(month) <= 12 &&
        parseInt(month) >= 1
      )
    }
  }

  const lastCharacter = currentValue.substring(
    currentValue.length - 1,
    currentValue.length,
  )

  return /^[0-9]$/.test(lastCharacter)
}

export const validateInput = (that, e) => {
  e.target.classList.add('active')

  showInputError(that, e.target.name)
}

export const showInputError = (that, refName) => {
  const field = that.refs[refName]
  return showInputErrorForField(that, field)
}

export const showInputErrorForField = (that, field) => {
  // Return validity true for fields that can't be found or don't have a validity property
  if (field === undefined || field === null || field['validity'] === null) {
    return true
  }

  const validity = field.validity
  const fieldLabel = document.getElementById(`${field.name}Label`)
  const fieldError = document.getElementById(`${field.name}Error`)
  if (fieldLabel === null || fieldError === null) {
    return true
  }

  const label = fieldLabel.textContent
  const isPassword = field.name.indexOf('password') !== -1
  const isPasswordConfirm = field.name === 'passwordConfirm'
  const isPasswordCurrent = field.name === 'passwordCurrent'

  if (isPasswordConfirm) {
    if (that.refs.password.value !== that.refs.passwordConfirm.value) {
      that.refs.passwordConfirm.setCustomValidity('Passwords do not match')
    } else {
      that.refs.passwordConfirm.setCustomValidity('')
    }
  }

  if (isPassword && isPasswordCurrent) {
    if (that.refs.password.value === that.refs.passwordCurrent.value) {
      that.refs.password.setCustomValidity(
        'New password must be different from Current password.',
      )
    } else {
      that.refs.password.setCustomValidity('')
    }
  }

  if (field.name === 'email') {
    let emailString = field.value.split('@')

    let domain = emailString[1]

    if (domain !== undefined && domain !== '') {
      if (domain.indexOf('.') === -1) {
        fieldError.textContent = `${label} should be a valid email address.`

        return false
      }
    }
  } else if (field.name.toLowerCase().endsWith('date')) {
    const dateFormatFound = field.value.match(DATE_FORMAT_REGEX)

    if (dateFormatFound === null || dateFormatFound.count === 0) {
      fieldError.textContent = `${label} must be in the format yyyy-mm-dd.`
      return false
    }
  }

  if (typeof validity !== 'undefined' && !validity.valid) {
    if (validity.valueMissing) {
      fieldError.textContent = `${label} is a required field.`
    } else if (validity.typeMismatch) {
      fieldError.textContent = `${label} should be a valid email address.`
    } else if (isPassword && validity.patternMismatch) {
      fieldError.textContent = `${label} must contain at least one digit, 
                                    uppercase character, and
                                    special symbol, and
                                    be at least 8 characters long.`
    } else if (isPasswordConfirm && validity.customError) {
      fieldError.textContent = 'Passwords do not match.'
    } else if (isPassword && validity.customError) {
      fieldError.textContent =
        'New password must be different from Current password.'
    }
    return false
  }

  fieldError.textContent = ''
  return true
}

export const showFormErrors = (that) => {
  const inputs = document.querySelectorAll('form input')
  let isFormValid = true

  _.forEach(inputs, (input) => {
    input.classList.add('active')

    const isInputValid = showInputErrorForField(that, input)

    if (!isInputValid) {
      isFormValid = false
    }
  })

  return isFormValid
}
