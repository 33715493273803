import {
  UPDATE_TRANSACTIONS,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
} from '../constants'

export const updateTransactions = (payload) => ({
  type: UPDATE_TRANSACTIONS,
  payload,
})

export const updateTransaction = (payload) => ({
  type: UPDATE_TRANSACTION,
  payload,
})

export const deleteTransaction = (payload) => ({
  type: DELETE_TRANSACTION,
  payload,
})
