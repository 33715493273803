import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import {Document, Page, pdfjs} from 'react-pdf'
import {Stage, Layer, Image, Transformer, Group} from 'react-konva'
import useImage from 'use-image'
import _ from 'lodash'
// Redux
import {connect} from 'react-redux'
// Utils
import {networkErrorDetail} from '../../utils/urls'
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Images
import left_arrow from '../../img/left_arrow.svg'
import right_arrow from '../../img/right_arrow.svg'
import prepare_add from '../../img/prepare_add.svg'
import prepare_init from '../../img/prepare_init.svg'
import prepare_initial from '../../img/prepare_initial.svg'
import prepare_sign from '../../img/prepare_sign.svg'
import refresh from '../../img/refresh.svg'
import prepare_signature from '../../img/prepare_signature.svg'
import prepare_signature_close from '../../img/prepare_signature_close.svg'
// Components
import DocumentAddField from './DocumentAddField'
import FormError from '../common/FormError'
import DocumentCreateSplit from './DocumentCreateSplit'
import DocumentRemoveFields from './DocumentRemoveFields'
import withApi from '../../api/withApi'
import {
  QUARTER_ROTATION_DEGRESS,
  FULL_ROTATION_DEGRESS,
  NO_ROTATION_DEGRESS,
} from '../../constants'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// the first very simple and recommended way:
const CloseButton = (props) => {
  const [image] = useImage(prepare_signature_close)

  return (
    <Image
      x={1}
      y={1}
      width={20}
      height={20}
      image={image}
      id={props.name}
      onClick={props.handleRemoveField}
    />
  )
}

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
class DocField extends React.Component {
  state = {
    image: null,
  }
  componentDidMount() {
    this.loadImage()
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage()
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad)
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image()
    this.image.src = this.props.src
    this.image.addEventListener('load', this.handleLoad)
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
    })
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  }

  handleRemoveField = (e) => {
    _.forEach(this.props.state.images, (field, i) => {
      if (field.id && field.id.toString() === e.target.attrs.id) {
        NProgress.start()

        const companyId = this.props.props.userAccount.company.id
        const transactionId = this.props.state.transactionId
        const documentId = this.props.state.documentId
        const fieldId = e.target.attrs.id
        this.props.api
          .fieldsDelete(companyId, transactionId, documentId, fieldId)
          .then((response) => {
            this.handleRemoveImage(e)
          })
          .catch((error) => {
            this.setState({
              error: networkErrorDetail(error),
            })
          })
          .finally(() => {
            NProgress.done()
          })
      } else {
        this.handleRemoveImage(e)
      }
    })
  }

  handleRemoveImage = (e) => {
    const imagesCopy = this.props.state.images.filter((image) => {
      return image.name.toString() !== e.target.attrs.id
    })

    let signatureCount = 0
    let initialCount = 0

    _.forEach(imagesCopy, (rect, i) => {
      if (rect.type === 'signature') {
        signatureCount += 1
      } else if (rect.type === 'initial') {
        initialCount += 1
      }
    })

    this.props.state.signatureCount = signatureCount
    this.props.state.initialCount = initialCount

    this.props.handleImageState(imagesCopy)
  }

  handleImageDragEnd = (e) => {
    // console.log(e)

    _.forEach(this.props.images, (rect, i) => {
      if (rect.name.toString() === e.target.attrs.name) {
        // console.log(rect)
        rect.position_x_in_document =
          e.currentTarget.attrs.x / this.props.state.canvasWidth
        rect.position_y_in_document =
          e.currentTarget.attrs.y / this.props.state.canvasHeight
      }
    })
  }

  handleTransformEnd = (e, that) => {
    // console.log(e)

    _.forEach(this.props.images, (rect, i) => {
      if (rect.name.toString() === e.target.attrs.name) {
        // transformer is changing scale
        const node = e.currentTarget
        const scaleX = node.scaleX()

        // we will reset it back
        node.scaleX(1)
        node.scaleY(1)

        rect.position_x_in_document = node.x() / this.props.state.canvasWidth
        rect.position_y_in_document = node.y() / this.props.state.canvasHeight

        rect.width = rect.width * scaleX

        that.props.resizeCanvas()
      }
    })
  }

  render() {
    return (
      <Group
        x={this.props.position_x_in_document * this.props.state.canvasWidth}
        y={this.props.position_y_in_document * this.props.state.canvasHeight}
        draggable={true}
        dragBoundFunc={(pos) => {
          var newY = ''
          var newX = ''
          var width = this.props.width * this.props.canvasWidth
          var height =
            this.props.width *
            this.props.width_to_height_ratio *
            this.props.canvasWidth

          if (pos.y < 0) {
            newY = 0
          } else if (pos.y > this.props.canvasHeight - height) {
            newY = this.props.canvasHeight - height
          } else {
            newY = pos.y
          }

          if (pos.x < 0) {
            newX = 0
          } else if (pos.x > this.props.canvasWidth - width) {
            newX = this.props.canvasWidth - width
          } else {
            newX = pos.x
          }
          return {
            x: newX,
            y: newY,
          }
        }}
        onDragEnd={this.handleImageDragEnd}
        onTransformEnd={(e) => {
          this.handleTransformEnd(e, this)
        }}
        ref={(node) => {
          this.imageNode = node
        }}
        name={this.props.name}
      >
        <Image
          image={this.state.image}
          width={this.props.width * this.props.state.canvasWidth}
          height={
            this.props.width_to_height_ratio *
            this.props.width *
            this.props.state.canvasWidth
          }
          fill={this.props.fill}
          ref={(node) => {
            this.imageNode = node
          }}
          name={this.props.name}
        />
        <CloseButton
          name={this.props.name}
          handleRemoveField={this.handleRemoveField}
          handleImageState={this.props.handleImageState}
        />
      </Group>
    )
  }
}

class TransformerComponent extends React.Component {
  componentDidMount() {
    this.checkNode()
  }
  componentDidUpdate() {
    this.checkNode()
  }
  checkNode() {
    // here we need to manually attach or detach Transformer node
    const stage = this.transformer.getStage()
    const {selectedShapeName} = this.props

    const selectedNode = stage.findOne('.' + selectedShapeName)
    // do nothing if selected node is already attached
    if (selectedNode === this.transformer.node()) {
      if (this.transformer._filterUpToDate === false) {
        this.transformer.forceUpdate()
        // console.log(selectedNode)
      }
      return
    }

    if (selectedNode) {
      // attach to another node
      this.transformer.attachTo(selectedNode)
    } else {
      // remove transformer
      // this.transformer.forceUpdate()
      this.transformer.detach()
    }
    this.transformer.getLayer().batchDraw()
  }
  render() {
    return (
      <Transformer
        ref={(node) => {
          this.transformer = node
        }}
      />
    )
  }
}

class DocumentPrepare extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addField: false,
      pageNumber: 1,
      canvasWidth: 0,
      canvasHeight: 0,
      isDragging: false,
      images: [],
      selectedShapeName: '',
      isLoadingDocument: false,

      initialRotations: [],
      rotations: [],
      documentId: this.props.match.params.di,
      error: '',
      fieldId: '',
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),

      checked: [],
      selectAll: false,

      selectedPages: [],
      handleCreateSplit: false,

      handleRemoveFields: false,
      fieldType: '',

      signatureCount: 0,
      initialCount: 0,
      networkActive: false,
    }
  }

  componentWillMount() {
    this.loadDocument()
    this.loadDocumentDetails()

    this.parseDocumentToState(this.props.document)
  }

  componentWillReceiveProps(nextProps) {
    this.parseDocumentToState(nextProps.document)
  }

  handleImageState = (imagesCopy) => {
    this.setState({
      images: imagesCopy,
    })

    this.resizeCanvas()
  }

  handleChange = () => {
    var selectAll = !this.state.selectAll
    var checkedCopy = this.state.checked
    let newCheckedCopy = []

    checkedCopy.forEach(function (e, index) {
      newCheckedCopy.push(selectAll)
    })

    this.setState({
      checked: newCheckedCopy,
      selectAll: selectAll,
    })
  }

  handleSingleCheckboxChange = (page) => {
    let checkedCopy = this.state.checked

    checkedCopy[page] = !checkedCopy[page]

    this.setState({
      selectAll: _.reduce(
        checkedCopy,
        (current, next) => {
          return current && next
        },
        true,
      ),
      checked: checkedCopy,
    })
  }

  handleGoToPage = (page) => {
    if (page > 0 && page <= this.state.numPages) {
      this.setState({
        pageNumber: page,
      })
    }
  }

  returnPages = (numPages) => {
    let pages = []

    for (let i = 0; i < numPages; i++) {
      pages.push(false)
    }

    this.setState({
      checked: pages,
    })

    this.resizeCanvas()
  }

  handleCloseAddField = (e) => {
    this.setState({
      addField: false,
    })
  }

  handleAddField = (type) => {
    this.setState({
      addField: true,
      error: '',
      fieldType: type,
    })
  }

  handleStageMouseDown = (e) => {
    // clicked on stage - cler selection
    if (e.target === e.target.getStage()) {
      this.setState({
        selectedShapeName: '',
      })
      return
    }
    // clicked on transformer - do nothing
    const clickedOnTransformer =
      e.target.getParent().className === 'Transformer'
    if (clickedOnTransformer) {
      return
    }

    // find clicked rect by its name
    const name = e.target.name()
    const rect = this.state.images.find((r) => r.name === name)
    if (rect) {
      this.setState({
        selectedShapeName: name,
      })
    } else {
      this.setState({
        selectedShapeName: '',
      })
    }

    _.throttle(this.resizeCanvas, 200) /// call the first time page is loaded
  }

  loadDocument = () => {
    NProgress.start()

    this.setState({
      isLoadingDocument: true,
      file: undefined,
      doc: {},
      initialRotations: [],
      rotations: [],
      guid: '',
      documentName: '',
      error: '',
    })

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId
    const documentId = this.state.documentId

    // Get Document Data
    this.props.api
      .documentDownload(companyId, transactionId, documentId)
      .then((response) => {
        var file = new File([response.data], `test-doc.pdf`, {
          type: 'application/pdf',
        })

        this.setState({
          file: file,
          pageNumber: 1,
        })
      })
      .catch((error) => {
        this.setState({
          isLoadingDocument: false,
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  loadDocumentDetails = () => {
    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId
    const documentId = this.state.documentId

    this.props.api
      .documentRead(companyId, transactionId, documentId)
      .catch((error) => {
        this.setState({
          isLoadingDocument: false,
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          isLoadingDocument: false,
        })
      })
  }

  parseDocumentToState = (flatData) => {
    if (_.isNil(flatData)) {
      return
    }

    var rotations = []
    var initialRotations = []
    for (var index = 0; index < flatData.page_count; index++) {
      // Need to save the initial rotation of a given page in order to properly rotate the page
      // Since the web uses the 0 position + the orientation but the API just needs the change in orientation
      // e.g. If the document page has a 90 degree rotation existing on it, the web would need 180 to properly
      // display that page if rotated again 90 degrees, however when we tell the API what the new rotation is
      // it takes in the additional rotation without the original so we would only send the change of 90 degrees
      initialRotations.push(0)
      // Start all rotations as null because if they are explicitly set to 0 it will
      // Mess with the library using the meta data of the document to show the proper orientation
      rotations.push(null)
    }

    this.setState({
      doc: flatData,
      initialRotations,
      rotations,
      guid: flatData.guid,
      documentName: flatData.name,
    })
  }

  resizeCanvas = (e) => {
    this.container &&
      this.setState({
        canvasWidth: this.container.offsetWidth,
        canvasHeight: this.container.offsetHeight,
      })
  }

  handleBack = (e) => {
    this.props.history.goBack()
  }

  onDocumentLoadSuccess = (pdf) => {
    const {numPages} = pdf

    for (var index = 1; index < numPages; index++) {
      pdf.getPage(index).then((page) => this.onPageLoadSuccess(page))
    }

    this.setState({numPages})

    window.addEventListener('resize', _.throttle(this.resizeCanvas, 200), false)

    _.throttle(this.resizeCanvas, 200)

    this.returnPages(numPages)
  }

  onDocumentRender = () => {
    this.resizeCanvas()
  }

  handlePrevious = (e) => {
    if (this.state.pageNumber <= 1) {
      this.setState({
        pageNumber: 1,
      })
    } else {
      this.setState({
        pageNumber: this.state.pageNumber - 1,
      })
    }
  }

  handleNext = ({numPages}) => {
    if (this.state.pageNumber >= this.state.numPages) {
      this.setState({
        pageNumber: this.state.numPages,
      })
    } else {
      this.setState({
        pageNumber: this.state.pageNumber + 1,
      })
    }
  }

  handleHideModal = (e) => {
    this.props.history.push(appPaths.Parties + '?=0')
  }

  handleCloseRemoveFields = (e) => {
    this.setState({
      handleRemoveFields: false,
    })
  }

  handleRemoveFields = (e) => {
    this.setState({
      handleRemoveFields: true,
    })
  }

  handleSaveChanges = (e) => {
    e.preventDefault()

    let selectedPages = []

    const buttonName = e.target.value

    _.forEach(this.state.checked, (page, i) => {
      if (page === true) {
        selectedPages.push(i + 1)
      }
    })

    if (
      selectedPages.length > 0 &&
      selectedPages.length !== this.state.checked.length
    ) {
      this.setState({
        error: '',
        handleCreateSplit: true,
        selectedPages: selectedPages,
      })
    } else {
      const companyId = this.state.userAccount.company.id
      const transactionId = this.state.transactionId
      const documentId = this.state.documentId

      NProgress.start()

      this.setState({networkActive: true})

      var {rotations} = this.state

      this.props.api
        .documentRotate(companyId, transactionId, documentId, {
          data: {
            rotations: _.map(rotations, (rotation) => {
              return _.isNil(rotation) ? 0 : rotation
            }),
          },
        })
        .then((response) => {
          let flatData = jsonApiSpecToFlatObject(
            response.data.data,
            response.data.included,
          )

          if (buttonName === 'saveExit') {
            let DocumentUpload = ''
            if (flatData.upload_in_progress) {
              DocumentUpload = '?upload=true'
            }
            this.props.history.push(
              appPaths.TransactionRead(this.state.transactionId) +
                DocumentUpload,
            )
          } else {
            this.loadDocument()
          }
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()

          this.setState({networkActive: false})
        })
    }
  }

  handleCloseCreateSplit = (e) => {
    this.setState({
      handleCreateSplit: false,
    })
  }

  handleSubmit = (e) => {
    var personId = e

    let type = ''
    if (this.state.fieldType === 'signature') {
      type = prepare_signature
    } else if (this.state.fieldType === 'initial') {
      type = prepare_initial
    }

    let obj = {
      person_id: JSON.stringify(personId),
      type: this.state.fieldType,
      position_x_in_document: 0.1,
      position_y_in_document: 0.1,
      width: 0.24,
      width_to_height_ratio: 0.25,
      geolocation: '',
      page_number: this.state.pageNumber,
      name: JSON.stringify(this.state.images.length + 1),
      src: type,
      newField: true,
    }

    let imagesCopy = this.state.images
    imagesCopy.push(obj)

    let signatureCount = 0
    let initialCount = 0

    _.forEach(imagesCopy, (rect, i) => {
      if (rect.type === 'signature') {
        signatureCount += 1
      } else if (rect.type === 'initial') {
        initialCount += 1
      }
    })

    this.setState({
      addField: false,
      fieldType: '',
      images: imagesCopy,
      signatureCount: signatureCount,
      initialCount: initialCount,
    })

    this.resizeCanvas()
  }

  onPageLoadSuccess = (page) => {
    const {initialRotations} = this.state
    initialRotations[page.pageIndex] = page._pageInfo.rotate
    this.setState({
      initialRotations,
    })
  }

  handleRotate = () => {
    const {pageNumber, checked} = this.state

    if (!_.isNil(checked) && checked.length > 0) {
      _.forEach(checked, (isChecked, pageIndex) => {
        if (isChecked) {
          this.rotatePage90Degrees(pageIndex + 1)
        }
      })
    } else {
      this.rotatePage90Degrees(pageNumber)
    }
  }

  rotatePage90Degrees = (pageNumber) => {
    const {rotations} = this.state
    var rotation = rotations[pageNumber - 1]
    rotation += QUARTER_ROTATION_DEGRESS
    if (rotation === FULL_ROTATION_DEGRESS) {
      rotation = NO_ROTATION_DEGRESS
    }
    this.setPageRotation(pageNumber - 1, rotation)
  }

  setPageRotation = (index, rotation) => {
    const {rotations} = this.state
    rotations[index] = rotation
    this.setState({
      rotations,
    })
  }

  render() {
    const {
      file,
      pageNumber,
      numPages,
      doc,
      initialRotations,
      rotations,
      isLoadingDocument,
    } = this.state

    let rotation =
      initialRotations[pageNumber - 1] +
      (_.isNil(rotations[pageNumber - 1]) ? 0 : rotations[pageNumber - 1])

    return (
      <React.Fragment>
        <div className="menu__body flexbox space-between">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back | &nbsp;
            </button>
            <h2 className="no-margin">{this.state.documentName}</h2>
          </div>
          <form method="post" noValidate>
            <button
              type="submit"
              name="saveExit"
              value="saveExit"
              className="btn btn--solid-green"
              onClick={this.handleSaveChanges}
              disabled={this.state.networkActive}
            >
              Save &amp; Exit
            </button>
          </form>
        </div>
        <div className="tile user--settings">
          <div className=" large full-width flexbox">
            <div className="col-25">
              <div className="signatures">
                <h2 className="header">Document Pages</h2>
                {!isLoadingDocument && (
                  <div className="top__select">
                    <div className="filter__checkbox top">
                      <div>
                        <p>
                          Select pages below to rotate multiple pages at once or
                          by selecting pages below and hitting save you will
                          split the document based on the page selection.
                        </p>

                        {!_.isNil(doc) && doc.upload_in_progress && (
                          <button
                            type="button"
                            className="link"
                            style={{float: 'left'}}
                            onClick={this.handleRotate}
                          >
                            <img src={refresh} alt="Rotate document" />
                          </button>
                        )}
                        <label
                          className="checkcontainer"
                          style={{marginLeft: '40px'}}
                        >
                          Select All
                          <input
                            type="checkbox"
                            onChange={this.handleChange}
                            checked={this.state.selectAll}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                <div className="number__tiles">
                  {!isLoadingDocument &&
                    this.state.checked.length > 0 &&
                    this.state.checked.map((e, i) => (
                      <div key={i}>
                        <div className="filter__checkbox">
                          <div>
                            <label className="checkcontainer">
                              <input
                                type="checkbox"
                                checked={this.state.checked[i]}
                                onChange={() =>
                                  this.handleSingleCheckboxChange(i)
                                }
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <Document file={file} scale={3}>
                          <Page
                            pageNumber={i + 1}
                            scale={3}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            onLoadSuccess={this.onPageLoadSuccess}
                            rotate={
                              initialRotations[i] +
                              (_.isNil(rotations[i]) ? 0 : rotations[i])
                            }
                            onClick={() => this.handleGoToPage(i + 1)}
                          />
                        </Document>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="dashbaord__tile large full-width col-67">
              <FormError error={this.state.error} />
              <div className="inner__tile padding-none">
                <div className="page__select">
                  <div className="filter__checkbox">
                    <div>
                      <label className="checkcontainer">
                        <input
                          type="checkbox"
                          checked={this.state.checked[pageNumber - 1]}
                          onChange={() =>
                            this.handleSingleCheckboxChange(pageNumber - 1)
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pdf__arrows flex">
                  <button
                    type="button"
                    className="link"
                    onClick={this.handlePrevious}
                  >
                    <img src={left_arrow} alt="Left Arrow" />
                  </button>
                  <span>
                    {pageNumber} of {numPages}
                  </span>
                  <button
                    type="button"
                    className="link"
                    onClick={this.handleNext}
                  >
                    <img src={right_arrow} alt="Right arrow" />
                  </button>
                </div>

                {!isLoadingDocument && (
                  <div ref={(el) => (this.container = el)}>
                    <Document
                      file={file}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                      scale={3}
                    >
                      <Page
                        pageNumber={pageNumber}
                        scale={3}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        onRenderSuccess={this.onDocumentRender}
                        rotate={rotation}
                      />
                    </Document>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.addField && (
          <DocumentAddField
            handleSubmit={this.handleSubmit}
            handleCloseAddField={this.handleCloseAddField}
          />
        )}

        {this.state.handleCreateSplit && (
          <DocumentCreateSplit
            handleCreateSplit={this.handleCreateSplit}
            handleCloseCreateSplit={this.handleCloseCreateSplit}
            selectedPages={this.state.selectedPages}
            fields={this.state.images}
            rotations={rotations}
          />
        )}

        {this.state.handleRemoveFields && (
          <DocumentRemoveFields
            handleCloseRemoveFields={this.handleCloseRemoveFields}
            handleRemoveFields={this.handleRemoveFields}
            handleImageState={this.handleImageState}
            fields={this.state.images}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  account: state.account,
  document: state.documents.data[ownProps.match.params.di],
})

export default withApi(withRouter(connect(mapStateToProps)(DocumentPrepare)))
