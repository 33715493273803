export const appPaths = {
  // Base
  Login: '/',

  // Password
  ForgotPassword: '/password/forgot',
  PasswordForgotSent: '/password/forgot/sent',
  ChangePassword: '/password/change',
  PasswordChanged: '/password/changed',

  // Account
  About: '/about',

  // Account
  Account: '/account',
  AccountRead: (companyId, accountId) =>
    `${appPaths.Account}/${companyId}/${accountId}`,
  AccountManage: '/account/manage',
  AccountSettings: '/account/settings',
  AccountValidation: '/account/validation',

  // Parties
  Parties: '/parties',
  PartiesRead: (pi) => `/parties/${pi}`,

  // Dashboard
  Dashboard: '/dashboard',

  // Document
  Document: (transactionId) =>
    `${appPaths.TransactionRead(transactionId)}/documents`,
  DocumentRead: (transactionId, documentId) =>
    `${appPaths.Document(transactionId)}/${documentId}`,
  DocumentUpload: (transactionId, documentId) =>
    `${appPaths.DocumentRead(transactionId, documentId)}/add`,
  DocumentPrepare: (transactionId, documentId) =>
    `${appPaths.DocumentRead(transactionId, documentId)}/prepare`,
  DocumentView: (transactionId, documentId) =>
    `${appPaths.DocumentRead(transactionId, documentId)}/view`,

  // Login
  LoginToken: '/login/token',

  // Search
  Search: '/search',

  // Subscribers
  Subscribers: '/subscribers',
  SubscribersRead: '/subscribers',
  Payment: '/payment',

  // Transaction
  Transaction: '/transactions',
  TransactionRead: (ti) => `${appPaths.Transaction}/${ti}`,
}
