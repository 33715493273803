import produce from 'immer'
import {UPDATE_ROLES} from '../constants'
import _ from 'lodash'

export default (
  state = {
    data: {},
  },
  action,
) => {
  return produce(state, (draft) => {
    const {payload} = action

    switch (action.type) {
      case UPDATE_ROLES: {
        // Empty the data to the newly downloaded
        draft.data = {}

        _.forEach(payload, (role) => {
          draft.data[role.id] = role
        })
        return draft
      }
      default:
        return draft
    }
  })
}
