import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import _ from 'lodash'
// Redux
import {connect} from 'react-redux'
// Components
import FormError from '../common/FormError'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import withApi from '../../api/withApi'
import {buildUrl, networkErrorDetail} from '../../utils/urls'

class DocumentRemoveFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.match.params.di,
      error: '',
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseRemoveFields()
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let fieldsExit = false

    _.forEach(this.props.fields, (field, i) => {
      if (field.id !== undefined) {
        fieldsExit = true
      }
    })

    if (fieldsExit) {
      NProgress.start()

      const companyId = this.state.userAccount.company.id
      const transactionId = this.state.transactionId
      const documentId = this.state.documentId

      this.props.api
        .fieldsDeleteAll(companyId, transactionId, documentId)
        .then((response) => {
          this.props.handleImageState([])

          this.handleHideModal()
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
        })
    } else {
      this.props.handleImageState([])

      this.handleHideModal()
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Remove Fields</h5>
                    <p>
                      Clicking "Remove" will remove all fields associated with
                      this document.
                    </p>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <FormError error={this.state.error} />
                      <div className="form__group">
                        <button
                          type="submit"
                          className="btn btn--mod-2 btn--green"
                          value="Remove"
                        >
                          Remove
                        </button>
                        <button
                          type="button"
                          className="btn btn--mod-2 btn--red"
                          value="Cancel"
                          onClick={this.handleHideModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(
  withRouter(connect(mapStateToProps)(DocumentRemoveFields)),
)
