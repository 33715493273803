import React from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
// Utils
import {appPaths} from '../../utils/appPaths'
// Components
import DocumentUpload from './DocumentUpload'

import DocumentPrepare from './DocumentPrepare'
import DocumentRead from './DocumentRead'
import DocumentView from './DocumentView'

const DocumentSwitch = () => (
  <Switch>
    <Route
      path={appPaths.DocumentUpload(':ti', ':di')}
      component={DocumentUpload}
    />

    <Route
      path={appPaths.DocumentPrepare(':ti', ':di')}
      component={DocumentPrepare}
    />
    <Route
      path={appPaths.DocumentView(':ti', ':di')}
      component={DocumentView}
    />
    <Route
      path={appPaths.DocumentRead(':ti', ':di')}
      component={DocumentRead}
    />
  </Switch>
)

export default withRouter(DocumentSwitch)
