import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

class FormError extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: this.props.error,
    }
  }

  // TODO
  // Route Not implemented
  // Need to add handling for erros
  // Terms and conditions

  // componentWillMount() {
  //   if (this.state.error) {
  //     let error = this.state.error
  //     if (error.status === 401) {
  //       this.props.history.push(appPaths.Login)
  //     }
  //   }
  // }

  render() {
    return (
      <React.Fragment>
        {this.props.error && (
          <div className="form__group">
            <p className="row error">{this.props.error}</p>
            <br />
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(FormError)
