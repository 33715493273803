import {SHOW_MODAL, MODAL_LOCKED_TRANSACTION} from '../constants'

/**
 * Remove the modal shown
 */
export function removeModal() {
  return {
    type: SHOW_MODAL,
    payload: null,
  }
}

/**
 * Shows the Transaction Locked Modal
 *
 * @param {string} lockOwnerName - Name of the person with the lock
 */
export function showTransactionLockedModal(lockOwnerName) {
  return {
    type: SHOW_MODAL,
    payload: {
      name: MODAL_LOCKED_TRANSACTION,
      lockOwnerName: lockOwnerName,
    },
  }
}
