import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import recently_received_icon from '../../img/recently_received_icon.svg'
import withApi from '../../api/withApi'
import {networkErrorDetail, buildUrl} from '../../utils/urls'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [
        {
          name: '',
          received_time: {
            date: '',
          },
          sender: '',
        },
      ],
      error: '',
      loading: true,
      page: 0,
      pageSize: 10,
      pages: 1,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.handleDataRefresh()
  }

  handleDataRefresh = (e) => {
    this.setState({
      refresh: true,
    })
  }

  getTableData(page, pageSize, sorted, filtered, handleRetrievedData) {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    let sortBy = ''
    let filterBy = ''
    page = page + 1

    if (sorted !== undefined && sorted.length > 0) {
      let sortedVal = sorted[0].id

      if (sortedVal === 'sender') {
        sortedVal = 'documentDistributionLog.sender.firstName'
      }

      if (sorted[0].desc) {
        sortBy = 'sort=-document.' + sortedVal
      } else {
        sortBy = 'sort=document.' + sortedVal
      }
    } else {
      sortBy = ''
    }

    let url = buildUrl(
      `/company/${companyId}/recentlyReceived`,
      page,
      pageSize,
      filterBy,
      sortBy,
    )

    return this.props.api
      .get(url)
      .then((response) => {
        let flatData = jsonApiSpecToFlatObject(
          response.data.data,
          response.data.included,
        )

        if (response.data.data !== undefined && response.data.data.length > 0) {
          var totalPages = Math.ceil(
            response.data.meta.total / response.data.meta.page_size,
          )
          if (totalPages <= 1) {
            totalPages = 1
          }
        } else {
          totalPages = 1
        }

        this.setState({
          data: flatData,
          loading: false,
          page: page - 1,
          pages: totalPages,
        })
      })
      .catch((error) => {
        this.setState({
          data: [],
          error: networkErrorDetail(error),
          loading: false,
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    const columns = [
      {
        id: 'name',
        Header: 'Message',
        accessor: (d) => d.message,
      },
      {
        id: 'sender',
        Header: 'Sender',
        accessor: (d) => d.sender,
      },
      {
        id: 'createdAt',
        Header: 'Received',
        accessor: (d) => {
          return <Moment format="MMMM D, YYYY">{d.received_time.date}</Moment>
        },
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(
            appPaths.DocumentRead(
              rowInfo.original.transaction.id,
              rowInfo.original.id,
            ),
          )
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={recently_received_icon} alt="Alt text" />
            <p>
              Transactions awaiting specialized <br />
              distribution will appear here.
            </p>
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <span>
          <ReactTableCommon
            tableState={this}
            columns={columns}
            onRowClick={onRowClick}
            tableNoResults={tableNoResults}
          />
        </span>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(Dashboard)))
