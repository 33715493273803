import React, {Component} from 'react'
// Components
import AccountsReadAllTable from './AccountsReadAllTable'

class AccountsReadAll extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="tile">
          <div>
            <div className="dashbaord__tile full">
              <div className="inner__tile">
                <h2>User Accounts</h2>
                <AccountsReadAllTable />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AccountsReadAll
