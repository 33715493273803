import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
import {updateAccountCompanyId} from '../../redux/actions/account'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
// Components
import AccountCreate from './AccountCreate'
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import parties_icon from '../../img/parties_icon.svg'
import large_add from '../../img/large_add.svg'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import _ from 'lodash'

class AccountsReadAllTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      handleCreate: false,
      loading: true,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.getTableData()
  }

  handleCloseCreate = (e) => {
    this.setState({
      handleCreate: false,
    })
  }

  handleCreate = (e) => {
    this.setState({
      handleCreate: true,
    })
  }

  handleDataRefresh = (e) => {
    this.getTableData()
  }

  getTableData = () => {
    NProgress.start()

    let companyId = this.state.userAccount.company.id

    this.setState({
      loading: true,
    })

    let sortBy = null
    return this.props.api
      .accountReadAll(companyId, sortBy)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const columns = [
      {
        id: 'firstName',
        Header: 'Name',
        accessor: (d) => d.first_name + ' ' + d.last_name,
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: (d) => d.email,
      },
      {
        id: 'totalAccountTransactionCount',
        Header: 'Transactions',
        accessor: (d) => d.total_account_transaction_count,
      },
      {
        id: 'role',
        Header: 'User Type',
        accessor: (d) => {
          if (d.role.id === 1) {
            return 'Super Admin'
          } else if (d.role.id === 2) {
            return 'Admin'
          } else if (d.role.id === 3) {
            return 'User'
          } else {
            return ''
          }
        },
      },
      {
        id: 'lastLoggedIn',
        Header: 'Last Login',
        accessor: (d) => {
          if (d.last_logged_in == null) {
            return 'N/A'
          } else {
            return (
              <Moment format="MMMM D, YYYY">{d.last_logged_in.date}</Moment>
            )
          }
        },
      },
    ]

    if (this.state.userAccount.role.id === 1) {
      columns.splice(2, 0, {
        id: 'company.name',
        Header: 'Subscriber',
        accessor: (d) => d.company.name,
      })
    }

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          if (this.state.userAccount.role.id === 1) {
            this.props.updateAccountCompanyId(rowInfo.original.company.id)
          }

          if (
            rowInfo.original.id !== 1 &&
            rowInfo.original.company.name !== 'VICA'
          ) {
            this.props.history.push(
              appPaths.AccountRead(
                rowInfo.original.company.id,
                rowInfo.original.id,
              ),
            )
          }
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={parties_icon} alt="Account Profile Pic" />
            <p>
              {this.state.error ? this.state.error : ' No User Accounts Found'}
            </p>
          </div>
        </div>
      )
    }

    const tableData = this.props.accounts

    return (
      <React.Fragment>
        {this.state.userAccount.role.id <= 2 && (
          <button
            type="button"
            className="link right"
            onClick={this.handleCreate}
          >
            <img src={large_add} alt="Large Add" />
          </button>
        )}
        <div>
          <ReactTableCommon
            tableData={tableData}
            isLoading={this.state.loading}
            columns={columns}
            onRowClick={onRowClick}
            tableNoResults={tableNoResults}
          />
        </div>

        {this.state.handleCreate && (
          <AccountCreate
            handleCloseCreate={this.handleCloseCreate}
            handleDataRefresh={this.handleDataRefresh}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let accounts = _.map(state.accounts.data, (account, key) => account)
  if (!_.isNil(ownProps.companyAccountId)) {
    accounts = _.filter(accounts, (account) => {
      return `${account.company.id}` === `${ownProps.companyAccountId}`
    })
  }

  return {
    account: state.account,
    accounts,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateAccountCompanyId: (payload) =>
    dispatch(updateAccountCompanyId(payload)),
})

export default withApi(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AccountsReadAllTable),
  ),
)
