import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {networkErrorDetail, buildUrl} from '../../utils/urls'
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
// Components
import FormError from '../common/FormError'
import withApi from '../../api/withApi'

class DocumentReplaceMsg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentId: this.props.match.params.di,
      error: '',
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseReplaceMsg()
  }

  handleReplace = (e) => {
    this.props.handleReplace()
  }

  handleSubmit = (e) => {
    e.preventDefault()

    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId
    const documentId = this.state.documentId

    this.props.api
      .documentDelete(companyId, transactionId, documentId)
      .then((response) => {
        this.props.history.push(
          appPaths.TransactionRead(this.state.transactionId),
        )
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Replace Document?</h5>
                    <p>
                      Replacing a document will remove any signatures,
                      commissioner stamps, and markup present. Would you like to
                      proceed?
                    </p>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <FormError error={this.state.error} />
                      <div className="form__group no-margin">
                        <button
                          type="button"
                          className="btn btn--mod-2 btn--blue"
                          onClick={this.handleReplace}
                          value="Proceed"
                        >
                          Proceed
                        </button>
                        <button
                          type="button"
                          className="btn btn--mod-2 btn--red"
                          value="Cancel"
                          onClick={this.handleHideModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(DocumentReplaceMsg)))
