import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
// Components
import Notifications from './Notifications'
import RecentlyReceived from './RecentlyReceived'
import RecentlyViewed from './RecentlyViewed'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="tile">
          <div className="left">
            <div className="dashbaord__tile large">
              <div className="inner__tile">
                <h2>Documents Awaiting Signatures</h2>
                <div className="inner">
                  <RecentlyViewed />
                </div>
              </div>
            </div>
            <div className="dashbaord__tile large">
              <div className="inner__tile">
                <h2>Recently Received</h2>
                <div className="inner">
                  <RecentlyReceived />
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="dashbaord__tile">
              <div className="inner__tile">
                <h2>Notifications</h2>
                <div className="inner">
                  <Notifications />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Dashboard)
