import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import Moment from 'react-moment'
// Redux
import {connect} from 'react-redux'
// JSPDF
import * as jsPDF from 'jspdf'
import 'jspdf-autotable'
import * as JSZip from 'jszip'
import {saveAs} from 'file-saver'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {showFormErrors} from '../../utils/validate'
import withApi from '../../api/withApi'
// Components
import FormError from '../common/FormError'
import DocumentReadAll from '../document/DocumentReadAll'
import TransactionDelete from './TransactionDelete'
import TransactionParties from './TransactionParties'
import DateInput from '../common/DateInput'
// Images
import large_edit from '../../img/large_edit.svg'
import large_delete from '../../img/large_delete.svg'
import {networkErrorDetail} from '../../utils/urls'
import Modal, {ACTION_DESTRUCTIVE, ACTION_DEFAULT} from '../common/Modal'
import {isTransactionReadOnly} from '../../utils/transaction'
import _ from 'lodash'
import queryString from 'query-string'
import moment from 'moment'
import {appPaths} from '../../utils/appPaths'

const MODAL_DELETE = 'modal_delete'
const MODAL_REQUIRE_DOCUMENT = 'modal_require_document'
const MODAL_PARTIES = 'modal_parties'
const MODAL_ABORT = 'modal_abort'

class TransactionRead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasDocuments: false,
      closingDateError: '',
      complete: false,
      error: '',
      guid: '',
      shownModal: '',
      abortError: '',
      lastUpdate: '',
      loginToken: checkToken(this),
      isReadOnly: true,
      transactionCreator: '',
      transactionId: this.props.match.params.ti,
      transactionName: '',
      transactionParties: '',
      transactionEditable: false,
      closingDate: '',
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  componentWillMount() {
    this.handleUpdateTransaction()

    if (!_.isNil(this.props.location.search)) {
      const qs = queryString.parse(this.props.location.search)
      if (_.has(qs, 'modal')) {
        this.showModal(qs.modal)
      }
    }
  }

  componentDidMount() {
    this.parseTransactionToState(this.props.transaction)
  }

  componentWillReceiveProps(nextProps) {
    this.parseTransactionToState(nextProps.transaction)
  }

  parseTransactionToState = (flatData) => {
    if (_.isNil(flatData)) {
      this.setState({
        hasDocuments: false,
        guid: '',
        isReadOnly: true,
        lastUpdate: '',
        owner: '',
        transaction: {},
        transactionCreator: '',
        transactionName: '',
        transactionParties: '',
        transactionEditable: false,
        closingDate: '',
      })
      return
    }

    let transParties = ''
    if (Array.isArray(flatData.parties)) {
      transParties = flatData.parties.length
    } else if (flatData.parties) {
      transParties = 1
    } else {
      transParties = 0
    }

    if (flatData.closed_at) {
      this.setState({
        complete: true,
      })
    } else {
      this.setState({
        complete: false,
      })
    }

    const closingDateWithoutTime =
      flatData.closing_date != null
        ? flatData.closing_date.date.substr(
            0,
            flatData.closing_date.date.indexOf(' '),
          )
        : ''

    const isReadOnly = isTransactionReadOnly(flatData)

    this.setState({
      hasDocuments: flatData.document_count > 0,
      guid: flatData.guid,
      isReadOnly,
      lastUpdate: flatData.updated_at.date,
      owner: flatData.owner.first_name + ' ' + flatData.owner.last_name,
      transaction: flatData,
      transactionCreator:
        flatData.owner.first_name + ' ' + flatData.owner.last_name,
      transactionName: flatData.name,
      transactionParties: transParties,
      transactionEditable: flatData.editable,
      closingDate: closingDateWithoutTime,
    })
  }

  handleUpdateTransaction = (e) => {
    NProgress.start()

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
    } = this.state

    this.props.api
      .transactionRead(companyId, transactionId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  handleBack = (e) => {
    this.props.history.goBack()
  }

  showModal = (modalName) => {
    if (modalName === '') {
      this.props.history.replace({
        search: window.location.search
          .replace('?modal=' + this.state.shownModal, '')
          .replace('&modal=' + this.state.shownModal, ''),
      })
    } else if (!window.location.search.includes('modal')) {
      var currentSearch = window.location.search
      this.props.history.replace({
        search:
          currentSearch +
          (_.isNil(currentSearch) || _.isEmpty(currentSearch)
            ? '?modal='
            : '&modal=') +
          modalName,
      })
    }

    this.setState({
      shownModal: modalName,
    })
  }

  hideModal = (updateTransaction = true) => {
    this.showModal('')
    if (updateTransaction) {
      this.handleUpdateTransaction()
    }
  }

  abortTransaction = () => {
    NProgress.start()
    this.setState({networkActive: true})

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
    } = this.state

    this.props.api
      .transactionAbort(companyId, transactionId)
      .then(() => {
        this.hideModal()
        this.props.history.push(appPaths.Transaction)
      })
      .catch((error) => {
        this.setState({
          abortError: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({networkActive: false})
      })
  }

  handleEdit = (e) => {
    this.setState({
      edit: true,
    })
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleDownload = (e) => {
    NProgress.start()
    this.setState({networkActive: true})

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
    } = this.state

    this.props.api
      .transactionDocumentsDownload(companyId, transactionId)
      .then((response) => {
        let serverDateFormat = 'YYYY-MM-DD HH:mm:ss'

        const {guid, transactionName, owner, closingDate} = this.state
        const {documents} = this.props

        let displayClosingDate =
          !_.isNil(closingDate) &&
          !_.isEmpty(closingDate) &&
          closingDate !== 'N/A'
            ? moment(closingDate, serverDateFormat).format('MMMM D, YYYY')
            : 'N/A'

        var doc = new jsPDF()

        doc.text('Transaction Information', 14, 20)

        doc.autoTable({
          startY: 25,
          theme: 'plain',
          head: [['Transaction ID', 'Transaction Name', 'Transaction Creator']],
          body: [[guid, transactionName, owner]],
        })

        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 5,
          theme: 'plain',
          head: [['Transaction Date']],
          body: [[displayClosingDate]],
        })

        doc.text('All Documents', 14, doc.previousAutoTable.finalY + 10)

        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 15,
          theme: 'plain',
          head: [['Document Name']],
          body: documents.map((documents, i) => [documents.name]),
        })

        var zip = new JSZip()
        zip.file(transactionName + '.pdf', doc.output())

        var blob = new Blob([response.data], {type: 'application/zip'})
        zip.file('documents.zip', blob)

        /*zip.loadAsync(response.data).then(function(contents) {
          var processedFileCount = 0
          var fileCount = _.keys(contents.files).length
          _.forEach(_.keys(contents.files), (fileName) => {
            contents.files[fileName].async('nodebuffer').then((fileData) => {
              if (_.endsWith(fileName, '.pdf')) {
                zip.file(fileName, fileData)
              } else {
                zip.file(`${fileName}.pdf`, fileData)
              }

              processedFileCount += 1
              if (processedFileCount === fileCount) {
                zip.generateAsync({type: 'blob'}).then(function(content) {
                  saveAs(content, 'trans_docs.zip')
                })
              }
            })
          })
        })*/

        zip.generateAsync({type: 'blob'}).then(function (content) {
          saveAs(content, transactionName + '.zip')
        })
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({networkActive: false})
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let closingDate = this.state.closingDate
    closingDate = new Date(closingDate.replace(/-/g, '/'))
    const today = new Date().setHours(0, 0, 0, 0)

    if (!showFormErrors(this) || closingDate < today) {
      let closingDateError = ''

      if (closingDate < today) {
        closingDateError = 'Transaction date must be today or later.'
      } else {
        closingDateError = ''
      }

      this.setState({
        closingDateError: closingDateError,
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.setState({networkActive: true})

      const {
        userAccount: {
          company: {id: companyId},
        },
        transactionId,
        guid,
        transactionName,
        closingDate,
      } = this.state

      this.props.api
        .transactionUpdate(companyId, transactionId, {
          guid: guid,
          name: transactionName,
          closing_date: closingDate,
        })
        .then((response) => {
          this.setState({
            closingDateError: '',
            error: '',
            edit: false,
          })
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
          this.setState({networkActive: false})
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="blue__bar menu__body flexbox">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back{' '}
              <span className="vert__lines">
                <span className="vert__line" />
              </span>
            </button>
            <h2>{this.state.transactionName}</h2>
          </div>
        </div>
        <div className="tile user--settings">
          <div className=" large full-width flexbox">
            <div className="dashbaord__tile large full-width col-100">
              <div className="inner__tile">
                <form method="post" onSubmit={this.handleSubmit} noValidate>
                  <h2 className="left">Transaction Information</h2>
                  {this.state.edit ? (
                    <div>
                      <button
                        type="submit"
                        className="col-40 btn btn--mod-2 btn--green no-margin"
                        value="Save"
                        disabled={this.state.networkActive}
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <div>
                      {this.state.transaction != null && (
                        <button
                          type="button"
                          className="link right"
                          onClick={() => this.showModal(MODAL_DELETE)}
                        >
                          <img src={large_delete} alt="Large trash" />
                        </button>
                      )}
                      {!this.state.isReadOnly &&
                        this.state.transaction.closing_date_changed_count <
                          5 && (
                          <button
                            type="button"
                            className="link right"
                            onClick={this.handleEdit}
                          >
                            <img src={large_edit} alt="Large edit" />
                          </button>
                        )}
                    </div>
                  )}
                  <FormError error={this.state.error} />
                  <div className="row">
                    <div>
                      <table className="">
                        <tbody>
                          <tr>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label id="guidLabel">Transaction ID</label>
                                </div>
                                {this.state.transactionEditable &&
                                this.state.edit ? (
                                  <div className="form__group">
                                    <div className="row" />
                                    <input
                                      type="text"
                                      name="guid"
                                      ref="guid"
                                      className="form__control"
                                      value={this.state.guid}
                                      onChange={this.handleInput}
                                      placeholder="Enter Transaction ID"
                                      title="Transaction ID"
                                      required
                                    />
                                    <div className="error" id="guidError" />
                                  </div>
                                ) : (
                                  <p>{this.state.guid}</p>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label id="transactionNameLabel">
                                    Transaction Name
                                  </label>
                                </div>
                                {this.state.transactionEditable &&
                                this.state.edit ? (
                                  <div className="form__group">
                                    <div className="row" />
                                    <input
                                      type="text"
                                      name="transactionName"
                                      ref="transactionName"
                                      className="form__control"
                                      value={this.state.transactionName}
                                      onChange={this.handleInput}
                                      placeholder="Enter transaction name"
                                      title="Transaction Name"
                                      required
                                    />
                                    <div
                                      className="error"
                                      id="transactionNameError"
                                    />
                                  </div>
                                ) : (
                                  <p>{this.state.transactionName}</p>
                                )}
                              </div>
                            </td>
                            <td>
                              <DateInput
                                id="closingDate"
                                title="Transaction Date"
                                date={this.state.closingDate}
                                isEditing={this.state.edit}
                                required={true}
                                onChange={(e, id, value) =>
                                  this.setState({[id]: value})
                                }
                              />
                              <div className="error">
                                {this.state.closingDateError}
                              </div>
                            </td>
	                    <td>
                              {/*!this.state.edit && !this.state.isReadOnly && (
                                <button
                                  type="button"
                                  className={'btn no-margin btn--red'}
                                  onClick={() => this.showModal(MODAL_ABORT)}
                                >
                                  Abort
                                </button>
                              )*/}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label>Transaction Creator</label>
                                </div>
                                <p>{this.state.transactionCreator}</p>
                              </div>
                            </td>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label>
                                    Parties{' '}
                                    <button type="button" className="link">
                                      <i
                                        className="fas fa-plus"
                                        onClick={() => {
                                          if (this.state.hasDocuments) {
                                            this.showModal(MODAL_PARTIES)
                                          } else {
                                            this.showModal(
                                              MODAL_REQUIRE_DOCUMENT,
                                            )
                                          }
                                        }}
                                      />
                                    </button>
                                  </label>
                                </div>
                                <p>{this.state.transactionParties} Parties </p>
                              </div>
                            </td>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label>Last Update</label>
                                </div>
                                <p>
                                  <Moment format="MMMM D, YYYY">
                                    {this.state.lastUpdate}
                                  </Moment>
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <DocumentReadAll
            transaction={this.props.transaction}
            canAddDocuments={!this.state.isReadOnly}
            canDistributeDocuments={!this.state.isReadOnly}
            handleDownload={this.handleDownload}
            handleUpdateTransaction={this.handleUpdateTransaction}
          />
        </div>

        {this.state.shownModal === MODAL_DELETE && (
          <TransactionDelete handleCloseDelete={this.hideModal} />
        )}

        {this.state.shownModal === MODAL_ABORT && (
          <Modal
            title="Abort Transaction?"
            message="Aborting the transaction will remove it from your transactions list."
            error={this.state.abortError}
            action="Abort"
            actionType={ACTION_DESTRUCTIVE}
            disabled={this.state.networkActive}
            onCancel={() => this.hideModal()}
            onAction={() => this.abortTransaction()}
          />
        )}

        {this.state.shownModal === MODAL_REQUIRE_DOCUMENT && (
          <Modal
            title="No Documents Added"
            message="You must upload at least One (1) document before any Party(s) are added to the Transaction."
            error={null}
            action="OK"
            actionType={ACTION_DEFAULT}
            disabled={this.state.networkActive}
            onAction={() => this.hideModal(false)}
          />
        )}

        {this.state.shownModal === MODAL_PARTIES && (
          <TransactionParties handleCloseParties={this.hideModal} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let documents = _.map(state.documents.data, (document, key) => document)
  documents = _.filter(
    documents,
    (document) =>
      `${document.transaction.id}` === `${ownProps.match.params.ti}` &&
      !document.upload_in_progress,
  )

  return {
    account: state.account,
    transaction: state.transactions.data[ownProps.match.params.ti],
    documents,
  }
}

export default withApi(withRouter(connect(mapStateToProps)(TransactionRead)))
