import {
  UPDATE_DOCUMENT_UPLOAD_EDITS,
  UPDATE_DOCUMENTS_WITHOUT_REPLACE,
  UPDATE_DOCUMENTS,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
} from '../constants'

/**
 * The edits that are performed against a document. The update is smart enough to merge
 * together the list, adding new, updating existing, and removing values that use
 * null for the object within the [id].
 *
 * The expected form of the object payload is
 *
 * {
 *    [id]: {
 *      name: [name]
 *    }
 * }
 *
 * Where
 *  - [id] is the ID of the document
 *  - [name] is the new name of the document
 *
 * Passing [id]: null will remove the object from the list
 *
 * @param {object} A dictionary in the form of { [id]: { name: [name] } }
 */
export function updateDocumentEdits(payload) {
  return {
    type: UPDATE_DOCUMENT_UPLOAD_EDITS,
    payload,
  }
}

export const updateDocuments = (payload) => ({
  type: UPDATE_DOCUMENTS,
  payload,
})

export const updateDocumentsWithoutReplace = (payload) => ({
  type: UPDATE_DOCUMENTS_WITHOUT_REPLACE,
  payload,
})

export const updateDocument = (payload) => ({
  type: UPDATE_DOCUMENT,
  payload,
})

export const deleteDocument = (payload) => ({
  type: DELETE_DOCUMENT,
  payload,
})
