import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import _ from 'lodash'
// Redux
import {connect} from 'react-redux'
// Components
import FormError from '../common/FormError'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {showFormErrors} from '../../utils/validate'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'

class DocumentCreateSplit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createdTransaction: false,
      documentId: this.props.match.params.di,
      error: '',
      loginToken: checkToken(this),
      guid: '',
      documentName: '',
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseCreateSplit()
  }

  handleInput = (e) => {
    var nextState = {
      [e.target.name]: e.target.value,
    }
    if (e.target.name === 'documentName') {
      nextState['guid'] = e.target.value
    }

    this.setState(nextState)
  }

  handleAddFields = (newDocId, uploadInProgress) => {
    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId
    const documentId = newDocId

    NProgress.start()

    this.setState({networkActive: true})

    var fieldsOnSelectedPages = _.filter(this.props.fields, (field, i) => {
      return _.includes(this.props.selectedPages, field.page_number)
    })

    _.forEach(fieldsOnSelectedPages, (field, i) => {
      if (field.id) {
        delete field.id
      }
    })

    fieldsOnSelectedPages = _.map(fieldsOnSelectedPages, (image) => {
      // Adjust the y position to take the object from web coordinates to iPad coordinates
      image.position_y_in_document = 1 - image.position_y_in_document
      image.page_number =
        _.indexOf(this.props.selectedPages, image.page_number) + 1
      return image
    })

    this.props.api
      .fieldsCreate(companyId, transactionId, documentId, {
        data: fieldsOnSelectedPages,
      })
      .then(() => {
        if (uploadInProgress) {
          this.props.history.push(
            appPaths.TransactionRead(this.state.transactionId) + '?upload=true',
          )
        } else {
          this.props.history.push(
            appPaths.TransactionRead(this.state.transactionId),
          )
        }
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()

        this.setState({networkActive: false})
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      // No Rotations then only split the document.
      if (
        _.filter(this.props.rotations, (rotation) => !_.isNil(rotation))
          .length === 0
      ) {
        this.splitDocument()
      } else {
        this.rotateDocument().then(() => {
          this.splitDocument()
        })
      }
    }
  }

  rotateDocument = async () => {
    return new Promise((resolve, reject) => {
      NProgress.start()

      this.setState({networkActive: true})

      const companyId = this.state.userAccount.company.id
      const transactionId = this.state.transactionId
      const documentId = this.state.documentId
      this.props.api
        .documentRotate(companyId, transactionId, documentId, {
          data: {
            rotations: this.props.rotations,
          },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })

          reject(error)
        })
        .finally(() => {
          NProgress.done()
          this.setState({networkActive: false})
        })
    })
  }

  splitDocument = async () => {
    return new Promise((resolve, reject) => {
      NProgress.start()

      this.setState({networkActive: true})

      const companyId = this.state.userAccount.company.id
      const transactionId = this.state.transactionId
      const documentId = this.state.documentId
      this.props.api
        .documentSplit(companyId, transactionId, documentId, {
          data: {
            name: this.state.documentName,
            guid: this.state.guid,
            pages: this.props.selectedPages,
          },
        })
        .then((response) => {
          let flatData = jsonApiSpecToFlatObject(
            response.data.data,
            response.data.included,
          )

          if (flatData[0].upload_in_progress) {
            this.props.history.push(
              appPaths.TransactionRead(this.state.transactionId) +
                '?upload=true',
            )
          } else {
            this.props.history.push(
              appPaths.TransactionRead(this.state.transactionId),
            )
          }

          resolve(response)
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })

          reject(error)
        })
        .finally(() => {
          NProgress.done()
          this.setState({networkActive: false})
        })
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                      disabled={this.state.networkActive}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>New Document (Split Original)</h5>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <div className="form__group">
                        <div className="row">
                          <label id="documentNameLabel">Document Name</label>
                        </div>
                        <input
                          type="text"
                          name="documentName"
                          ref="documentName"
                          className="form__control"
                          value={this.state.documentName}
                          onChange={this.handleInput}
                          placeholder="Enter document name"
                          title="Document Name"
                          required
                        />
                        <div className="error" id="documentNameError" />
                      </div>
                      <FormError error={this.state.error} />
                      <div className="form__group style--1">
                        <span className="col-60 left">
                          Selecting a subset of the original document will
                          create a new document from the selected pages, while
                          keeping the original document.
                        </span>
                        <button
                          type="submit"
                          className="col-40 btn btn--mod-2 no-margin"
                          value="Create"
                          disabled={this.state.networkActive}
                        >
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(
  withRouter(connect(mapStateToProps)(DocumentCreateSplit)),
)
