import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkUserAccount} from '../../utils/loginToken'
// Components
import Payment from '../subscribers/Payment'

class DocumentRead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      handlePayment: false,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {}

  handleBack = (e) => {
    this.props.history.goBack()
  }

  handleClosePayment = (e) => {
    this.setState({
      handlePayment: false,
    })
  }

  handlePayment = (e) => {
    this.setState({
      handlePayment: true,
    })
  }

  render() {
    if (this.state.userAccount.role.id > 2) {
      return (
        <React.Fragment>
          <div className="blue__bar menu__body flexbox space-between">
            <div className="title">
              <button type="button" className="link" onClick={this.handleBack}>
                <i className="fas fa-chevron-left" /> Back{' '}
                <span className="vert__lines">
                  <span className="vert__line" />
                </span>
              </button>
              <h2>Manage</h2>
            </div>
          </div>
          <div className="tile user--settings">
            <p>You do not have access to this page</p>
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <div className="blue__bar menu__body flexbox space-between">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back{' '}
              <span className="vert__lines">
                <span className="vert__line" />
              </span>
            </button>
            <h2>Manage</h2>
          </div>
        </div>
        <div className="tile user--settings">
          <div className="dashbaord__tile large full-width">
            <div className="inner__tile">
              <h2 className="left">Account Subscription</h2>
              <div>
                <div className="row">
                  <div className="col-60 padding-right-45">
                    <button
                      type="button"
                      className="btn no-margin btn--blue"
                      onClick={this.handlePayment}
                    >
                      Update Credit Card
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.handlePayment && (
          <Payment handleClosePayment={this.handleClosePayment} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withRouter(connect(mapStateToProps)(DocumentRead))
