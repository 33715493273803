import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
import SubscribersCreate from './SubscribersCreate'
// Images
import large_add from '../../img/large_add.svg'
import parties_icon from '../../img/parties_icon.svg'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import _ from 'lodash'

class SubscribersReadAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      handleCreate: false,
      loading: true,
      search: '',
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.getTableData()
  }

  handleCloseCreate = (e) => {
    this.setState({
      handleCreate: false,
    })
  }

  handleCreate = (e) => {
    this.setState({
      handleCreate: true,
    })
  }

  handleDataRefresh = (e) => {
    this.getTableData()
  }

  handleSearch = (e) => {
    this.setState({
      search: e.target.value,
    })
  }

  getTableData() {
    NProgress.start()

    this.setState({
      loading: true,
    })

    let filterBy = null
    let sortBy = null
    return this.props.api
      .companyReadAll(filterBy, sortBy)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
        NProgress.done()
      })
  }

  render() {
    const columns = [
      {
        id: 'name',
        Header: 'Name',
        accessor: (d) => d.name,
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: (d) => d.email,
      },
      {
        id: 'transactionCount',
        Header: 'Trasactions',
        accessor: (d) => d.total_company_transaction_count,
      },
      {
        id: 'createdAt',
        Header: 'Added On',
        accessor: (d) => {
          return <Moment format="MMMM D, YYYY">{d.created_at.date}</Moment>
        },
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(
            appPaths.SubscribersRead + '/' + rowInfo.original.id,
          )
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={parties_icon} alt="Account Profile Pic" />
            <p>
              {this.state.error ? this.state.error : 'No Subscribers Found'}
            </p>
          </div>
        </div>
      )
    }

    let tableData = this.props.companies
    if (!_.isNil(this.state.search) && !_.isEmpty(this.state.search)) {
      tableData = _.filter(
        tableData,
        (company) =>
          _.includes(
            company.name.toLowerCase(),
            this.state.search.toLowerCase(),
          ) ||
          _.includes(
            company.email.toLowerCase(),
            this.state.search.toLowerCase(),
          ),
      )
    }

    return (
      <React.Fragment>
        <div className="tile">
          <div>
            <div className="dashbaord__tile full">
              <div className="inner__tile">
                <h2>Subscribers</h2>
                <form
                  method="post"
                  className="subscribers__search"
                  noValidate=""
                >
                  <input
                    type="text"
                    name="search"
                    placeholder="Type to search subscribers"
                    value={this.state.search}
                    onChange={this.handleSearch}
                  />
                </form>
                {this.state.userAccount.role.id <= 2 && (
                  <button
                    type="button"
                    className="link right"
                    onClick={this.handleCreate}
                  >
                    <img src={large_add} alt="Large Add" />
                  </button>
                )}
                <div>
                  <ReactTableCommon
                    tableData={tableData}
                    isLoading={
                      this.state.loading && this.props.companies.length === 0
                    }
                    columns={columns}
                    onRowClick={onRowClick}
                    tableNoResults={tableNoResults}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.handleCreate && (
          <SubscribersCreate
            handleCloseCreate={this.handleCloseCreate}
            handleDataRefresh={this.handleDataRefresh}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  companies: _.map(state.companies.data, (company, key) => company),
})

export default withApi(withRouter(connect(mapStateToProps)(SubscribersReadAll)))
