// Reads, deserializes, returns the state from localStoage
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

// Takes state object, serializes it and writes it to localStorage
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}

// Removes the serialized state from localStorage
export const resetState = () => {
  try {
    localStorage.removeItem('state')
    console.log('State reset.')
  } catch (err) {
    // Ignore write errors.
  }
}
