import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {validateInput, showFormErrors} from '../../utils/validate'
// Components
import FormError from '../common/FormError'
import RoleReadAllSelect from '../role/RoleReadAllSelect'
import AccountDelete from './AccountDelete'
import TransactionReadAllTable from '../transactions/TransactionReadAllTable'
import PhoneNumberInput from '../common/PhoneNumberInput'
// Images
import large_edit from '../../img/large_edit.svg'
import large_delete from '../../img/large_delete.svg'
import withApi from '../../api/withApi'
import TextInput from '../common/TextInput'
import TextArea from '../common/TextArea'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'

class AccountRead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyId: this.props.match.params.cmp,
      accountId: this.props.match.params.ac,
      edit: false,
      error: '',
      handleDelete: false,
      totalAccountTransactionCount: 0,
      email: '',
      firstName: '',
      lastLogin: '',
      lastName: '',
      loginToken: checkToken(this),
      phoneNumber: '',
      commissionerStamp: '',
      role: '',
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    NProgress.start()

    const companyId = this.state.companyId
    const accountId = this.state.accountId
    this.props.api
      .accountRead(companyId, accountId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  componentWillReceiveProps(nextProps) {
    this.parseAccountToState(nextProps.readAccount)
  }

  componentDidMount() {
    this.parseAccountToState(this.props.readAccount)
  }

  handleBack = (e) => {
    this.props.history.goBack()
  }

  handleCloseDelete = (e) => {
    this.setState({
      handleDelete: false,
    })
  }

  handleDelete = (e) => {
    this.setState({
      handleDelete: true,
    })
  }

  handleEdit = (e) => {
    this.setState({
      edit: true,
    })
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    // Validate email on input
    if (e.target.name === 'email') {
      validateInput(this, e)
    }
  }

  updateValueInState(key, value) {
    this.setState({
      [key]: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      const companyId = this.state.companyId
      const accountId = this.state.accountId

      this.props.api
        .accountUpdate(companyId, accountId, {
          email: e.target.email.value,
          first_name: e.target.firstName.value,
          last_name: e.target.lastName.value,
          phone_number: e.target.phoneNumber.value,
          commissioner_stamp: e.target.commissionerStamp.value,
          role: {
            id: e.target.roleId.value,
          },
        })
        .then((response) => {
          this.setState({
            edit: false,
          })
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
        })
    }
  }

  parseAccountToState = (flatData) => {
    if (_.isNil(flatData)) {
      this.setState({
        totalAccountTransactionCount: 0,
        email: '',
        firstName: '',
        lastLogin: '',
        lastName: '',
        commissionerStamp: '',
        phoneNumber: '',
        role: '',
      })
      return
    }

    this.setState({
      totalAccountTransactionCount: flatData.total_account_transaction_count,
      email: flatData.email,
      firstName: flatData.first_name,
      lastLogin: flatData.last_logged_in,
      lastName: flatData.last_name,
      commissionerStamp: flatData.commissioner_stamp,
      phoneNumber: flatData.phone_number,
      role: flatData.role.name,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="blue__bar menu__body flexbox">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back{' '}
              <span className="vert__lines">
                <span className="vert__line" />
              </span>
            </button>
            <h2>{this.state.firstName + ' ' + this.state.lastName}</h2>
          </div>
        </div>
        <div className="tile user--settings">
          <div className="dashbaord__tile large full-width">
            <div className="inner__tile">
              <form method="post" onSubmit={this.handleSubmit} noValidate>
                <h2>User Profile</h2>
                {this.state.edit ? (
                  <div>
                    <button
                      type="submit"
                      className="col-40 btn btn--mod-2 btn--green no-margin"
                      value="Save"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="link right"
                      onClick={this.handleDelete}
                    >
                      <img src={large_delete} alt="Large trash" />
                    </button>
                    <button
                      type="button"
                      className="link right"
                      onClick={this.handleEdit}
                    >
                      <img src={large_edit} alt="Large edit" />
                    </button>
                  </div>
                )}
                <FormError error={this.state.error} />
                <div className="row">
                  <div className="col-60">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <TextInput
                              id="firstName"
                              title="First Name"
                              value={this.state.firstName}
                              placeholder="Enter first name"
                              isRequired={true}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <TextInput
                              id="lastName"
                              title="Last Name"
                              value={this.state.lastName}
                              placeholder="Enter last name"
                              isRequired={true}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <TextInput
                              id="totalAccountTransactionCount"
                              title="Transaction"
                              value={
                                '' + this.state.totalAccountTransactionCount
                              }
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                              isRequired={false}
                              isEditing={false}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <TextInput
                              id="email"
                              title="Email"
                              value={this.state.email}
                              placeholder="Enter email"
                              isRequired={true}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <PhoneNumberInput
                              id="phoneNumber"
                              title="Phone Number"
                              placeholder="(555) 555-5555"
                              value={this.state.phoneNumber}
                              isRequired={true}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <div className="form__group">
                              <div className="row">
                                <label>Last Log In</label>
                              </div>
                              <p>
                                {this.state.lastLogin === null ? (
                                  'N/A'
                                ) : (
                                  <Moment format="MMMM D, YYYY">
                                    {this.state.lastLogin.date}
                                  </Moment>
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <TextArea
                              id="commissionerStamp"
                              title="Commissioner Stamp"
                              rows={5}
                              value={this.state.commissionerStamp}
                              placeholder="Enter commissioner stamp"
                              isRequired={true}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="col-40">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <div className="form__group">
                              <div className="row">
                                <label>User Role</label>
                              </div>
                              {this.state.edit ? (
                                <RoleReadAllSelect value={this.state.role} />
                              ) : (
                                <p>{this.state.role}</p>
                              )}
                            </div>
                            <p>
                              {this.state.role != null
                                ? this.state.role === 'Admin'
                                  ? 'An admin profile is capable of creating and managing documents, transactions, and parties. They also have access to Company information and payment options.'
                                  : 'A user profile is capable of creating and managing documents, transactions, and parties. A user profile is not able to access payment options, nor remove any users.'
                                : ''}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {this.state.userAccount.role.id !== 1 && (
            <div className="dashbaord__tile large no-padding">
              <div className="inner__tile">
                <h2>Account Transactions</h2>
                <TransactionReadAllTable
                  accountId={parseInt(this.state.accountId)}
                />
              </div>
            </div>
          )}
        </div>

        {this.state.handleDelete && (
          <AccountDelete
            handleCloseDelete={this.handleCloseDelete}
            accountId={this.state.accountId}
            companyId={this.state.companyId}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  account: state.account,
  readAccount: state.accounts.data[ownProps.match.params.ac],
})

export default withApi(withRouter(connect(mapStateToProps)(AccountRead)))
