import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkUserAccount} from '../../utils/loginToken'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import notifications_icon from '../../img/notifications_icon.svg'
import withApi from '../../api/withApi'
import {networkErrorDetail, buildUrl} from '../../utils/urls'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [
        {
          attributes: {
            created_at: {
              date: '',
            },
            event_type: '',
            message: '',
          },
        },
      ],
      error: '',
      loading: true,
      page: 0,
      pageSize: 10,
      pages: 1,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.handleDataRefresh()
  }

  handleDataRefresh = (e) => {
    this.setState({
      refresh: true,
    })
  }

  getTableData(page, pageSize, sorted, filtered, handleRetrievedData) {
    NProgress.start()

    let sortBy = ''
    let filterBy = ''
    page = null

    sortBy = 'sort=-notification.createdAt'

    let url = buildUrl(`/notification`, page, pageSize, filterBy, sortBy)

    return this.props.api
      .get(url)
      .then((response) => {
        if (response.data.data !== undefined && response.data.data.length > 0) {
          var totalPages = Math.ceil(
            response.data.meta.total / response.data.meta.page_size,
          )
          if (totalPages <= 1) {
            totalPages = 1
          }
        } else {
          totalPages = 1
        }

        this.setState({
          data: response.data.data,
          loading: false,
          page: page - 1,
          pages: totalPages,
        })
      })
      .catch((error) => {
        this.setState({
          data: [],
          error: networkErrorDetail(error),
          loading: false,
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    const columns = [
      {
        id: 'state',
        Header: '',
        width: 25,
        accessor: (d) => {
          if (
            d.attributes.event_type === 'received' ||
            d.attributes.event_type === 'incomplete'
          ) {
            return (
              <div>
                <div className="circle circle--yellow" />
              </div>
            )
          } else if (
            d.attributes.event_type === 'complete' ||
            d.attributes.event_type === 'delivered'
          ) {
            return (
              <div>
                <div className="circle circle--green" />
              </div>
            )
          } else {
            return (
              <div>
                <div className="circle circle--grey" />
              </div>
            )
          }
        },
        sortable: false,
      },
      {
        id: 'createdAt',
        Header: '',
        className: 'notifications',
        accessor: (d) => {
          return (
            <div>
              <p>{d.attributes.message}</p>
              <span className="time">
                <Moment format="MMMM D, YYYY">
                  {d.attributes.created_at.date}
                </Moment>
              </span>
            </div>
          )
        },
        sortable: false,
      },
    ]

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={notifications_icon} alt="Alt text" />
            <p>
              New notifications from the system <br />
              will appear here.
            </p>
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <span>
          <ReactTableCommon
            tableState={this}
            columns={columns}
            loading={this.state.loading}
            getTableData={this.getTableData}
            tableNoResults={tableNoResults}
            handleDataRefresh={this.handleDataRefresh}
          />
        </span>
        {this.state.data.length > 0 && (
          <p className="status">Notifications are up to date</p>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(Dashboard)))
