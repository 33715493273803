import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {showFormErrors} from '../../utils/validate'
// Components
import DocumentMove from './DocumentMove'
import TransactionParties from '../transactions/TransactionParties'
import DocumentReplace from './DocumentReplace'
import DocumentReplaceMsg from './DocumentReplaceMsg'
import DocumentDownload from './DocumentDownload'
import FormError from '../common/FormError'
import DocumentDelete from './DocumentDelete'
// Images
import large_edit from '../../img/large_edit.svg'
import move from '../../img/move.svg'
import document_view from '../../img/document_view.svg'
import download from '../../img/download.svg'
import large_delete from '../../img/large_delete.svg'
import split from '../../img/split.svg'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'
import {isTransactionReadOnly} from '../../utils/transaction'
import _ from 'lodash'
import queryString from 'query-string'

class DocumentRead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      isLastDocument: true,
      handleDelete: false,
      handleMove: false,
      handleParties: false,
      handleReplace: false,
      handleReplaceMsg: false,
      loading: true,
      penColor: null,
      documentID: '',
      documentName: '',
      parentTransaction: '',
      documentCreator: '',
      parties: '',
      lastUpdate: '',
      expirationDate: '',
      handleDistribute: false,
      documentId: this.props.match.params.di,
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
      isReadOnly: false,
      networkActive: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.parseDocumentToState(nextProps.document)
  }

  componentDidMount() {
    this.parseDocumentToState(this.props.document)
  }

  componentWillMount() {
    this.handleDocDetails()

    if (!_.isNil(this.props.location.search)) {
      const qs = queryString.parse(this.props.location.search)
      if (_.has(qs, 'modal') && qs.modal === 'parties') {
        this.handleParties()
      }
    }
  }

  handleDocDetails = (e) => {
    NProgress.start()

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
      documentId,
    } = this.state

    this.props.api
      .documentRead(companyId, transactionId, documentId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  parseDocumentToState = (flatData) => {
    if (_.isNil(flatData)) {
      this.setState({
        isLastDocument: true,
        isReadOnly: false,
        guid: '',
        documentName: '',
        parentTransaction: '',
        documentCreator: '',
        pageCount: 0,
        parties: 0,
        penColor: null,
        lastUpdate: '',
        expirationDate: '',
      })
      return
    }
    let partyCount = ''
    if (Array.isArray(flatData.parties)) {
      partyCount = flatData.parties.length
    } else if (flatData.parties) {
      partyCount = 1
    } else {
      partyCount = 0
    }

    this.setState({
      isLastDocument: flatData.transaction.documents.length === 1,
      isReadOnly: isTransactionReadOnly(flatData.transaction),
      guid: flatData.guid,
      documentName: flatData.name,
      parentTransaction: flatData.transaction.name,
      documentCreator:
        flatData.owner.first_name + ' ' + flatData.owner.last_name,
      pageCount: flatData.page_count,
      parties: partyCount,
      penColor: flatData.pen_color,
      lastUpdate: flatData.updated_at.date,
      expirationDate: flatData.expiration_date,
    })
  }

  handleBack = (e) => {
    this.props.history.goBack()
  }

  handleCloseDelete = (e) => {
    this.setState({
      handleDelete: false,
    })
  }

  handleDelete = (e) => {
    this.setState({
      handleDelete: true,
    })
  }

  handleCloseMove = (e) => {
    this.setState({
      handleMove: false,
    })
  }

  didMoveDocumentToTransaction = (transactionId) => {
    this.props.history.replace(appPaths.TransactionRead(transactionId))
  }

  handleMove = (e) => {
    this.setState({
      handleMove: true,
    })
  }

  handleCloseReplace = (e) => {
    this.setState({
      handleReplace: false,
    })
  }

  handleReplace = (e) => {
    this.setState({
      handleReplace: true,
      handleReplaceMsg: false,
    })
  }

  handleCloseReplaceMsg = (e) => {
    this.setState({
      handleReplaceMsg: false,
    })
  }

  handleReplaceMsg = (e) => {
    this.setState({
      handleReplaceMsg: true,
    })
  }

  handleDownload = (e) => {
    DocumentDownload(this)
    this.setState({networkActive: true})
  }

  handleEdit = (e) => {
    this.setState({
      edit: true,
    })
  }

  handleSettings = (e) => {
    this.setState({
      penColor: e,
    })

    this.handleSubmit('', e)
  }

  handleInput = (e) => {
    var nextState = {
      [e.target.name]: e.target.value,
    }

    if (e.target.name === 'documentName') {
      nextState['guid'] = e.target.value
    }

    this.setState(nextState)
  }

  handleCloseParties = (e) => {
    this.props.history.replace({
      search: window.location.search
        .replace('?modal=parties', '')
        .replace('&modal=parties', ''),
    })

    this.setState({
      handleParties: false,
    })

    this.handleDocDetails()
  }

  handleParties = (e) => {
    if (!window.location.search.includes('modal')) {
      var currentSearch = window.location.search
      this.props.history.replace({
        search:
          currentSearch +
          (_.isNil(currentSearch) || _.isEmpty(currentSearch)
            ? '?modal='
            : '&modal=') +
          'parties',
      })
    }

    this.setState({
      handleParties: true,
    })
  }

  handlePrepare = (e) => {
    this.props.history.push(
      appPaths.DocumentPrepare(this.state.transactionId, this.state.documentId),
    )
  }

  handleSplit = (e) => {
    this.props.history.push(
      appPaths.DocumentPrepare(this.state.transactionId, this.state.documentId),
    )
  }

  handleView = (e) => {
    this.props.history.push(
      appPaths.DocumentView(this.state.transactionId, this.state.documentId),
    )
  }

  handleCloseDistribute = (e) => {
    this.setState({
      handleDistribute: false,
    })
  }

  handleDistribute = (e) => {
    this.setState({
      handleDistribute: true,
    })
  }

  handleSubmit = (e, pc) => {
    let penColor = ''
    if (e !== undefined && e !== '') {
      e.preventDefault()
      penColor = this.state.penColor
    } else {
      penColor = pc
    }

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
        emailError: true,
      })
    } else {
      NProgress.start()

      this.setState({networkActive: true})

      const {
        userAccount: {
          company: {id: companyId},
        },
        transactionId,
        documentId,
      } = this.state

      this.props.api
        .documentUpdate(companyId, transactionId, documentId, {
          name: this.state.documentName,
          guid: this.state.guid,
          pen_color: penColor,
        })
        .then((response) => {
          this.setState({
            edit: false,
          })
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
          this.setState({networkActive: true})
        })
    }
  }

  documentIsLoaded = () =>
    !_.isNil(this.state.documentName) && !_.isEmpty(this.state.documentName)

  render() {
    return (
      <React.Fragment>
        <a href="" download="" className="doc-text" />
        <div className="blue__bar menu__body flexbox space-between">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back{' '}
              <span className="vert__lines">
                <span className="vert__line" />
              </span>
            </button>
            <h2>{this.state.documentName}</h2>
          </div>
        </div>
        <div className="tile user--settings">
          <div className="dashbaord__tile large full-width">
            <div className="inner__tile">
              <form method="post" onSubmit={this.handleSubmit} noValidate>
                <div>
                  <div className="row">
                    <div className="col-60 padding-right-45">
                      <h2 className="left">Document Information</h2>

                      <FormError error={this.state.error} />
                      {this.state.edit ? (
                        <div>
                          <button
                            type="submit"
                            className="col-40 btn btn--mod-2 btn--green no-margin"
                            value="Save"
                            disabled={this.state.networkActive}
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        this.documentIsLoaded() &&
                        !this.state.isReadOnly && (
                          <div>
                            <button
                              type="button"
                              className="link right"
                              onClick={this.handleDelete}
                              disabled={this.state.networkActive}
                            >
                              <img src={large_delete} alt="Large trash" />
                            </button>
                            <button
                              type="button"
                              className="link right"
                              onClick={this.handleEdit}
                              disabled={this.state.networkActive}
                            >
                              <img src={large_edit} alt="Large edit" />
                            </button>
                          </div>
                        )
                      )}
                      <table className="">
                        <tbody>
                          <tr>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label id="documentNameLabel">
                                    Document Name
                                  </label>
                                </div>
                                {this.state.edit ? (
                                  <div className="form__group">
                                    <input
                                      type="text"
                                      name="documentName"
                                      ref="documentName"
                                      className="form__control"
                                      value={this.state.documentName}
                                      onChange={this.handleInput}
                                      placeholder="Enter Document name"
                                      title="Document name"
                                      required
                                    />
                                    <div
                                      className="error"
                                      id="documentNameError"
                                    />
                                  </div>
                                ) : (
                                  <p>{this.state.documentName}</p>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label id="parentTransactionLabel">
                                    Parent Transaction
                                  </label>
                                </div>
                                <p>{this.state.parentTransaction}</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label>Document Creator</label>
                                </div>
                                <p>{this.state.documentCreator}</p>
                              </div>
                            </td>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label>
                                    Parties{' '}
                                    <button type="button" className="link">
                                      <i
                                        className="fas fa-plus"
                                        onClick={this.handleParties}
                                      />
                                    </button>
                                  </label>
                                </div>
                                <p>{this.state.parties} Parties</p>
                              </div>
                            </td>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label>Last Update</label>
                                </div>
                                <p>
                                  {!_.isNil(this.state.lastUpdate) &&
                                  !_.isEmpty(this.state.lastUpdate) ? (
                                    <Moment format="MMMM D, YYYY">
                                      {this.state.lastUpdate}
                                    </Moment>
                                  ) : (
                                    'N/A'
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form__group">
                                <div className="row">
                                  <label>Expiration Date</label>
                                </div>
                                <p>
                                  {this.state.expirationDate === null ? (
                                    'N/A'
                                  ) : (
                                    <Moment format="MMMM D, YYYY">
                                      {this.state.expirationDate.date}
                                    </Moment>
                                  )}
                                </p>
                              </div>
                            </td>
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-40 flex__vertical">
                      <div className="document__view border-left">
                        <button className="row link" onClick={this.handleView}>
                          <img src={document_view} alt="Document" />
                          <p>
                            {this.documentIsLoaded()
                              ? this.state.pageCount
                              : '--'}{' '}
                            Pages
                          </p>
                        </button>
                      </div>
                      <div className="document__actions">
                        {this.documentIsLoaded() && !this.state.isReadOnly && (
                          <button
                            type="button"
                            className="link flex__vertical"
                            onClick={this.handleMove}
                            disabled={this.state.networkActive}
                          >
                            <img src={move} alt="Move" />
                            <p>Move</p>
                          </button>
                        )}

                        {this.documentIsLoaded() && (
                          <button
                            type="button"
                            className="link flex__vertical"
                            onClick={this.handleDownload}
                            disabled={this.state.networkActive}
                          >
                            <img src={download} alt="Document" />
                            <p>Download</p>
                          </button>
                        )}

                        {this.documentIsLoaded() &&
                          !this.state.isReadOnly &&
                          this.state.pageCount > 1 && (
                            <button
                              type="button"
                              className="link flex__vertical"
                              onClick={this.handleSplit}
                              disabled={this.state.networkActive}
                            >
                              <img src={split} alt="Split" />
                              <p>Manage</p>
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {this.state.handleDelete && (
          <DocumentDelete
            handleCloseDelete={this.handleCloseDelete}
            isLastDocument={this.state.isLastDocument}
          />
        )}

        {this.state.handleMove && (
          <DocumentMove
            handleCloseMove={this.handleCloseMove}
            didMoveDocumentToTransaction={this.didMoveDocumentToTransaction}
          />
        )}

        {this.state.handleParties && (
          <TransactionParties handleCloseParties={this.handleCloseParties} />
        )}

        {this.state.handleReplace && (
          <DocumentReplace handleCloseReplace={this.handleCloseReplace} />
        )}

        {this.state.handleReplaceMsg && (
          <DocumentReplaceMsg
            handleCloseReplaceMsg={this.handleCloseReplaceMsg}
            handleReplace={this.handleReplace}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    account: state.account,
    document: state.documents.data[ownProps.match.params.di],
  }
}

export default withApi(withRouter(connect(mapStateToProps)(DocumentRead)))
