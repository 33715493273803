import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import ReactTable from 'react-table'
import _ from 'lodash'
// Styles
import 'react-table/react-table.css'
import {updatePageSize} from '../../redux/actions/preferences'
import {connect} from 'react-redux'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

class DragTrComponent extends React.Component {
  render() {
    const {children = null, rowInfo, onClick = null} = this.props
    if (rowInfo) {
      const {original, index} = rowInfo
      const rowId =
        _.isNil(original) || _.isNil(original.id)
          ? index.toString()
          : original.id.toString()
      return (
        <Draggable key={rowId} index={index} draggableId={rowId}>
          {(draggableProvided, draggableSnapshot) => (
            <a
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              onClick={onClick}
            >
              <ReactTable.defaultProps.TrComponent>
                {_.map(children, (child) => {
                  // Make the row handle only the reorder column on the table.
                  // Must be the first item in the list as it seems to add ${index}- to the key.
                  if (child.key === '0-reorder') {
                    const copyOfChild = _.clone(child)
                    copyOfChild.props = {
                      ...copyOfChild.props,
                      ...draggableProvided.dragHandleProps,
                    }
                    return copyOfChild
                  } else {
                    return child
                  }
                })}
              </ReactTable.defaultProps.TrComponent>
            </a>
          )}
        </Draggable>
      )
    } else
      return (
        <ReactTable.defaultProps.TrComponent>
          {children}
        </ReactTable.defaultProps.TrComponent>
      )
  }
}

class DropTbodyComponent extends React.Component {
  render() {
    const {children = null} = this.props

    return (
      <Droppable droppableId="droppable">
        {(droppableProvided, droppableSnapshot) => (
          <div ref={droppableProvided.innerRef}>
            <ReactTable.defaultProps.TbodyComponent>
              {children}
              {droppableProvided.placeholder}
            </ReactTable.defaultProps.TbodyComponent>
          </div>
        )}
      </Droppable>
    )
  }
}

class ReactTableCommon extends Component {
  render() {
    // Set states for table
    const {
      tableData = [],
      isLoading,
      isReorderingElements,
      style,
      className,
      columns,
      onDragEnd,
      onRowClick,
    } = this.props

    return (
      <React.Fragment>
        <div>
          {tableData.length > 0 || isLoading ? (
            isReorderingElements ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <ReactTable
                  style={style}
                  TbodyComponent={DropTbodyComponent}
                  TrComponent={DragTrComponent}
                  getTrProps={onRowClick}
                  data={tableData}
                  className={className}
                  columns={columns}
                  loading={tableData.length === 0 && isLoading}
                  defaultPageSize={tableData.length}
                  pageSize={tableData.length}
                  showPagination={false}
                  defaultSortDesc={true}
                />
              </DragDropContext>
            ) : (
              <ReactTable
                data={tableData}
                className={className}
                columns={columns}
                loading={tableData.length === 0 && isLoading}
                defaultPageSize={tableData.length}
                pageSize={tableData.length}
                showPagination={false}
                defaultSortDesc={true}
                getTrProps={this.props.onRowClick}
              />
            )
          ) : (
            this.props.tableNoResults()
          )}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = null
const mapDispatchToProps = (dispatch) => ({
  updatePageSize: (payload) => dispatch(updatePageSize(payload)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReactTableCommon),
)
