import {combineReducers} from 'redux'
import account from './account'
import accounts from './accounts'
import roles from './roles'
import preferences from './preferences'
import documents from './documents'
import transactions from './transactions'
import parties from './parties'
import modals from './modals'
import companies from './company'
import {CLEAR_REDUX_CACHE, CLEAR_REDUX_CACHE_EXCLUDING_USER} from '../constants'

const appReducer = combineReducers({
  account,
  accounts,
  roles,
  companies,
  preferences,
  documents,
  transactions,
  parties,
  modals,
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_REDUX_CACHE) {
    state = undefined
  } else if (action.type === CLEAR_REDUX_CACHE_EXCLUDING_USER) {
    const account = state.account
    state = {
      account,
    }
  }

  return appReducer(state, action)
}

export default rootReducer
