export const YEAR_LENGTH = 4
export const YEAR_SEPARATOR_LENGTH = YEAR_LENGTH + 1
export const YEAR_SEPARATOR_MONTH_LENGTH = 7
export const YEAR_SEPARATOR_MONTH_SEPARATOR_LENGTH =
  YEAR_SEPARATOR_MONTH_LENGTH + 1
export const MAX_DATE_LENGTH = 10
export const DATE_SEPARATOR = '-'
// const DATE_FORMAT = 'yyyy-mm-dd'
export const DATE_FORMAT_REGEX =
  '[0-9]{4}' + DATE_SEPARATOR + '[0-9]{2}' + DATE_SEPARATOR + '[0-9]{2}'

export const TRANSACTION_STATE_ABORTED = 'aborted'

/// Number of days in a month indexed by 0 - January to 11 - december
export const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const QUARTER_ROTATION_DEGRESS = 90
export const FULL_ROTATION_DEGRESS = 360
export const NO_ROTATION_DEGRESS = 0

export const MILLISECONDS_PER_SECOND = 1000
export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR
