import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken} from '../../utils/loginToken'
// Components
import FormError from '../common/FormError'
// Images
import hand_shake from '../../img/hand_shake.png'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'

class SubscriberDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyAccountId: this.props.match.params.ac,
      error: '',
      loginToken: checkToken(this),
      networkActive: false,
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseDelete()
  }

  handleSubmit = (e) => {
    e.preventDefault()

    NProgress.start()

    this.setState({networkActive: true})

    const companyAccountId = this.state.companyAccountId
    this.props.api
      .companyDelete(companyAccountId)
      .then(() => {
        this.props.history.push(appPaths.Subscribers)
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()

        this.setState({networkActive: false})
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__two">
              <button
                type="button"
                className="close link"
                onClick={this.handleHideModal}
                disabled={this.state.networkActive}
              >
                <i className="fas fa-times" />
              </button>
              <div className="modal__form">
                <div className="modal__inner">
                  <h1>Delete Subscriber</h1>
                  <form method="post" onSubmit={this.handleSubmit} noValidate>
                    <FormError error={this.state.error} />
                    <div className="form__group">
                      <div className="row">
                        <button
                          type="submit"
                          className="btn btn--blue-solid"
                          value="Create"
                          disabled={this.state.networkActive}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal__mark">
                <img src={hand_shake} className="modal__img" alt="logo" />

                <div className="top__angle" />
                <div className="bottom__angle" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(SubscriberDelete)))
