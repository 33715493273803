import React from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
// Utils
import {appPaths} from '../../utils/appPaths'
// Components
import PartiesRead from './PartiesRead'
import PartiesReadAll from './PartiesReadAll'

const PartiesSwitch = () => (
  <Switch>
    <Route path={appPaths.PartiesRead(':ac')} component={PartiesRead} />
    <Route path={appPaths.Parties} component={PartiesReadAll} />
  </Switch>
)

export default withRouter(PartiesSwitch)
