import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Components
import FormError from '../common/FormError'
// Utils
import {checkToken} from '../../utils/loginToken'
import {validateInput, showFormErrors} from '../../utils/validate'
// Images
import hand_shake from '../../img/hand_shake.png'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import RegionAutoSuggest from '../common/RegionAutoSelect'
import SubregionAutoSuggest from '../common/SubregionAutoSelect'
import _ from 'lodash'

class SubscriberCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyName: '',
      email: '',
      error: '',
      loginToken: checkToken(this),
      networkActive: false,
      regionCode: '',
    }
  }

  componentWillMount() {
    NProgress.start()

    this.setState({loading: true})

    this.props.api.regions().catch((error) => {
      this.setState({
        error: networkErrorDetail(error),
      })
    })
  }

  handleHideModal = (e) => {
    this.props.handleCloseCreate()
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    // Validate email on input
    if (e.target.name === 'email') {
      validateInput(this, e)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    var subregionName = null
    if (!_.isNil(e.target.subregionCode)) {
      subregionName = e.target.subregionCode.attributes.value.nodeValue
    }

    const regionName = e.target.regionCode.attributes.value.nodeValue
    const regionMatched = _.first(
      _.filter(this.props.regions, (region) => {
        return region.name === regionName
      }),
    )

    if (_.isNil(regionMatched)) {
      this.setState({
        error: 'You must select a valid country.',
      })
      return
    }

    var regionCode = ''
    if (_.has(regionMatched, 'subregions')) {
      if (_.isNil(subregionName) || subregionName === '') {
        this.setState({
          error: 'You must select a valid region.',
        })
        return
      }

      regionCode = _.first(
        _.filter(regionMatched.subregions, (subregion) => {
          return subregion.name === subregionName
        }),
      ).code
    } else {
      regionCode = regionMatched.code
    }

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.setState({networkActive: true})

      this.props.api
        .companyCreate({
          name: e.target.companyName.value,
          email: e.target.email.value,
          invoice_email: e.target.email.value,
          region_code: regionCode,
        })
        .then((response) => {
          this.props.handleDataRefresh()
          this.props.handleCloseCreate()
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
          this.setState({networkActive: false})
        })
    }
  }

  didUpdateRegionSelection = (newRegionName) => {
    const regionMatched = _.first(
      _.filter(this.props.regions, (region) => {
        return region.name === newRegionName
      }),
    )

    if (!_.isNil(regionMatched)) {
      this.setState({regionCode: regionMatched.code})
    } else {
      this.setState({regionCode: ''})
    }
  }

  render() {
    var regionName = 'N/A'
    var subregionName = 'N/A'
    var subregions = []

    if (
      !_.isNil(this.state.regionCode) &&
      this.state.regionCode.trim() !== ''
    ) {
      const regionCode = this.state.regionCode.split('-')[0]
      const regionMatch = _.first(
        _.filter(this.props.regions, (region) => {
          return region.code === regionCode
        }),
      )

      if (!_.isNil(regionMatch)) {
        regionName = regionMatch.name
        subregions = regionMatch.subregions || []

        if (this.state.regionCode.includes('-')) {
          const subregionMatch = _.first(
            _.filter(subregions, (subregion) => {
              return subregion.code === this.state.regionCode
            }),
          )

          if (!_.isNil(subregionMatch)) {
            subregionName = subregionMatch.name
          }
        }
      }
    }

    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__two">
              <button
                type="button"
                className="close link"
                onClick={this.handleHideModal}
                disabled={this.state.networkActive}
              >
                <i className="fas fa-times" />
              </button>
              <div className="modal__form">
                <div className="modal__inner">
                  <h1>Create Company</h1>
                  <form method="post" onSubmit={this.handleSubmit} noValidate>
                    <div className="form__group">
                      <div className="row">
                        <label id="companyNameLabel">Name</label>
                      </div>
                      <input
                        type="text"
                        name="companyName"
                        ref="companyName"
                        className="form__control"
                        value={this.state.companyName}
                        onChange={this.handleInput}
                        placeholder="Enter name"
                        title="Company Name"
                        required
                      />
                      <div className="error" id="companyNameError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="emailLabel">Email</label>
                      </div>
                      <input
                        type="email"
                        name="email"
                        ref="email"
                        value={this.state.email}
                        onChange={this.handleInput}
                        placeholder="Enter email"
                        title="Email"
                        required
                      />
                      <div className="error" id="emailError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="countryLabel">Country</label>
                      </div>
                      <RegionAutoSuggest
                        didChangeValue={this.didUpdateRegionSelection}
                      />
                    </div>
                    {subregions.length > 0 && (
                      <div className="form__group">
                        <div className="row">
                          <label id="regionLabel">Region</label>
                        </div>
                        <SubregionAutoSuggest regions={subregions} />
                      </div>
                    )}
                    <FormError error={this.state.error} />
                    <div className="form__group">
                      <div className="row">
                        <button
                          type="submit"
                          className="btn btn--blue-solid"
                          value="Create"
                          disabled={this.state.networkActive}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal__mark">
                <img src={hand_shake} className="modal__img" alt="logo" />
                <div className="top__angle" />
                <div className="bottom__angle" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    regions: state.preferences.regions,
  }
}

export default withApi(withRouter(connect(mapStateToProps)(SubscriberCreate)))
