import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import queryString from 'query-string'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
// Components
import SearchDocument from './SearchDocument'
import SearchParties from './SearchParties'
import SearchTransaction from './SearchTransaction'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loading: true,
      page: 0,
      pages: 1,
      search: '',
      tabValue: 't',
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search)
    const search = values.q
    const tabValue = values.c

    if (tabValue !== 't' && tabValue !== 'd' && tabValue !== 'p') {
      this.props.history.push(appPaths.Search + '?q=' + search + '&c=t')
    } else {
      this.props.history.push(
        appPaths.Search + '?q=' + search + '&c=' + tabValue,
      )
    }

    this.setState({
      search: search,
      tabValue: tabValue,
    })
  }

  componentDidUpdate() {
    const values = queryString.parse(this.props.location.search)
    const tabValue = values.c

    if (this.state.tabValue !== tabValue) {
      this.setState({
        tabValue: tabValue,
      })
    }
  }

  handleTab = (e) => {
    const values = queryString.parse(this.props.location.search)
    const queryParameter = values.q

    this.props.history.push(
      appPaths.Search + '?q=' + queryParameter + '&c=' + e,
    )

    this.setState({
      tabValue: e,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="menu__body">
          <p>
            <b>Results for: </b>
            {queryString.parse(this.props.location.search).q}
          </p>
        </div>
        <div className="tile user--settings">
          <ul className="search__tabs">
            <li>
              <button
                className={this.state.tabValue !== 't' ? '' : ' active'}
                onClick={() => this.handleTab('t')}
              >
                Transactions
              </button>
            </li>
            <li>
              <button
                className={this.state.tabValue !== 'd' ? '' : ' active'}
                onClick={() => this.handleTab('d')}
              >
                Documents
              </button>
            </li>
            <li>
              <button
                className={this.state.tabValue !== 'p' ? '' : ' active'}
                onClick={() => this.handleTab('p')}
              >
                Parties
              </button>
            </li>
          </ul>
          <div className="dashbaord__tile full">
            <div className="inner__tile">
              {this.state.tabValue === 'd' && <SearchDocument />}

              {this.state.tabValue === 'p' && <SearchParties />}

              {this.state.tabValue === 't' && <SearchTransaction />}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withRouter(connect(mapStateToProps)(Search))
