import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
import {updateUserAccount} from '../../redux/actions/account'
// Utils
import {appPaths} from '../../utils/appPaths'
import {
  checkToken,
  checkUserAccount,
  setUserAccount,
} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Components
import AccountChangePassword from './AccountChangePassword'
// Images
import email from '../../img/email.svg'
import notification_settings from '../../img/notification_settings.svg'
import profile from '../../img/profile.png'
import settings_icon_white from '../../img/settings_icon_white.svg'
import _ from 'lodash'
import {networkErrorDetail, buildUrl} from '../../utils/urls'
import withApi from '../../api/withApi'
import Payment from '../subscribers/Payment'
import SubscriberProfile from '../subscribers/SubscriberProfile'

class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyName: '',
      email: '',
      firstName: '',
      lastName: '',
      totalCompanyTransactionCount: '',
      storageUsage: '',
      checkedNotification: [],
      checkedEmail: [],
      data: [
        {
          header: '',
        },
        {
          header: 'A document has been delivered',
        },
        {
          header: 'A document has been received',
        },
        {
          header: 'A transaction has been completed',
        },
      ],
      handleChangePassword: false,
      showPayment: false,
      loginToken: checkToken(this),
      selectAllEmail: false,
      selectAllNotification: false,
      userAccount: checkUserAccount(this),

      handleHints: false,
    }
  }

  componentWillMount() {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const accountId = this.state.userAccount.id

    this.props.api
      .accountRead(companyId, accountId)
      .then((response) => {
        let flatData = jsonApiSpecToFlatObject(
          response.data.data,
          response.data.included,
        )

        // Set user settings
        let checkAllNotification = ''

        if (
          flatData.notif_document_delivered === true &&
          flatData.notif_document_received === true &&
          flatData.notif_transaction_complete === true
        ) {
          checkAllNotification = true
          this.setState({
            selectAllNotification: true,
          })
        } else {
          checkAllNotification = false
        }

        let checkedNotification = [
          checkAllNotification,
          flatData.notif_document_delivered,
          flatData.notif_document_received,
          flatData.notif_transaction_complete,
        ]

        let checkAllEmail = ''

        if (
          flatData.email_document_delivered === true &&
          flatData.email_document_received === true &&
          flatData.email_transaction_complete === true
        ) {
          checkAllEmail = true
          this.setState({
            selectAllEmail: true,
          })
        } else {
          checkAllEmail = false
        }

        let checkedEmail = [
          checkAllEmail,
          flatData.email_document_delivered,
          flatData.email_document_received,
          flatData.email_transaction_complete,
        ]

        this.setState({
          checkedNotification: checkedNotification,
          checkedEmail: checkedEmail,
        })

        this.setState({
          companyName: flatData.company.name,
          email: flatData.email,
          firstName: flatData.first_name,
          lastName: flatData.last_name,
          totalCompanyTransactionCount:
            flatData.total_company_transaction_count,
          storageUsage: flatData.storage_usage,
          handleHints: flatData.show_hints,
        })
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  handleCloseChangePassword = (e) => {
    this.setState({
      handleChangePassword: false,
    })
  }

  handleChangePassword = (e) => {
    this.setState({
      handleChangePassword: true,
    })
  }

  handleChange = (type) => {
    let selectAll = false
    let checkedCopy = ''

    if (type === 'notification') {
      checkedCopy = this.state.checkedNotification
    } else {
      checkedCopy = this.state.checkedEmail
    }

    if (type === 'notification') {
      selectAll = !this.state.selectAllNotification
      this.setState({selectAllNotification: selectAll})
    } else {
      selectAll = !this.state.selectAllEmail
      this.setState({selectAllEmail: selectAll})
    }

    this.state.data.forEach(function (e, index) {
      checkedCopy[index] = selectAll
    })

    if (type === 'notification') {
      this.setState({
        checkedNotification: checkedCopy,
      })
    } else {
      this.setState({
        checkedEmail: checkedCopy,
      })
    }

    this.handleUpdateSettings()
  }

  handleSingleCheckboxChange = (type, row) => {
    let checkedCopy = ''

    if (type === 'notification') {
      checkedCopy = this.state.checkedNotification
    } else {
      checkedCopy = this.state.checkedEmail
    }

    if (checkedCopy[row.index] === false) {
      this.setState({selectAll: false})
    }

    if (row.index === 0) {
      this.handleChange(type)
    } else {
      checkedCopy[row.index] = !checkedCopy[row.index]

      if (checkedCopy[row.index] === false) {
        checkedCopy[0] = false
      }

      if (type === 'notification') {
        this.setState({
          checkedNotification: checkedCopy,
        })
      } else {
        this.setState({
          checkedEmail: checkedCopy,
        })
      }

      this.handleUpdateSettings()
    }
  }

  handleShowPayment = (e) => {
    this.setState({
      showPayment: true,
    })
  }

  handleClosePayment = (e) => {
    this.setState({
      showPayment: false,
    })
  }

  handleHints = () => {
    var nextHintState = !this.state.handleHints

    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const accountId = this.state.userAccount.id

    const checkedEmail = this.state.checkedEmail
    const checkedNotification = this.state.checkedNotification

    this.props.api
      .accountUpdate(companyId, accountId, {
        email_document_delivered: checkedEmail[1],
        email_document_received: checkedEmail[2],
        email_transaction_complete: checkedEmail[3],

        notif_document_delivered: checkedNotification[1],
        notif_document_received: checkedNotification[2],
        notif_transaction_complete: checkedNotification[3],

        show_hints: nextHintState,
      })
      .then((response) => {
        var userAccount = _.assign({}, this.props.account.userAccount)
        userAccount.show_hints = nextHintState

        // Set userAccount local storage
        setUserAccount(userAccount)
        this.props.updateUserAccount(userAccount)

        if (nextHintState === true) {
          localStorage.removeItem('helpHintDocument')
          localStorage.removeItem('helpHintPerson')
          localStorage.removeItem('helpHintTransaction')
        }

        this.setState({
          userAccount: userAccount,
          handleHints: nextHintState,
        })
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  handleUpdateSettings = (e) => {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const accountId = this.state.userAccount.id

    const checkedEmail = this.state.checkedEmail
    const checkedNotification = this.state.checkedNotification

    this.props.api
      .accountUpdate(companyId, accountId, {
        email_document_delivered: checkedEmail[1],
        email_document_received: checkedEmail[2],
        email_transaction_complete: checkedEmail[3],

        notif_document_delivered: checkedNotification[1],
        notif_document_received: checkedNotification[2],
        notif_transaction_complete: checkedNotification[3],

        show_hints: this.state.handleHints,
      })
      .then((response) => {
        // console.log('response is : ' + response.data);
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="menu__body">
          <img src={settings_icon_white} alt="Alt text" />
          <p>Settings</p>
        </div>
        <div className="tile user--settings">
          <div className="large full-width flexbox">
            <SubscriberProfile />
            <div className="dashbaord__tile large full-width col-40">
              <div className="inner__tile">
                <h2>Login Information</h2>
                <div className="inner">
                  <table className="">
                    <tbody>
                      <tr>
                        <td>
                          <img src={profile} className="profile" alt="logo" />
                        </td>
                        <td>
                          <div className="form__group">
                            <div className="row">
                              <label>Full Name</label>
                            </div>
                            <p>
                              {this.state.firstName + ' ' + this.state.lastName}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="form__group">
                            <div className="row">
                              <label>Email</label>
                            </div>
                            <p>{this.state.email}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <div className="form__group">
                            <div className="row">
                              <label>Password</label>
                            </div>
                            <span className="circle__group two">
                              <span className="circle circle--blue" />
                              <span className="circle circle--blue" />
                              <span className="circle circle--blue" />
                              <span className="circle circle--blue" />
                              <span className="circle circle--blue" />
                              <span className="circle circle--blue" />
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="form__group">
                            <button
                              type="button"
                              className="change__password"
                              onClick={this.handleChangePassword}
                            >
                              Change Password
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="dashbaord__tile large full-width col-60 no-padding">
              <div className="inner__tile">
                <h2>Payment and Usage</h2>
                <div className="inner">
                  <table className="">
                    <tbody>
                      <tr>
                        <td>
                          <div className="form__group">
                            <div className="row">
                              <label>Total Transactions</label>
                            </div>
                            <p>{this.state.totalCompanyTransactionCount}</p>
                          </div>
                        </td>
                        <td>
                          <div className="form__group">
                            <div className="row">
                              <label>Company Account</label>
                            </div>
                            <p>{this.state.companyName}</p>
                          </div>
                        </td>
                        <td>
                          {this.state.userAccount.role.id <= 2 && (
                            <button
                              type="button"
                              className="change__password"
                              onClick={this.handleShowPayment}
                            >
                              Update Credit Card
                            </button>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form__group">
                            <div className="row">
                              <label>Storage Usage</label>
                            </div>
                            <p>{this.state.storageUsage}</p>
                          </div>
                        </td>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="dashbaord__tile large full-width col-40">
            <div className="inner__tile">
              <div className="col-100 signing__settings">
                <h2>User Settings</h2>
                <div className="row form__group">
                  <div className="row">
                    <p>
                      Show helpful hints <br />
                    </p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={this.handleHints}
                        checked={this.state.handleHints}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.handleChangePassword && (
          <AccountChangePassword
            handleCloseChangePassword={this.handleCloseChangePassword}
          />
        )}

        {this.state.showPayment && (
          <Payment handleClosePayment={this.handleClosePayment} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserAccount: (payload) => dispatch(updateUserAccount(payload)),
})

export default withApi(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSettings)),
)
