import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import NProgress from 'nprogress'
import queryString from 'query-string'
// Redux
import { connect } from 'react-redux'
import {
  updateLoginToken,
  updateUserAccount,
  clearReduxCache,
} from '../redux/actions/account'
// Utils
import { appPaths } from '../utils/appPaths'
import {
  getLoginToken,
  checkUserAccount,
  deleteCookie,
} from '../utils/loginToken'
// Components
import Main from './Main'
import TermsAndConditions from './account/TermsAndConditions'
// Images
import dashboard_icon_small from '../img/dashboard_icon_small.svg'
import logo_icon from '../img/logo_icon.svg'
import notification_icon from '../img/notification_icon.svg'
import parties_icon_small from '../img/parties_icon_small.svg'
import search_icon from '../img/search_icon.svg'
import settings_icon from '../img/settings_icon.svg'
import transactions_icon_small from '../img/transactions_icon_small.svg'
import user_accounts_icon from '../img/user_accounts_icon.svg'
import welcome_modal from '../img/welcome_modal.svg'
import { networkErrorDetail } from '../utils/urls'
import withApi from '../api/withApi'
import _ from 'lodash'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closeWelcome: this.props.closeWelcome,
      error: '',
      loginToken: getLoginToken(this, 'loginToken'),
      navActive: 0,
      renderData: false,
      search: '',
      userAccount: checkUserAccount(this),
      showToC: false,
    }
  }

  componentWillMount() {
    // On load, get and set redux from localstorage and cookies
    var loginToken = this.state.loginToken
    var userAccount = this.state.userAccount

    if (loginToken && userAccount) {
      this.props.updateLoginToken(loginToken)
      this.props.updateUserAccount(userAccount)

      this.setState({
        renderData: true,
      })
    } else {
      this.props.history.push(appPaths.Login)
    }

    try {
      if (!_.isNil(userAccount) && userAccount.did_accept_tos) {
        this.setState({
          renderData: true,
        })
      }
    } catch (err) {
      this.props.history.push(appPaths.Login)
    }
  }

  handleDeclineTermsAndConditions = () => {
    this.props.history.push(appPaths.Login)
  }

  handleCloseWelcome = (e) => {
    // Close welcome modal on click
    this.setState({ closeWelcome: false })

    this.props.api
      .welcomeShown()
      .then((response) => { })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  handleMainDashboardComponent = (index) => {
    // Component route for main dashboard
    this.setState({
      navActive: index,
    })
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const values = queryString.parse(this.props.location.search)
    const tabValue = values.c

    if (tabValue !== 't' && tabValue !== 'd' && tabValue !== 'p') {
      this.props.history.push(
        appPaths.Search + '?q=' + this.state.search + '&c=t',
      )
    } else {
      this.props.history.push(
        appPaths.Search + '?q=' + this.state.search + '&c=' + tabValue,
      )
    }
  }

  handleLogOut = (e) => {
    deleteCookie('loginToken')
    this.props.clearReduxCache()
    this.props.history.push(appPaths.Login)
  }

  handleToC = () => {
    this.handleShowTocState(true)
  }

  handleShowTocState = (nextshowToCState) => {
    if (nextshowToCState !== this.state.showToC) {
      this.setState({
        showToC: nextshowToCState,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.renderData &&
          !_.isNil(this.props.account) &&
          !_.isNil(this.props.account.userAccount) &&
          this.props.account.userAccount.did_accept_tos && (
            <div className="dashboard">
              <div className="dashbaord__menu">
                <div className="dashbaord__logo">
                  <Link
                    to="/transactions"
                    onClick={() => this.handleMainDashboardComponent(0)}
                  >
                    <img src={logo_icon} alt="logo" />
                  </Link>
                </div>

                <div className="dashbaord__menu-list">
                  <ul>
                    {this.state.userAccount.role.id !== 1 && (
                      <div>
                        {/*<li>
                        <Link
                          to="/dashboard"
                          className={this.state.navActive !== 0 ? '' : 'active'}
                          onClick={() => this.handleMainDashboardComponent(0)}
                        >
                          <img src={dashboard_icon_small} alt="Alt text" />{' '}
                          Dashboard
                        </Link>
                      </li>*/}
                        <li>
                          <Link
                            to={appPaths.Transaction}
                            className={
                              this.state.navActive !== 2 ? '' : 'active'
                            }
                            onClick={() => this.handleMainDashboardComponent(2)}
                          >
                            <img src={transactions_icon_small} alt="Alt text" />{' '}
                            Transactions
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/parties"
                            className={
                              this.state.navActive !== 4 ? '' : 'active'
                            }
                            onClick={() => this.handleMainDashboardComponent(4)}
                            title="Parties, Lawyers, Others"
                          >
                            <img src={parties_icon_small} alt="Alt text" />{' '}
                            Parties
                          </Link>
                        </li>
                        <li>
                          <div>
                            <span className="border" />
                          </div>
                        </li>
                      </div>
                    )}

                    {this.state.userAccount.role.id <= 2 && (
                      <li>
                        <Link
                          to="/account"
                          className={this.state.navActive !== 5 ? '' : 'active'}
                          onClick={() => this.handleMainDashboardComponent(5)}
                        >
                          <img src={user_accounts_icon} alt="Alt text" /> User
                          Accounts
                        </Link>
                      </li>
                    )}
                    {this.state.userAccount.role.id === 1 && (
                      <li>
                        <Link
                          to="/subscribers"
                          className={this.state.navActive !== 6 ? '' : 'active'}
                          onClick={() => this.handleMainDashboardComponent(6)}
                        >
                          <img src={parties_icon_small} alt="Alt text" />{' '}
                          Subscribers
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/files/ModernXMark®UserGuide-1.4.1.pdf"
                        target="_blank"
                        download
                      >
                        <img src={user_accounts_icon} alt="Alt text" />
                        User Guide
                      </Link>
                    </li>

                    {this.state.userAccount.role.id === 1 && (
                      <li>
                        <Link
                          to="/files/ModernXMark®SuperAdminUserGuide.pdf"
                          target="_blank"
                          download
                        >
                          <img src={user_accounts_icon} alt="Alt text" />
                          Super User Guide
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="logout">
                  <button className="link" onClick={this.handleToC}>
                    Terms and Conditions
                  </button>
                  <a
                    href="https://modernxmark.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About
                  </a>
                  <button className="link" onClick={this.handleLogOut}>
                    <i className="fas fa-sign-out-alt" /> Logout
                  </button>
                </div>
              </div>
              <div className="dashbaord__main">
                <div className="dashbaord__tools">
                  {this.state.userAccount.role.id !== 1 && (
                    <div className="search left">
                      <form
                        className="flexbox"
                        method="post"
                        onSubmit={this.handleSubmit}
                        noValidate
                      >
                        <img src={search_icon} alt="Alt text" />
                        <input
                          type="text"
                          name="search"
                          value={this.state.search}
                          onChange={this.handleInput}
                          placeholder="Search transaction, document (name or number), or party (name)"
                        />
                        <button
                          type="submit"
                          className=" btn btn--mod-2 btn--blue"
                        >
                          Search
                        </button>
                      </form>
                    </div>
                  )}
                  <div className="profile right">
                    <span className="tools">
                      {/*<Link
                      to="/dashboard"
                      onClick={() => this.handleMainDashboardComponent(0)}
                      title="Dashboard"
                    >
                      <img src={notification_icon} alt="Alt text" />
                    </Link>*/}
                      <Link to="/account/settings" title="Account Settings">
                        <img src={settings_icon} alt="Alt text" />
                      </Link>
                    </span>
                    <div className="profile__info">
                      <span className="profile__name">
                        {this.state.userAccount.first_name +
                          ' ' +
                          this.state.userAccount.last_name}
                      </span>
                    </div>
                  </div>
                </div>
                <Main />
              </div>
            </div>
          )}
        {this.state.closeWelcome && (
          <div className="welcome__modal" onClick={this.handleCloseWelcome}>
            <div>
              <div className="inner">
                <div>
                  <button
                    type="button"
                    className="link close"
                    onClick={this.handleCloseWelcome}
                  >
                    <i className="fas fa-times" />
                  </button>
                  <h1>
                    Welcome to ModernXMark<sup>&reg;</sup>
                  </h1>
                  <p>
                    This is your main dashboard, where critical actions for
                    transactions and documents will be displayed. ModernMark is
                    organized by associating your documents together in a
                    transaction.
                  </p>
                  <img src={welcome_modal} className="App-logo" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        )}

        {!_.isNil(this.props.account) &&
          !_.isNil(this.props.account.userAccount) &&
          !this.props.account.userAccount.did_accept_tos && (
            <TermsAndConditions
              showButtons={true}
              onCloseClick={this.handleDeclineTermsAndConditions}
              handleDeclineTermsAndConditions={
                this.handleDeclineTermsAndConditions
              }
            />
          )}

        {this.state.showToC && (
          <TermsAndConditions
            showButtons={false}
            onCloseClick={() => this.handleShowTocState(false)}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
  clearReduxCache: () => dispatch(clearReduxCache()),
  updateLoginToken: (payload) => dispatch(updateLoginToken(payload)),
  updateUserAccount: (payload) => dispatch(updateUserAccount(payload)),
})

export default withApi(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)),
)
