import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Moment from 'react-moment'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import large_add from '../../img/large_add.svg'
import transactions_icon from '../../img/transactions_icon.svg'
import withApi from '../../api/withApi'
import _ from 'lodash'
import {networkErrorDetail} from '../../utils/urls'

class TransactionPartyReadAllTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: this.props.match.params.ac,
      error: '',
      handleCreate: false,
      helpfulHint: false,
      loading: true,
      partyAccountId: this.props.partyId,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    if (
      !localStorage.helpHintTransaction &&
      this.state.userAccount.show_hints
    ) {
      this.setState({
        helpfulHint: true,
      })
    }

    this.getTableData()
  }

  getTableData() {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    this.setState({
      loading: true,
    })

    return this.props.api
      .transactionReadAll(companyId, null, null)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const columns = [
      {
        id: 'id',
        Header: 'Transaction ID',
        accessor: (d) => d.guid,
      },
      {
        id: 'name',
        Header: 'Transaction Name',
        accessor: (d) => d.name,
      },
      {
        id: 'createdAt',
        Header: 'Creation Date',
        accessor: (d) => {
          if (d.created_at === null || d.created_at === '') {
            return 'N/A'
          } else {
            return <Moment format="MMM D, YYYY">{d.created_at.date}</Moment>
          }
        },
      },
      {
        id: 'closingDate',
        Header: 'Transaction Date',
        accessor: (d) => {
          if (
            d.closing_date === null ||
            d.closing_date === undefined ||
            d.closing_date === ''
          ) {
            return 'N/A'
          } else {
            return <Moment format="MMM D, YYYY">{d.closing_date.date}</Moment>
          }
        },
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(appPaths.TransactionRead(rowInfo.original.id))
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={transactions_icon} alt="Transactions Icon" />
            <p>
              {this.state.error ? this.state.error : ' No Transactions Found'}
            </p>
          </div>
        </div>
      )
    }

    console.log('this.props.partyTransactions', this.props.partyTransactions)
    console.log('this.props.partyId', this.props.partyId)

    const tableData = this.props.partyTransactions

    return (
      <React.Fragment>
        {this.state.userAccount.role.id === 2 && !this.state.accountId && (
          <button
            type="button"
            className="link right"
            onClick={this.handleCreate}
          >
            <img src={large_add} alt="Large Add" />
          </button>
        )}

        <div>
          <ReactTableCommon
            tableData={tableData}
            isLoading={this.state.loading}
            columns={columns}
            onRowClick={onRowClick}
            tableNoResults={tableNoResults}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let transactions = _.map(
    state.transactions.data,
    (transaction, key) => transaction,
  )

  transactions = _.filter(transactions, (transaction) => {
    if (_.isNil(transaction.parties)) {
      return false
    }

    // Tried doing this multiple ways (e.g. using _.first(), _.includes(partyIds, ownProps.partyId))
    // This method ended up working so using it as is.
    const parties = _.isArray(transaction.parties)
      ? transaction.parties
      : [transaction.parties]

    if (_.isEmpty(parties)) {
      return false
    }

    let containsParty = false
    for (let index = 0; index < parties.length; index += 1) {
      const party = parties[index]
      if (`${party.id}` === `${ownProps.partyId}`) {
        containsParty = true
        break
      }
    }

    return containsParty
  })

  return {
    account: state.account,
    partyTransactions: transactions,
  }
}

export default withApi(
  withRouter(connect(mapStateToProps)(TransactionPartyReadAllTable)),
)
