import produce from 'immer'
import {SHOW_MODAL} from '../constants'

export default (
  state = {
    activeModal: null,
  },
  action,
) => {
  switch (action.type) {
    case SHOW_MODAL:
      return produce(state, (draftState) => {
        draftState.activeModal = action.payload
      })
    default:
      return state
  }
}
