import React from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
// Utils
import {appPaths} from '../../utils/appPaths'
// Components
import AccountManage from './AccountManage'
import AccountRead from './AccountRead'
import AccountsReadAll from './AccountsReadAll'
import AccountSettings from './AccountSettings'

const AccountSwitch = () => (
  <Switch>
    <Route path={appPaths.AccountManage} component={AccountManage} />
    <Route path={appPaths.AccountSettings} component={AccountSettings} />
    <Route path={appPaths.AccountRead(':cmp', ':ac')} component={AccountRead} />
    <Route path={appPaths.Account} component={AccountsReadAll} />
  </Switch>
)

export default withRouter(AccountSwitch)
