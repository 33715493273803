import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {CardElement, injectStripe} from 'react-stripe-elements'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
// Components
import FormError from '../common/FormError'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import TextInput from '../common/TextInput'
import {validateInput} from '../../utils/validate'
import _ from 'lodash'

class CheckoutForm extends Component {
  constructor(props) {
    super(props)
    const userAccount = checkUserAccount(this)

    this.state = {
      error: '',
      paymentAdded: false,
      invoiceEmail: userAccount.company.invoice_email,
      userAccount: userAccount,
    }

    this.submit = this.submit.bind(this)
  }

  updateValueInState = (e, id, value) => {
    this.setState({
      [id]: value,
    })

    // Validate email on input
    if (id === 'invoiceEmail') {
      validateInput(this, e)
    }
  }

  async submit(ev) {
    let {token} = await this.props.stripe.createToken({
      name: this.state.userAccount.company.name,
    })

    if (token) {
      NProgress.start()

      const companyId = this.state.userAccount.company.id

      this.props.api
        .companyUpdate(companyId, {
          name: this.state.userAccount.company.name,
          email: this.state.userAccount.company.email,
          invoice_email: this.state.invoiceEmail,
          region_code: this.state.userAccount.company.region_code,
          stripe_token: token.id,
        })
        .then(() => {
          this.setState({
            paymentAdded: true,
          })

          if (this.props.handleClosePayment) {
            this.props.handleClosePayment()
          } else {
            this.props.history.push(appPaths.Transaction)
          }
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
        })
    } else {
      this.setState({
        error: 'Form error',
      })
    }
  }

  render() {
    return (
      <div className="checkout">
        <CardElement hidePostalCode={true} />
        <br />

        {!this.props.isUpdate && (
          <React.Fragment>
            <p>
              This email will receive Invoices for any fees incured during the
              use of ModernXMark®
            </p>
            <TextInput
              id="invoiceEmail"
              title="Email Invoices to:"
              value={this.state.invoiceEmail}
              placeholder="Enter name"
              isRequired={true}
              isEditing={true}
              onChange={(e, id, value) => this.updateValueInState(e, id, value)}
            />
          </React.Fragment>
        )}
        <FormError error={this.state.error} />
        <button className="btn btn--mod-3" onClick={this.submit}>
          {this.props.isUpdate ? 'Update' : 'Submit'}
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(
  withRouter(connect(mapStateToProps)(injectStripe(CheckoutForm))),
)
