import produce from 'immer'
import {UPDATE_ACCOUNTS, UPDATE_ACCOUNT, DELETE_ACCOUNT} from '../constants'
import _ from 'lodash'

export default (
  state = {
    data: {},
  },
  action,
) => {
  return produce(state, (draft) => {
    const {payload} = action

    switch (action.type) {
      case UPDATE_ACCOUNTS: {
        // Empty the data to the newly downloaded
        draft.data = {}

        _.forEach(payload, (account) => {
          draft.data[account.id] = account
        })
        return draft
      }
      case UPDATE_ACCOUNT: {
        draft.data[payload.id] = payload
        return draft
      }
      case DELETE_ACCOUNT: {
        delete draft.data[payload.id]
        return draft
      }
      default:
        return draft
    }
  })
}
