import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken} from '../../utils/loginToken'
import {showFormErrors, validateInput} from '../../utils/validate'
// Components
import FormError from '../common/FormError'
import SubscribersDelete from './SubscribersDelete'
// Images
import large_edit from '../../img/large_edit.svg'
import large_delete from '../../img/large_delete.svg'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import TextInput from '../common/TextInput'
import _ from 'lodash'
import RegionAutoSuggest from '../common/RegionAutoSelect'
import SubregionAutoSuggest from '../common/SubregionAutoSelect'

class SubscriberRead extends Component {
  constructor(props) {
    super(props)

    let companyId = this.props.match.params.ac
    if (_.isNil(companyId)) {
      companyId = this.props.account.userAccount.company.id
    }

    this.state = {
      companyAccountId: companyId,
      editSubscriber: false,
      subscriberName: '',
      email: '',
      invoiceEmail: '',
      totalCompanyTransactionCount: 0,
      error: '',
      handleDelete: false,
      loginToken: checkToken(this),
      networkActive: false,
    }
  }

  componentWillMount() {
    NProgress.start()

    this.setState({loading: true})

    this.props.api.regions().catch((error) => {
      this.setState({
        error: networkErrorDetail(error),
      })
    })

    const companyAccountId = this.state.companyAccountId
    this.props.api
      .companyRead(companyAccountId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        this.setState({loading: false})
        NProgress.done()
      })
  }

  componentWillReceiveProps(nextProps) {
    this.parseSubscriberToState(nextProps.company)
  }

  componentDidMount() {
    this.parseSubscriberToState(this.props.company)
  }

  parseSubscriberToState = (flatData) => {
    if (_.isNil(flatData)) {
      this.setState({
        subscriberName: '',
        email: '',
        regionCode: '',
        totalCompanyTransactionCount: 0,
      })
      return
    }

    this.setState({
      subscriberName: flatData.name,
      email: flatData.email,
      invoiceEmail: flatData.invoice_email,
      regionCode: flatData.region_code,
      totalCompanyTransactionCount: flatData.total_company_transaction_count,
    })
  }

  handleCloseDelete = (e) => {
    this.setState({
      handleDelete: false,
    })
  }

  handleDelete = (e) => {
    this.setState({
      handleDelete: true,
    })
  }

  handleEdit = (e) => {
    this.setState({
      editSubscriber: true,
    })
  }

  updateValueInState = (e, id, value) => {
    this.setState({
      [id]: value,
    })

    // Validate email on input
    if (id === 'email' || id === 'invoiceEmail') {
      validateInput(this, e)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    var subregionName = null
    if (!_.isNil(e.target.subregionCode)) {
      subregionName = e.target.subregionCode.attributes.value.nodeValue
    }

    const regionName = e.target.regionCode.attributes.value.nodeValue
    const regionMatched = _.first(
      _.filter(this.props.regions, (region) => {
        return region.name === regionName
      }),
    )

    if (_.isNil(regionMatched)) {
      this.setState({
        error: 'You must select a valid country.',
      })
      return
    }

    var regionCode = ''
    if (_.has(regionMatched, 'subregions')) {
      if (_.isNil(subregionName) || subregionName === '') {
        this.setState({
          error: 'You must select a valid region.',
        })
        return
      }

      regionCode = _.first(
        _.filter(regionMatched.subregions, (subregion) => {
          return subregion.name === subregionName
        }),
      ).code
    } else {
      regionCode = regionMatched.code
    }

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.setState({error: '', networkActive: true})

      const companyAccountId = this.state.companyAccountId

      this.props.api
        .companyUpdate(companyAccountId, {
          name: e.target.subscriberName.value,
          email: e.target.email.value,
          invoice_email: e.target.invoiceEmail.value,
          region_code: regionCode,
        })
        .then(() => {
          this.setState({
            editSubscriber: false,
          })
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
          this.setState({networkActive: false})
        })
    }
  }

  didUpdateRegionSelection = (newRegionName) => {
    const regionMatched = _.first(
      _.filter(this.props.regions, (region) => {
        return region.name === newRegionName
      }),
    )

    if (!_.isNil(regionMatched)) {
      this.setState({regionCode: regionMatched.code})
    } else {
      this.setState({regionCode: ''})
    }
  }

  render() {
    var regionName = 'N/A'
    var subregionName = 'N/A'
    var subregions = []

    if (
      !_.isNil(this.state.regionCode) &&
      this.state.regionCode.trim() !== ''
    ) {
      const regionCode = this.state.regionCode.split('-')[0]
      const regionMatch = _.first(
        _.filter(this.props.regions, (region) => {
          return region.code === regionCode
        }),
      )

      if (!_.isNil(regionMatch)) {
        regionName = regionMatch.name
        subregions = regionMatch.subregions || []

        if (this.state.regionCode.includes('-')) {
          const subregionMatch = _.first(
            _.filter(subregions, (subregion) => {
              return subregion.code === this.state.regionCode
            }),
          )

          if (!_.isNil(subregionMatch)) {
            subregionName = subregionMatch.name
          }
        }
      }
    }

    return (
      <React.Fragment>
        <div className="dashbaord__tile large full-width">
          <div className="inner__tile">
            <form method="post" onSubmit={this.handleSubmit} noValidate>
              <h2>Subscriber Profile</h2>
              {this.props.account.userAccount.role.id === 2 &&
                (this.state.editSubscriber ? (
                  <div>
                    <button
                      type="submit"
                      className="col-40 btn btn--mod-2 btn--green no-margin"
                      value="Save"
                      disabled={this.state.networkActive}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="link right"
                      onClick={this.handleDelete}
                      disabled={this.state.networkActive}
                    >
                      <img src={large_delete} alt="Large trash" />
                    </button>
                    <button
                      type="button"
                      className="link right"
                      onClick={this.handleEdit}
                      disabled={this.state.networkActive}
                    >
                      <img src={large_edit} alt="Large edit" />
                    </button>
                  </div>
                ))}
              <FormError error={this.state.error} />
              <div className="row">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form__group">
                          <TextInput
                            id="subscriberName"
                            title="Subscriber Name"
                            value={this.state.subscriberName}
                            placeholder="Enter name"
                            isRequired={true}
                            isEditing={this.state.editSubscriber}
                            onChange={(e, id, value) =>
                              this.updateValueInState(e, id, value)
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form__group">
                          <TextInput
                            id="email"
                            title="Email"
                            value={this.state.email}
                            placeholder="Enter email"
                            isRequired={true}
                            isEditing={this.state.editSubscriber}
                            onChange={(e, id, value) =>
                              this.updateValueInState(e, id, value)
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form__group">
                          <TextInput
                            id="invoiceEmail"
                            title="Email Invoices to:"
                            value={this.state.invoiceEmail}
                            placeholder="Enter email to receive incoices"
                            isRequired={true}
                            isEditing={this.state.editSubscriber}
                            onChange={(e, id, value) =>
                              this.updateValueInState(e, id, value)
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form__group">
                          <div className="row">
                            <label id="regionLabel">Country</label>
                          </div>
                          {this.state.editSubscriber ? (
                            <RegionAutoSuggest
                              initialValue={regionName}
                              didChangeValue={this.didUpdateRegionSelection}
                            />
                          ) : (
                            <p>{regionName}</p>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="form__group">
                          <div className="row">
                            <label id="subregionLabel">Region</label>
                          </div>
                          {this.state.editSubscriber &&
                          subregions.length > 0 ? (
                            <SubregionAutoSuggest
                              initialValue={
                                subregionName === 'N/A' ? '' : subregionName
                              }
                              regions={subregions}
                            />
                          ) : (
                            <p>{subregionName}</p>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="form__group">
                          <TextInput
                            id="totalCompanyTransactionCount"
                            title="Transactions"
                            value={'' + this.state.totalCompanyTransactionCount}
                            placeholder=""
                            isRequired={false}
                            isEditing={false}
                            onChange={(e, id, value) =>
                              this.updateValueInState(e, id, value)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>

        {this.state.handleDelete && (
          <SubscribersDelete handleCloseDelete={this.handleCloseDelete} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let companyId = ownProps.match.params.ac
  if (_.isNil(companyId)) {
    companyId = state.account.userAccount.company.id
  }

  return {
    account: state.account,
    company: state.companies.data[companyId],
    regions: state.preferences.regions,
  }
}

export default withApi(withRouter(connect(mapStateToProps)(SubscriberRead)))
