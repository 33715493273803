import NProgress from 'nprogress'
// Utils
import {networkErrorDetail, buildUrl} from '../../utils/urls'
import _ from 'lodash'

const DocumentDownload = (that) => {
  NProgress.start()

  var companyId = that.state.userAccount.company.id
  var transactionId = that.state.transactionId
  var documentId = that.state.documentId

  let url = buildUrl(
    `/company/${companyId}/transaction/${transactionId}/document/${documentId}/download`,
  )

  that.props.api
    .get(url, {
      responseType: 'blob',
    })
    .then((response) => {
      that.setState({
        document: response.data,
        networkActive: false,
      })

      // Note: Ie and Edge don't support the new File constructor,
      // so it's better to construct blobs and use saveAs(blob, filename)
      var FileSaver = require('file-saver')
      var filename = that.state.documentName

      // From Here: https://stackoverflow.com/a/680982
      var regexFileExtension = /(?:\.([^.]+))?$/
      const fileExtension = regexFileExtension.exec(filename)[1]
      if (!_.isNil(fileExtension) && fileExtension !== 'pdf') {
        filename = `${filename}.pdf`
      }

      var file = new File([that.state.document], filename, {
        type: 'application/pdf;charset=utf-8"',
      })
      FileSaver.saveAs(file)
    })
    .catch((error) => {
      that.setState({
        error: networkErrorDetail(error),
      })
    })
    .finally(() => {
      NProgress.done()
    })
}

export default DocumentDownload
