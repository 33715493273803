import React, {Component} from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
// Utils
import {appPaths} from '../utils/appPaths'
// Components
import Account from './account/AccountSwitch'
import Parties from './parties/PartiesSwitch'
import Dashboard from './dashboard/Dashboard'
import Search from './search/Search'
import Subscribers from './subscribers/SubscribersSwitch'
import Transaction from './transactions/TransactionSwitch'
import {connect} from 'react-redux'
import {MODAL_LOCKED_TRANSACTION} from '../redux/constants'
import Modal from './common/Modal'
import {removeModal} from '../redux/actions/modals'
import withApi from '../api/withApi'
import _ from 'lodash'
import {checkUserAccount} from '../utils/loginToken'
import {updateLockedTransactionId} from '../redux/actions/preferences'
import {MILLISECONDS_PER_SECOND, SECONDS_PER_MINUTE} from '../constants'
import {clearReduxCacheExcludingUser} from '../redux/actions/account'

class Main extends Component {
  constructor(props) {
    super(props)

    this.props.api.pricing()

    // Clear storage when the browser is closed as we don't want
    // the app information available after it's closed
    window.onbeforeunload = function () {
      props.clearReduxCacheExcludingUser()
    }

    if (this.shouldSendTransactionLock()) {
      this.sendLockTransaction()
    }

    this.lockRetentionTimer = setInterval(() => {
      const pathname = this.props.history.location.pathname
      // User is logged in
      // and they have a transaction lock
      // and they are on a transaction url
      // and the transaction url has a number in it
      if (
        !_.isNil(checkUserAccount(this)) &&
        !_.isNil(this.props.lockedTransactionId) &&
        pathname.startsWith(appPaths.Transaction) &&
        _.split(pathname, '/').length > 2
      ) {
        this.sendLockTransaction()
      }
    }, 2 * SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND)

    this.historyStopListening = this.props.history.listen(
      (location, action) => {
        const pathname = this.props.history.location.pathname
        // User is logged in and they have a transaction lock. Release it.
        if (
          !_.isNil(checkUserAccount(this)) &&
          !_.isNil(this.props.lockedTransactionId) &&
          parseInt(this.props.lockedTransactionId) !==
            parseInt(_.split(pathname, '/')[2])
        ) {
          this.sendUnlockTransaction(() => {
            if (this.shouldSendTransactionLock()) {
              this.sendLockTransaction()
            }
          })
        } else if (this.shouldSendTransactionLock()) {
          this.sendLockTransaction()
        }
      },
    )
  }

  componentWillUnmount() {
    this.historyStopListening()
    clearInterval(this.lockRetentionTimer)
  }

  shouldSendTransactionLock = () => {
    if (
      _.isNil(this.props.history) ||
      _.isNil(this.props.history.location) ||
      _.isNil(this.props.history.location.pathname) ||
      _.isNil(checkUserAccount(this))
    ) {
      return false
    }

    const pathname = this.props.history.location.pathname
    return (
      pathname.startsWith(appPaths.Transaction) &&
      _.split(pathname, '/').length > 2 &&
      parseInt(this.props.lockedTransactionId) !==
        parseInt(_.split(pathname, '/')[2])
    )
  }

  sendLockTransaction = () => {
    const pathname = this.props.history.location.pathname
    const transactionId = pathname.split('/')[2]
    const userAccount = checkUserAccount(this)
    const companyId = userAccount.company.id

    this.props.api
      .lockTransaction(companyId, transactionId)
      .then((response) => {
        if (
          !_.isNil(response) &&
          !_.isNil(response.data) &&
          !_.isNil(response.data.data) &&
          !_.isNil(response.data.data.id)
        ) {
          const lockedTransactionId = response.data.data.id
          this.props.updateLockedTransactionId(lockedTransactionId)
        } else {
          this.props.updateLockedTransactionId(null)
          // Not sure why this is coming back success when someone else has the lock
          // but it has an empty body
          console.log('No Transaction came back, failed to gain a lock.')
        }
      })
      .catch((error) => {
        console.log('Transaction locked failed', error)
      })
  }

  sendUnlockTransaction = (onSuccess = null) => {
    const transactionId = this.props.lockedTransactionId
    const userAccount = checkUserAccount(this)
    const companyId = userAccount.company.id

    this.props.api
      .unlockTransaction(companyId, transactionId)
      .then((response) => {
        this.props.updateLockedTransactionId(null)
        if (!_.isNil(onSuccess)) {
          onSuccess(response)
        }
      })
      .catch((error) => {
        console.log('Transaction unlocked failed', error)
      })
  }

  onModalCancel = () => {
    this.props.removeModal()
    this.props.history.replace(appPaths.Transaction)
  }

  render() {
    const isTransactionLocked =
      !_.isNil(this.props.activeModal) &&
      this.props.activeModal.name === MODAL_LOCKED_TRANSACTION

    return (
      <div>
        {isTransactionLocked && (
          <Modal
            title={'Transaction locked'}
            message={`${this.props.activeModal.lockOwnerName} is currently viewing this transaction. You will be able to view it once they have finished.`}
            onCancel={this.onModalCancel}
            cancel={'OK'}
          />
        )}

        <Switch>
          <Route path={appPaths.Account} component={Account} />
          <Route path={appPaths.Parties} component={Parties} />
          <Route path={appPaths.Search} component={Search} />
          <Route path={appPaths.Subscribers} component={Subscribers} />
          <Route path={appPaths.Transaction} component={Transaction} />
          <Route component={Dashboard} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  activeModal: state.modals.activeModal,
  lockedTransactionId: state.preferences.lockedTransactionId,
})

const mapDispatchToProps = (dispatch) => ({
  clearReduxCacheExcludingUser: () => dispatch(clearReduxCacheExcludingUser()),
  removeModal: () => dispatch(removeModal()),
  updateLockedTransactionId: (payload) =>
    dispatch(updateLockedTransactionId(payload)),
})

export default withApi(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Main)),
)
