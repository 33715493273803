import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
import PartiesAutoSuggest from '../parties/PartiesAutoSuggest'
// Images
import parties_icon from '../../img/parties_icon.svg'
import doc_party_del from '../../img/doc_party_del.svg'
import document_close from '../../img/document_close.svg'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'
import Moment from 'react-moment'
import {appPaths} from '../../utils/appPaths'

class TransactionParties extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loading: true,
      loginToken: checkToken(this),
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    this.getTableData()
  }

  handleHideModal = (e) => {
    this.props.handleCloseParties()
  }

  getTableData() {
    NProgress.start()

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
    } = this.state
    this.props.api
      .transactionRead(companyId, transactionId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          loading: false,
        })
      })
  }

  handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()

    NProgress.start()
    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
    } = this.state
    const personId = e.target.value

    this.props.api
      .transactionPartiesDelete(companyId, transactionId, {
        data: {
          data: [
            {
              person_id: personId,
            },
          ],
        },
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    const columns = [
      {
        id: 'clientName',
        Header: 'Name',
        accessor: (d) => d.client_name,
        sortable: false,
      },
      {
        id: 'city',
        Header: 'Address',
        accessor: (d) => d.address,
        sortable: false,
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: (d) => {
          if (d.email === null || d.email === '') {
            return <span className="error">Unknown</span>
          } else {
            return d.email
          }
        },
        sortable: false,
      },
      {
        id: 'dateOfBirth',
        Header: 'Date of Birth',
        accessor: (d) => {
          if (_.isNil(d.date_of_birth) || _.isNil(d.date_of_birth.date)) {
            return 'N/A'
          } else {
            return <Moment format="MMMM D, YYYY">{d.date_of_birth.date}</Moment>
          }
        },
        sortable: false,
      },
      {
        id: 'phoneSms',
        Header: 'Phone Number',
        accessor: (d) => d.phone_sms,
        sortable: false,
      },
      {
        id: 'delete',
        Header: '',
        width: 75,
        className: 'parties__delete',
        accessor: (d) => {
          return (
            <button
              className="link btn btn--mod-2 btn--solid-red no-margin"
              value={d.id}
              onClick={this.handleDelete}
            >
              <img src={doc_party_del} alt="Delete Party from Document" />
            </button>
          )
        },
        sortable: false,
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(appPaths.PartiesRead(rowInfo.original.id))
        },
      }
    }

    const tableNoResults = () => {
      return (
        <div className="inner">
          <div>
            <img src={parties_icon} alt="Parties Icon" />
            <p>{this.state.error ? this.state.error : ' No Parties Found'}</p>
          </div>
        </div>
      )
    }

    const tableData = this.props.parties

    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three" />
            <div className="tile">
              <div>
                <div className="dashbaord__tile full">
                  <div className="inner__tile">
                    <button
                      type="button"
                      className="link right"
                      onClick={this.handleHideModal}
                    >
                      <img src={document_close} alt="DOcument Close" />
                    </button>
                    <h2>Parties</h2>
                    <p className="row">
                      To add parties to the party, please type the name of the
                      person you wish to add below.
                    </p>
                    <div className="doc__parties">
                      <div className="row">
                        <PartiesAutoSuggest
                          handleDataRefresh={this.handleDataRefresh}
                        />
                      </div>
                      <ReactTableCommon
                        tableData={tableData}
                        columns={columns}
                        onRowClick={onRowClick}
                        tableNoResults={tableNoResults}
                      />
                    </div>
                  </div>
                  <div className="document_buttons">
                    <button
                      type="submit"
                      className="col-40 btn btn--mod-2 btn--green"
                      value="Save"
                      onClick={this.handleHideModal}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let transaction = state.transactions.data[ownProps.match.params.ti]
  let parties = transaction.parties
  if (!_.isArray(parties)) {
    parties = [parties]
  }

  return {
    account: state.account,
    parties,
  }
}

export default withApi(withRouter(connect(mapStateToProps)(TransactionParties)))
