import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import Moment from 'react-moment'
import moment from 'moment'
// JSPDF
import * as jsPDF from 'jspdf'
import 'jspdf-autotable'
import * as JSZip from 'jszip'
import {saveAs} from 'file-saver'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {validateInput, showFormErrors} from '../../utils/validate'
import withApi from '../../api/withApi'
// Components
import PartiesDelete from './PartiesDelete'
import FormError from '../common/FormError'
import TransactionPartyReadAllTable from '../transactions/TransactionPartyReadAllTable'
// Images
import large_edit from '../../img/large_edit.svg'
import large_delete from '../../img/large_delete.svg'
import DateInput from '../common/DateInput'
import PhoneNumberInput from '../common/PhoneNumberInput'
import _ from 'lodash'
import TextInput from '../common/TextInput'
import {networkErrorDetail} from '../../utils/urls'

class PartiesRead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clientName: '',
      address: '',
      partyAccountId: this.props.match.params.ac,
      email: '',
      employer: '',
      employerAddress: '',
      employerPhoneNumber: '',
      phoneNumber: '',
      dateOfBirth: '',
      error: '',
      handleDelete: false,
      lastUpdate: '',
      lastVerified: '',
      loginToken: checkToken(this),
      phoneFax: '',
      phoneSMS: '',
      relationshipAge: '',
      transactions: '',
      userAccount: checkUserAccount(this),
      showDownloadVerificationButton: false,
      occupation: '',
      networkActive: false,
    }
  }

  componentWillMount() {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const partyAccountId = this.state.partyAccountId

    this.props.api
      .partyRead(companyId, partyAccountId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  componentWillReceiveProps(nextProps) {
    this.parsePartyToState(nextProps.party)
  }

  componentDidMount() {
    this.parsePartyToState(this.props.party)
  }

  parsePartyToState = (flatData) => {
    if (_.isNil(flatData)) {
      this.setState({
        clientName: '',
        dateOfBirth: '',
        address: '',
        relationshipAge: '',
        transactions: '',
        lastUpdate: '',
        lastVerified: '',
        email: '',
        employer: '',
        employerAddress: '',
        employerPhoneNumber: '',
        occupation: '',
        phoneNumber: '',
        phoneFax: '',
        showDownloadVerificationButton: false,
      })
      return
    }

    let lastVerified = ''
    if (flatData.last_verified) {
      lastVerified = flatData.last_verified.date
    } else {
      lastVerified = 'N/A'
    }

    let transactions = flatData.transaction_count

    this.setState({
      clientName: flatData.client_name || '',
      dateOfBirth: !_.isNil(flatData.date_of_birth)
        ? flatData.date_of_birth.date.substr(
            0,
            flatData.date_of_birth.date.indexOf(' '),
          )
        : '',
      address: flatData.address || '',
      relationshipAge: flatData.created_at.date,
      transactions: transactions,
      lastUpdate: flatData.updated_at.date,
      lastVerified: lastVerified,
      email: flatData.email || '',
      employer: flatData.employer || '',
      employerAddress: flatData.employer_address || '',
      employerPhoneNumber: flatData.employer_phone_number || '',
      occupation: flatData.occupation || '',
      phoneFax: flatData.phone_fax || '',
      phoneNumber: flatData.phone_sms || '',
      showDownloadVerificationButton: flatData.has_verification_data,
    })
  }

  handleBack = (e) => {
    this.props.history.goBack()
  }

  handleCloseDelete = (e) => {
    this.setState({
      handleDelete: false,
    })
  }

  handleDelete = (e) => {
    this.setState({
      handleDelete: true,
    })
  }

  handleEdit = (e) => {
    this.setState({
      edit: true,
    })
  }

  handleInput = (e) => {
    const fieldName = e.target.name
    const nextValue = e.target.value

    this.updateValueInState(fieldName, nextValue)

    // Validate email on input
    if (fieldName === 'email') {
      validateInput(this, e)
    }
  }

  updateValueInState(key, value) {
    this.setState({
      [key]: value,
    })
  }

  handleVerificationData = (e) => {
    NProgress.start()

    this.setState({networkActive: true})

    const companyId = this.state.userAccount.company.id
    const partyAccountId = this.state.partyAccountId

    this.props.api
      .partyDownloadVerificationData(companyId, partyAccountId)
      .then((response) => {
        let serverDateFormat = 'YYYY-MM-DD HH:mm:ss'

        let lastVerified =
          this.state.lastVerified !== null &&
          this.state.lastVerified !== '' &&
          this.state.lastVerified !== 'N/A'
            ? moment(this.state.lastVerified, serverDateFormat).format(
                'MMMM D, YYYY',
              )
            : 'N/A'

        let dateOfBirth =
          this.state.dateOfBirth !== null &&
          this.state.dateOfBirth !== '' &&
          this.state.dateOfBirth !== 'N/A'
            ? moment(this.state.dateOfBirth, serverDateFormat).format(
                'MMMM D, YYYY',
              )
            : 'N/A'

        let clientName = this.state.clientName

        var doc = new jsPDF()

        doc.text('Person Information', 14, 20)

        doc.autoTable({
          startY: 25,
          theme: 'plain',
          html: '#table',
          columnStyles: {
            0: {columnWidth: 44},
            1: {columnWidth: 44},
            2: {columnWidth: 44},
          },
          head: [['Client Name', 'Date of Birth', 'Email']],
          body: [[clientName, dateOfBirth, this.state.email]],
        })

        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 5,
          theme: 'plain',
          html: '#table',
          columnStyles: {
            0: {columnWidth: 44},
            1: {columnWidth: 44},
            2: {columnWidth: 44},
          },
          head: [['Address', 'Phone Number', ' Last Verified']],
          body: [[this.state.address, this.state.phoneNumber, lastVerified]],
        })

        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 5,
          theme: 'plain',
          html: '#table',
          columnStyles: {
            0: {columnWidth: 33},
            1: {columnWidth: 33},
            2: {columnWidth: 33},
            3: {columnWidth: 33},
          },
          head: [
            [
              'Occupation',
              'Employer',
              'Employer Address',
              'Employer Phone Number',
            ],
          ],
          body: [
            [
              this.state.occupation,
              this.state.employer,
              this.state.employerAddress,
              this.state.employerPhoneNumber,
            ],
          ],
        })

        var zip = new JSZip()
        zip.file('info.pdf', doc.output())

        var blob = new Blob([response.data], {type: 'application/zip'})
        zip.file('verification_data.zip', blob)

        zip.generateAsync({type: 'blob'}).then(function (content) {
          saveAs(content, `${clientName}.zip`)
        })
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()

        this.setState({networkActive: false})
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.setState({networkActive: true})

      const companyId = this.state.userAccount.company.id
      const partyAccountId = this.state.partyAccountId

      const {
        clientName,
        dateOfBirth,
        email,
        address,
        phoneNumber,
        occupation,
        employer,
        employerAddress,
        employerPhoneNumber,
      } = e.target

      this.props.api
        .partyUpdate(companyId, partyAccountId, {
          client_name: clientName.value,
          email: email.value,
          employer: employer.value,
          employer_address: employerAddress.value,
          employer_phone_number: employerPhoneNumber.value,
          occupation: occupation.value,
          date_of_birth: dateOfBirth.value,
          address: address.value,
          phone_voice: '',
          phone_sms: phoneNumber.value,
          phone_fax: '',
        })
        .then((response) => {
          this.setState({
            error: '',
            edit: false,
          })
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()

          this.setState({networkActive: false})
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="blue__bar menu__body flexbox">
          <div className="title">
            <button type="button" className="link" onClick={this.handleBack}>
              <i className="fas fa-chevron-left" /> Back{' '}
              <span className="vert__lines">
                <span className="vert__line" />
              </span>
            </button>
            <h2>{this.state.clientName}</h2>
          </div>
        </div>
        <div className="tile user--settings">
          <div className="dashbaord__tile large full-width">
            <div className="inner__tile">
              <form method="post" onSubmit={this.handleSubmit} noValidate>
                <div>
                  <h2 className="left">Party Record</h2>
                  {this.state.edit ? (
                    <div>
                      <button
                        type="submit"
                        className="col-40 btn btn--mod-2 btn--green no-margin"
                        value="Save"
                        disabled={this.state.networkActive}
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        type="button"
                        className="link right"
                        onClick={this.handleDelete}
                      >
                        <img src={large_delete} alt="Large trash" />
                      </button>
                      <button
                        type="button"
                        className="link right"
                        onClick={this.handleEdit}
                      >
                        <img src={large_edit} alt="Large edit" />
                      </button>
                    </div>
                  )}
                  <FormError error={this.state.error} />
                  <div className="row">
                    <table className="">
                      <tbody>
                        <tr>
                          <td>
                            <TextInput
                              id="clientName"
                              title="Client Name"
                              placeholder="Company Name, First Name, Middle Name, Last Name, Signing Authority (including title – if applicable"
                              value={this.state.clientName}
                              isRequired={true}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <DateInput
                              id="dateOfBirth"
                              title="Date of Birth"
                              date={this.state.dateOfBirth}
                              isRequired={false}
                              isEditing={this.state.edit}
                              required={false}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <TextInput
                              id="email"
                              title="Email"
                              placeholder="Enter Email"
                              value={this.state.email}
                              isRequired={false}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          {!this.state.edit &&
                            !_.isNil(this.props.party) &&
                            this.props.party.has_verification_data && (
                              <td>
                                <button
                                  type="button"
                                  className="btn btn--blue no-margin"
                                  onClick={this.handleVerificationData}
                                  disabled={this.state.networkActive}
                                >
                                  Download Verification Data
                                </button>
                              </td>
                            )}
                        </tr>
                        <tr>
                          <td>
                            <TextInput
                              id="address"
                              title="Address"
                              placeholder="Street No., Street Name, City/Town, Province, Postal Code, Country"
                              value={this.state.address}
                              isRequired={false}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <PhoneNumberInput
                              id="phoneNumber"
                              title="Phone Number"
                              placeholder="Enter phone number"
                              value={this.state.phoneNumber}
                              isRequired={true}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <TextInput
                              id="occupation"
                              title="Occupation"
                              placeholder="Enter Occupation"
                              value={this.state.occupation}
                              isRequired={false}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <TextInput
                              id="employer"
                              title="Employer"
                              placeholder="Enter Employer"
                              value={this.state.employer}
                              isRequired={false}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <TextInput
                              id="employerAddress"
                              title="Employer Address"
                              placeholder="Street No., Street Name, City/Town, Province, Postal Code, Country"
                              value={this.state.employerAddress}
                              isRequired={false}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                          <td>
                            <PhoneNumberInput
                              id="employerPhoneNumber"
                              title="Employer Phone Number"
                              placeholder="(555) 555-5555"
                              value={this.state.employerPhoneNumber}
                              isRequired={false}
                              isEditing={this.state.edit}
                              onChange={(e, id, value) =>
                                this.updateValueInState(id, value)
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form__group">
                              <div className="row">
                                <label>Transactions</label>
                              </div>
                              <p>{this.state.transactions}</p>
                            </div>
                          </td>
                          <td>
                            <div className="form__group">
                              <div className="row">
                                <label>Relationship Age</label>
                              </div>
                              <p>
                                Since{' '}
                                <Moment format="YYYY">
                                  {this.state.relationshipAge}
                                </Moment>{' '}
                                ({' '}
                                <Moment fromNow>
                                  {this.state.relationshipAge}
                                </Moment>{' '}
                                )
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="form__group">
                              <div className="row">
                                <label>Last Verified</label>
                              </div>
                              <p>
                                {this.state.lastVerified === 'N/A' ? (
                                  this.state.lastVerified
                                ) : (
                                  <Moment format="MMMM D, YYYY">
                                    {this.state.lastVerified}
                                  </Moment>
                                )}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="form__group">
                              <div className="row">
                                <label>Last Update</label>
                              </div>
                              <p>
                                <Moment format="MMMM D, YYYY">
                                  {this.state.lastUpdate}
                                </Moment>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="dashbaord__tile large no-padding">
            <div className="inner__tile">
              <h2>Party Transactions</h2>
              <TransactionPartyReadAllTable
                partyId={this.props.match.params.ac}
              />
            </div>
          </div>
        </div>

        {this.state.handleDelete && (
          <PartiesDelete handleCloseDelete={this.handleCloseDelete} />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  account: state.account,
  party: state.parties.data[ownProps.match.params.ac],
})

export default withApi(withRouter(connect(mapStateToProps)(PartiesRead)))
