import React from 'react'
import _ from 'lodash'
import Moment from 'moment'

/**
 * Build default empty table view
 *
 * @param {object} icon - Icon object to display
 * @param {string} error - Error to display (if any)
 */
export const buildTableEmpty = (icon, error) => (
  <div className="inner">
    <div>
      <img src={icon} alt="Empty Table" />
      <p>
        {!_.isNil(error) && !_.isEmpty(error) ? error : ' No Results Found'}
      </p>
    </div>
  </div>
)

/**
 * Build a column that shows the id key from the row data or N/A if null or empty
 *
 * @param {string} id - Id of the column (and key to access from the data)
 * @param {string} Header - Header to display for the table
 * @param {string|null} className - The class to apply to the column
 */
export const buildColumnNa = (id, Header, className = null) =>
  buildColumn(id, Header, className, (data) => {
    if (_.isNil(data[id]) || _.isEmpty(data[id])) {
      return 'N/A'
    } else {
      return data[id]
    }
  })

/**
 * Build a column that shows a date using the id key from the row data or N/A if not available
 *
 * @param {string} id - Id of the column (and key to access from the data)
 * @param {string} Header - Header to display for the table
 * @param {string|null} className - The class to apply to the column
 * @param {string} format - The format of the date to show
 */
export const buildColumnDate = (
  id,
  Header,
  className = null,
  format = 'MMM D, YYYY',
) =>
  buildColumn(id, Header, className, (data) => {
    if (_.isNil(data[id]) || _.isEmpty(data[id])) {
      return 'N/A'
    } else {
      return <Moment format={format}>{data[id].date}</Moment>
    }
  })

/**
 * Build a column that shows the value using the id key from the row data
 *
 * @param {string} id - Id of the column (and key to access from the data)
 * @param {string} Header - Header to display for the table
 * @param {string|null} className - The class to apply to the column
 */
export const buildColumnDefault = (id, Header, className = null) =>
  buildColumn(id, Header, className, (data) => data[id])

/**
 * Builds the row using the accessor callback provided
 *
 * @param {string} id - Id of the column
 * @param {string} Header - Header to display for the table
 * @param {string} className - Name of the class to use in display
 * @param {fn} accessor - Function callback for the row in the form (data) => {}
 */
export const buildColumn = (id, Header, className, accessor) => ({
  id,
  Header,
  className,
  accessor,
})
