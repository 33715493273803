import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import NumberFormat from 'react-number-format'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import {validateInput, showFormErrors} from '../../utils/validate'
// Components
import FormError from '../common/FormError'
import RoleReadAllSelect from '../role/RoleReadAllSelect'
import SubscribersAutoSuggest from '../subscribers/SubscribersAutoSuggest'
// Images
import hand_shake from '../../img/hand_shake.png'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import _ from 'lodash'

class AccountCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: '',
      firstName: '',
      lastName: '',
      password: '',
      phoneSMS: '',
      loginToken: checkToken(this),
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseCreate()
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    // Validate email or password on input
    if (e.target.name === 'email' || e.target.name === 'password') {
      validateInput(this, e)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      const {
        email,
        password,
        firstName,
        lastName,
        commissionerStamp,
        phoneSMS,
        roleId,
      } = e.target

      let companyId = ''
      if (this.state.userAccount.role.id === 1) {
        const companyName = e.target.companyId.attributes.value.nodeValue
        const companyNamesMatched = _.filter(
          this.props.subscribers,
          (subscriber) => {
            return subscriber.name === companyName
          },
        )

        if (companyNamesMatched.length > 0) {
          companyId = e.target.companyId.attributes.data.value
        } else {
          this.setState({
            error:
              'You must select a company from the list of companies.  If your company does not exist, please add company through Subscriber.',
          })
          return
        }
      } else {
        companyId = this.state.userAccount.company.id
      }

      if (companyId === '') {
        this.setState({
          error:
            'You must select a company from the list of companies.  If your company does not exist, please add company through Subscriber.',
        })
        return
      }

      NProgress.start()

      this.setState({
        networkActive: true,
      })

      this.props.api
        .accountCreate(companyId, {
          email: email.value,
          password: password.value,
          first_name: firstName.value,
          last_name: lastName.value,
          commissioner_stamp: commissionerStamp.value,
          phone_number: phoneSMS.value,
          role: {
            id: roleId.value,
          },
        })
        .then((response) => {
          this.props.handleDataRefresh()
          this.props.handleCloseCreate()
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()

          this.setState({
            networkActive: false,
          })
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__two">
              <button
                type="button"
                className="close link"
                onClick={this.handleHideModal}
              >
                <i className="fas fa-times" />
              </button>
              <div className="modal__form">
                <div className="modal__inner">
                  <h1>Create User Account</h1>
                  <form method="post" onSubmit={this.handleSubmit} noValidate>
                    <div className="form__group">
                      <div className="row">
                        <label id="emailLabel">Email</label>
                      </div>
                      <input
                        type="email"
                        name="email"
                        ref="email"
                        value={this.state.email}
                        onChange={this.handleInput}
                        placeholder="Enter email"
                        title="Email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        required
                      />
                      <div className="error" id="emailError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="passwordLabel" className="left">
                          Password
                        </label>
                      </div>
                      <input
                        type="password"
                        name="password"
                        ref="password"
                        value={this.state.password}
                        onChange={this.handleInput}
                        placeholder="Enter password"
                        title="Password"
                        pattern="((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,})"
                        required
                      />
                      <div className="error" id="passwordError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="firstNameLabel">First Name</label>
                      </div>
                      <input
                        type="text"
                        name="firstName"
                        ref="firstName"
                        className="form__control"
                        value={this.state.firstName}
                        onChange={this.handleInput}
                        placeholder="Enter first name"
                        title="First Name"
                        required
                      />
                      <div className="error" id="firstNameError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="lastNameLabel">Last Name</label>
                      </div>
                      <input
                        type="text"
                        name="lastName"
                        ref="lastName"
                        className="form__control"
                        value={this.state.lastName}
                        onChange={this.handleInput}
                        placeholder="Enter last name"
                        title="Last Name"
                        required
                      />
                      <div className="error" id="lastNameError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="phoneSMSLabel">Phone Number</label>
                      </div>
                      <NumberFormat
                        format="(###) ###-####"
                        mask="_"
                        type="text"
                        name="phoneSMS"
                        ref="phoneSMS"
                        className="form__control"
                        value={this.state.phoneSMS}
                        onChange={this.handleInput}
                        placeholder="Enter phone number"
                        title="Phone number"
                      />
                      <div className="error" id="phoneSMSError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label id="commissionerStampLabel">
                          Commissioner Stamp
                        </label>
                      </div>
                      <textarea
                        rows="5"
                        name="commissionerStamp"
                        ref="commissionerStamp"
                        className="form__control"
                        value={this.state.commissionerStamp}
                        onChange={this.handleInput}
                        placeholder="Enter Commissioner stamp"
                        title="Commissioner stamp"
                        required
                      />
                      <div className="error" id="commissionerStampError" />
                    </div>
                    <div className="form__group">
                      <div className="row">
                        <label>User Role</label>
                      </div>
                      <RoleReadAllSelect />
                    </div>
                    {this.state.userAccount.role.id === 1 && (
                      <div className="form__group">
                        <div className="row">
                          <label>Company</label>
                        </div>
                        <SubscribersAutoSuggest />
                      </div>
                    )}

                    <FormError error={this.state.error} />
                    <div className="form__group">
                      <div className="row">
                        <button
                          type="submit"
                          className="btn btn--blue-solid"
                          value="Create"
                          disabled={this.state.networkActive}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal__mark">
                <img src={hand_shake} className="modal__img" alt="logo" />

                <div className="top__angle" />
                <div className="bottom__angle" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  subscribers: _.map(state.companies.data, (company, key) => company),
})

export default withApi(withRouter(connect(mapStateToProps)(AccountCreate)))
