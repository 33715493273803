import {
  UPDATE_ACCOUNTS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_COMPANY,
  DELETE_ACCOUNT,
} from '../constants'

export const updateAccounts = (payload) => ({
  type: UPDATE_ACCOUNTS,
  payload,
})

export const updateAccount = (payload) => ({
  type: UPDATE_ACCOUNT,
  payload,
})

export const updateAccountCompany = (payload) => ({
  type: UPDATE_ACCOUNT_COMPANY,
  payload,
})

export const deleteAccount = (payload) => ({
  type: DELETE_ACCOUNT,
  payload,
})
