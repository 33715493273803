import React, {Component} from 'react'
// Components
import FormError from '../common/FormError'
import PropTypes from 'prop-types'
import _ from 'lodash'

export const ACTION_DESTRUCTIVE = 'red'
export const ACTION_SUCCESS = 'green'
export const ACTION_DEFAULT = 'blue'

class Modal extends Component {
  submit = (e) => {
    e.preventDefault()
    this.props.onAction()
  }

  render() {
    const {
      title,
      message,
      action,
      actionType,
      cancel,
      error,
      onCancel,
    } = this.props

    const actionButtonClassName = `btn btn--mod-2 btn--${actionType}`

    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    {!_.isNil(onCancel) && (
                      <button
                        type="button"
                        className="close link"
                        onClick={onCancel}
                        disabled={this.props.disabled}
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                    <h5>{title}</h5>
                    <p>{message}</p>
                    <form method="post" onSubmit={this.submit} noValidate>
                      <FormError error={error} />
                      <div className="form__group no-margin">
                        {!_.isNil(this.props.onAction) && (
                          <button
                            type="submit"
                            className={actionButtonClassName}
                            value="Close"
                            disabled={this.props.disabled}
                          >
                            {action}
                          </button>
                        )}
                        {!_.isNil(onCancel) && (
                          <button
                            type="button"
                            className="btn btn--mod-2 btn--blue"
                            onClick={onCancel}
                            value="Cancel"
                            disabled={this.props.disabled}
                          >
                            {cancel}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.string,
  cancel: PropTypes.string.isRequired,
  actionType: PropTypes.oneOf([
    ACTION_DESTRUCTIVE,
    ACTION_DEFAULT,
    ACTION_SUCCESS,
  ]),
  action: PropTypes.string,
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  disabled: PropTypes.bool,
}

Modal.defaultProps = {
  actionType: ACTION_DEFAULT,
  cancel: 'Cancel',
  disabled: false,
}

export default Modal
