import produce from 'immer'
import {
  UPDATE_TRANSACTIONS,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
} from '../constants'
import _ from 'lodash'

//   const closedAt = `${closedDate.getFullYear()}-${
//     closedDate.getMonth() + 1
//   }-${closedDate.getDate()} ${closedDate.getHours()}:${closedDate.getMinutes()}:${closedDate.getSeconds()}.${closedDate.getMilliseconds()}`

export default (
  state = {
    data: {},
  },
  action,
) => {
  return produce(state, (draft) => {
    const {payload} = action

    switch (action.type) {
      case UPDATE_TRANSACTIONS: {
        // Empty the data to the newly downloaded
        draft.data = {}

        _.forEach(payload, (transaction) => {
          draft.data[transaction.id] = transaction
        })
        return draft
      }
      case UPDATE_TRANSACTION: {
        draft.data[payload.id] = payload
        return draft
      }
      case DELETE_TRANSACTION: {
        delete draft.data[payload.id]
        return draft
      }
      default:
        return draft
    }
  })
}
