import {
  UPDATE_PRICING,
  UPDATE_REGIONS,
  UPDATE_PAGE_SIZE,
  UPDATE_LOCKED_TRANSACTION_ID,
} from '../constants'

export function updatePricing(payload) {
  return {
    type: UPDATE_PRICING,
    payload,
  }
}

export function updateRegions(payload) {
  return {
    type: UPDATE_REGIONS,
    payload,
  }
}

/**
 * Dispatch action for redux to update the page size
 *
 * @param {number} payload New page size
 */
export function updatePageSize(payload) {
  return {
    type: UPDATE_PAGE_SIZE,
    payload,
  }
}

export function updateLockedTransactionId(payload) {
  return {
    type: UPDATE_LOCKED_TRANSACTION_ID,
    payload,
  }
}
