import _ from 'lodash'

/**
 * Converts the json api spec data (https://jsonapi.org) to a flat object.
 *
 * @param {array|object} data Data item(s) in the json api spec response
 * @param {array} included Included items in the json api spec response
 */
export const jsonApiSpecToFlatObject = (data, included) => {
  if (_.isArray(data)) {
    let result = []
    _.forEach(data, (element) => {
      result.push(jsonApiSpecToFlatObject(element, included))
    })
    return result
  } else {
    let relationships = data.relationships
    var result = data.attributes

    if (data.id !== undefined) {
      result.id = data.id
    }

    _.forOwn(relationships, (relationship, key) => {
      result = _.assign(
        {},
        result,
        buildRelationship(key, relationship.data, included, false),
      )
    })

    return result
  }
}

/**
 * Converts the relationships from {type, id} to the full object with attributes from the includes
 *
 * @param {string} relationshipKey The Key to save the relationship under
 * @param {array|object} relationship The relationships to be converted to flat objects
 * @param {array} included Included items in the json api spec response
 * @param {boolean} fromArray Tells if the relationship is coming from an array type or an object type. This flag determines if the result should be in the form {type:data} or {data}
 */
const buildRelationship = (
  relationshipKey,
  relationship,
  included,
  fromArray,
) => {
  // TODO: Relationships that contain relationships

  if (_.isArray(relationship)) {
    return {
      [relationshipKey]: _.map(relationship, (relationship) => {
        return buildRelationship(relationshipKey, relationship, included, true)
      }),
    }
  } else {
    let relationshipItem = _.first(
      _.filter(included, (includedItem) => {
        return (
          includedItem.id === relationship.id &&
          includedItem.type === relationship.type
        )
      }),
    )

    if (fromArray) {
      return {id: relationship.id, ...relationshipItem.attributes}
    } else {
      return {
        [relationshipKey]: {
          id: relationship.id,
          ...relationshipItem.attributes,
        },
      }
    }
  }
}
