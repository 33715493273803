import _ from 'lodash'
import {hashId} from './hashid'

/**
 * Build a url path.
 * The path should be the full path to the endpoint that you are using.
 * If the path value is an integer it will be automatically hashed, if it's a string it will just be added as is.
 *
 * @param {array} path
 *
 * @returns The built url
 */
export const buildUrl = (
  path,
  page = null,
  pageSize = null,
  filterBy = null,
  sortBy = null,
) => {
  var url = ''

  const pathComponents = _.split(path, '/')

  _.forEach(pathComponents, (pathComponent) => {
    if (pathComponent === '') {
      return
    }

    const pathComponentAsNumber = _.toNumber(pathComponent)

    if (_.isFinite(pathComponentAsNumber)) {
      url += `/${hashId(pathComponentAsNumber)}`
    } else {
      url += `/${pathComponent}`
    }
  })

  if (!_.isNil(page) && !_.isNil(pageSize)) {
    url += `/${page}/${pageSize}`
  }

  let queryString = ''
  if (!_.isNil(filterBy)) {
    queryString += queryString.length > 0 ? `&${filterBy}` : `?${filterBy}`
  }

  if (!_.isNil(sortBy)) {
    queryString += queryString.length > 0 ? `&${sortBy}` : `?${sortBy}`
  }

  if (!_.isNil(queryString) && queryString.length > 0) {
    url += queryString
  }

  return url
}

export const networkErrorDetail = (error) => {
  try {
    if (
      !_.isNil(error) &&
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.errors) &&
      error.response.data.errors.length > 0 &&
      !_.isNil(error.response.data.errors[0]) &&
      !_.isNil(error.response.data.errors[0].detail)
    ) {
      return error.response.data.errors[0].detail
    } else {
      return 'Error. Please try again.'
    }
  } catch (err) {
    console.log(err)
    return 'Error. Please try again.'
  }
}
