import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
import Autosuggest from 'react-autosuggest'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import _ from 'lodash'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.client_name

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.client_name +
      ' ' +
      (suggestion.phone_sms !== null ? suggestion.phone_sms : '')}
  </div>
)

class PartiesAutoSuggest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loginToken: checkToken(this),
      party: '',
      suggestions: [],
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
      value: '',
    }
  }

  componentWillMount() {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    this.props.api
      .partyReadAll(companyId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  onChange = (event, {newValue}) => {
    this.setState({
      value: newValue,
    })
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : _.filter(this.props.parties, (party) => {
          return _.includes(party.client_name.trim().toLowerCase(), inputValue)
        })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      value: '',
    })
  }

  onSuggestionSelected = (event, {suggestion}) => {
    this.setState({
      error: null,
    })

    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId

    this.props.api
      .transactionPartiesCreate(companyId, transactionId, {
        data: [
          {
            person_id: suggestion.id,
            label: 'Signee',
          },
        ],
      })
      .then((response) => {
        this.onSuggestionsClearRequested()
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    const {value, suggestions} = this.state
    const {parties} = this.props

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      name: 'party',
      placeholder: 'Search',
      value,
      data: this.state.party,
      onChange: this.onChange,
    }

    // Add the current users company id invisibly to be used by the form
    if (parties.length === 0) {
      return (
        <React.Fragment>
          <p className="left">No parties found</p>
          <input type="hidden" name="party" value={this.props.party} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {this.state.error && <p>{this.state.error}</p>}

          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.onSuggestionSelected}
          />
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  parties: _.map(state.parties.data, (party, key) => party),
})

export default withApi(withRouter(connect(mapStateToProps)(PartiesAutoSuggest)))
