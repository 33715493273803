import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Components
import FormError from '../common/FormError'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import withApi from '../../api/withApi'
import {networkErrorDetail, buildUrl} from '../../utils/urls'

class TransactionDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transactionId: this.props.match.params.ti,
      error: '',
      loginToken: checkToken(this),
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseDelete()
  }

  handleSubmit = (e) => {
    e.preventDefault()

    NProgress.start()
    this.setState({networkActive: true})

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId

    this.props.api
      .transactionDelete(companyId, transactionId)
      .then((response) => {
        this.props.history.push(appPaths.Transaction)
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({networkActive: false})
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.handleHideModal}
                      disabled={this.state.networkActive}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h5>Delete Transaction?</h5>
                    <p>
                      Deleting a transaction will remove all associated
                      documents.
                    </p>
                    <form method="post" onSubmit={this.handleSubmit} noValidate>
                      <FormError error={this.state.error} />
                      <div className="form__group no-margin">
                        <button
                          type="submit"
                          className="btn btn--mod-2 btn--red"
                          value="Delete"
                          disabled={this.state.networkActive}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="btn btn--mod-2 btn--blue"
                          onClick={this.handleHideModal}
                          value="Keep"
                          disabled={this.state.networkActive}
                        >
                          Keep
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(TransactionDelete)))
