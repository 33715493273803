import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
import {updateUserAccount} from '../../redux/actions/account'
// Utils
import {
  checkToken,
  checkUserAccount,
  setUserAccount,
} from '../../utils/loginToken'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'

class TermsAndConditions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loginToken: checkToken(this),
      userAccount: checkUserAccount(this),
      networkActive: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({networkActive: true})

    NProgress.start()

    this.props.api
      .didAcceptTos()
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({networkActive: false})
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three terms">
              <div className="modal__form">
                <div className="modal__inner">
                  <div className="inner">
                    <button
                      type="button"
                      className="close link"
                      onClick={this.props.onCloseClick}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <h2>
                      ModernXMark<sup>&reg;</sup> Terms and Conditions
                    </h2>
                    <div>
                      <p>
                        <strong>Terms and Conditions</strong>
                      </p>

                      <ol>
                        <li>Definitions</li>
                      </ol>

                      <p>
                        In this document (the &ldquo;
                        <strong>Terms and Conditions</strong>&rdquo;):
                      </p>

                      <ul>
                        <li>
                          <p>
                            <strong>App </strong>means the functionality
                            embodied in the ModernXMark app, such functionality
                            broadly including signature and identifier upload
                            functionality;
                          </p>
                        </li>

                        <li>
                          <p>
                            <strong>Website </strong>means the functionality
                            embodied in{' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://modernxmark.com"
                            >
                              <u>modernxmark.com</u>
                            </a>{' '}
                            and/or{' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://dashboard.modernxmark.com"
                            >
                              <u>dashboard.modernxmark.com</u>
                            </a>{' '}
                            , such functionality broadly including document
                            upload and signatory identification functionality
                          </p>
                        </li>

                        <li>
                          <p>
                            <strong>Client </strong>is the person identified in
                            an enrolment operation that resulted in the creation
                            of access privileges to the document upload and
                            signatory identification functions of the Website to
                            a combination of username and password (&ldquo;Login
                            Credentials&rdquo;)
                          </p>
                        </li>

                        <li>
                          <p>
                            <strong>User</strong>&nbsp;means a person accessing
                            the functionality of the Website using the username
                            and password of a Client;
                          </p>
                        </li>

                        <li>
                          <p>
                            <strong>Signatory </strong>means a person,
                            identified by you, to which Modern has extended
                            access to the signature and identifier upload
                            functions of the App in respect of documents that
                            you have uploaded to the Website;
                          </p>
                        </li>

                        <li>
                          <p>
                            <strong>Modern</strong>&nbsp;means Vica Digital
                            Solutions Inc., the operator of the Website and the
                            App;
                          </p>
                        </li>
                      </ul>

                      <p>
                        The terms &ldquo;<strong>you</strong>&rdquo;, &ldquo;
                        <strong>your</strong>&rdquo;, and &ldquo;
                        <strong>yours</strong>&rdquo; refer to Client, unless
                        the document upload and signatory identification
                        functionality of the Website has been accessed via an
                        account assigned to a person other than the User and the
                        User has no authority to bind such person, in which
                        event, &ldquo;you&rdquo;, &ldquo;your&rdquo;, and
                        &ldquo;yours&rdquo; refer to User.
                      </p>

                      <p>
                        The terms &ldquo;<strong>Modern</strong>&rdquo;&rdquo;,
                        &ldquo;<strong>we</strong>&rdquo;, &ldquo;
                        <strong>us</strong>&rdquo;, and &ldquo;
                        <strong>our</strong>&rdquo; refer to Modern.
                      </p>

                      <ol start="2">
                        <li>Offer</li>
                      </ol>

                      <p>
                        The publication of the Website and the Terms and
                        Conditions constitutes an offer to provide access to the
                        document upload and signatory identification functions
                        of the Website to persons that agree to be bound by the
                        Terms and Conditions; your selection of &ldquo;I
                        agree&rdquo; in the enrolment operation constituted your
                        acceptance of the offer.
                      </p>

                      <ol start="3">
                        <li>Functionality and Availability for Use</li>
                      </ol>

                      <p>
                        While Modern endeavors to ensure that the Website and
                        the App are available at all times, Modern will not be
                        liable if, for any reason, either or both of the Website
                        and the App are unavailable, in whole or in part, at any
                        time or for any period. &nbsp;
                      </p>

                      <ol start="4">
                        <li>Payment</li>
                      </ol>

                      <p>
                        You agree to pay Modern in the amounts and on the terms
                        set forth in the Price List.
                      </p>

                      <ol start="5">
                        <li>Changes </li>
                      </ol>

                      <p>
                        Modern reserves the right, at its sole discretion, from
                        time to time, to modify, add, and/or delete
                      </p>

                      <ul>
                        <li>the Terms and Conditions;</li>
                        <li>
                          the functionality of the Website and the App; and
                        </li>
                        <li>the Price List</li>
                      </ul>

                      <p>
                        and you agree to be bound by such modifications,
                        additions and/or deletions (&ldquo;Changes&rdquo;). Such
                        Changes will be effective immediately upon notice to
                        you, which may be given by any means including posting
                        on the Website. You agree to regularly review the Terms
                        and Conditions and the Price List posted at the Website
                        and to be aware of such Changes.
                      </p>

                      <ol start="6">
                        <li>Ownership</li>
                      </ol>

                      <p>
                        As between you and Modern, exclusive ownership of, and
                        title to, all copyrights, trademarks, service marks,
                        patent rights, trade secrets and all other intellectual
                        property and other proprietary rights in the Website and
                        App and all of their functionality and content,
                        including, software, text, design, graphics, images,
                        data, advertisements, audio, video and all trade-marks,
                        service marks and trade names and the selection and
                        arrangements thereof will remain with, and vest in,
                        Modern. Except as expressly provided in the Terms and
                        Conditions, no licence to use, copy, distribute,
                        republish, transmit or otherwise exploit any Website or
                        App functionality or content is given to you and all
                        intellectual property and other proprietary rights in
                        and to the Website and App and all of their
                        functionality and content are expressly reserved to
                        Modern.
                      </p>

                      <ol start="7">
                        <li>Log In Credentials</li>
                      </ol>

                      <p>
                        You agree that contact information that you provided as
                        part of the enrolment process may be published in the
                        Modern online directory which can be accessed by others
                        to facilitate user communication and information
                        sharing. &nbsp;You warrant that the information you have
                        provided as part of the enrolment process is and shall
                        at all times be kept accurate. &nbsp;You are responsible
                        for the use of your Login Credentials and you must keep
                        them confidential and secure. &nbsp;&nbsp;You must
                        notify Modern immediately of any breach of security or
                        unauthorized use of your Login Credentials and agree to
                        indemnify Modern for all losses it may suffer as a
                        result of unauthorized use of your Login Credentials.
                        &nbsp;&nbsp;
                      </p>

                      <ol start="8">
                        <li>User Activity Data</li>
                      </ol>

                      <p>
                        Modern collects statistical information about the
                        occurrence of certain events in the course of use of the
                        Website and App. &nbsp;Modern may use this information
                        for its internal business purposes, including for
                        billing, to measure and understand the behavior and
                        preferences of our customers, to troubleshoot technical
                        problems, to enforce this agreement, and to ensure
                        proper functioning of the Website and App. &nbsp;You
                        warrant that you will not interfere or attempt to
                        interfere with the collection and transmission of such
                        information.
                      </p>

                      <ol start="9">
                        <li>Signatories</li>
                      </ol>

                      <p>
                        You acknowledge that your Signatories are bound by the
                        Terms and Conditions set forth at{' '}
                        <a href="http://www.modernxmark/userterms.com">
                          <u>www.modernxmark/userterms.com</u>
                        </a>
                      </p>

                      <ol start="10">
                        <li>Cookies</li>
                      </ol>

                      <p>
                        Modern uses cookies to identify you when you visit the
                        Website and to keep track of your browsing patterns and
                        build up a demographic profile. Modern' use of cookies
                        also allows you to be presented with a personalized
                        version of the site, carry out transactions and have
                        access to information about your account. Most browsers
                        allow you to turn off cookies. If you want to know how
                        to do this, please look at the help menu on your
                        browser. However, switching off cookies may restrict
                        your use of some features of the Website.
                      </p>

                      <ol start="11">
                        <li>User Content </li>
                      </ol>

                      <p>
                        The Website collects a great deal of content that is
                        personal or proprietary including but not limited to:
                        legal documents, images of signatures, sensor data
                        associated with the delivery of a signature including
                        stylus pressure and speed and video images of signatures
                        [&ldquo;Content&rdquo;]. &nbsp;&nbsp;
                      </p>

                      <p>
                        You grant Modern the right to collect Content for the
                        purposes of providing the functionality of the Website
                        and the App [collectively, &ldquo;the
                        Functionality&rdquo;] , the right to store the Content
                        so long as necessary to provide the Functionality and to
                        use and disclose the Content for purposes relating to
                        the provision of the Functionality [collectively,
                        &ldquo;the Purpose.&rdquo;] &nbsp;&nbsp;&nbsp;Modern
                        will not otherwise collect, retain, use or disclose
                        Content collected from you [&ldquo;Your Content&rdquo;].
                        &nbsp;&nbsp;For greater certainty, unless it receives
                        your written permission, Modern confirms it will not use
                        Your Content or derive information from it for any
                        advertising or similar commercial purposes. &nbsp;
                      </p>

                      <p>
                        As between the parties, you retain all right, title and
                        interest in and to the Your Content, other than the
                        rights specifically granted to Modern herein.
                        &nbsp;&nbsp;
                      </p>

                      <p>
                        Modern shall not disclose Your Content to any person
                        except to its affiliates, employees, internal
                        contractors and agents having both a need to know to
                        accomplish the Purpose and an obligation of
                        confidentiality.
                      </p>

                      <p>
                        The foregoing obligation of confidentiality and
                        restrictions on use hereunder shall not apply to
                        Content: (a) known to Modern before receipt from you,
                        without a duty of confidentiality; (b) generally
                        available to the public (or becomes so), unless due to a
                        Modern breach; (c) received by Modern from a third party
                        without a duly of confidentiality; or (d) independently
                        developed by or for Modern without any use of Your
                        Content.
                      </p>

                      <p>
                        It shall not be considered a breach of this Agreement if
                        Modern discloses Content as required by law provided
                        that Modern: (a) where permitted by law, gives you
                        written notice promptly upon receipt of a disclosure
                        requirement and before the disclosure is made; (b) takes
                        reasonable actions and provide reasonable assistance to
                        you to secure confidential treatment of the Content; and
                        (c) discloses only such Content as is required by law.
                      </p>

                      <p>
                        While Modern endeavors to ensure that Your Content is
                        available to you on the Website in accordance with the
                        Functionality, Modern will not be liable if, for any
                        reason, Content is lost. &nbsp;
                      </p>

                      <p>
                        Modern will delete Content promptly once the Purpose has
                        been satisfied. &nbsp;&nbsp;Data cannot be recovered
                        once deleted. &nbsp;&nbsp;
                        <strong>
                          You are strongly encouraged to maintain a backup of
                          all files in your library.
                        </strong>
                      </p>

                      <ol start="12">
                        <li>Security</li>
                      </ol>

                      <p>
                        Modern has implemented and will maintain and follow
                        commercially reasonable physical, technical and
                        organization measures intended to protect Your Content
                        against accidental, unauthorized or unlawful access or
                        disclosure, including without limitation, operational
                        security, encryption, access control, identity
                        management, threat management and network security.
                      </p>

                      <ol start="13">
                        <li>Data Breach </li>
                      </ol>

                      <p>
                        Modern will notify you of any security incidents
                        involving Your Content or Content of your Signatories in
                        accordance with applicable laws and, in any event,
                        promptly.
                      </p>

                      <ol start="14">
                        <li>Scope of Use</li>
                      </ol>

                      <p>
                        You may not, and agree not to, directly or indirectly
                        (including by allowing someone else to):
                      </p>

                      <ol type="a" className="alpha">
                        <li>
                          <p>
                            access the Website in a manner that could damage,
                            disable, abuse or otherwise interfere with the
                            Website, its security, any services, system
                            resources, accounts, servers or networks connected
                            to or accessible through the Website, or any other
                            person&rsquo;s use or enjoyment of the Website;
                          </p>
                        </li>

                        <li>
                          <p>
                            upload to, distribute to, or otherwise disseminate
                            through the Website any material or information of
                            any kind that is libelous, defamatory, obscene,
                            pornographic, abusive, or otherwise violates any law
                            or infringes or violates any rights of any other
                            person or entity (including any intellectual
                            property or other property rights), or that contain
                            viruses, backdoors or any other harmful or malicious
                            code;
                          </p>
                        </li>

                        <li>
                          <p>
                            sell, lease, transfer, provide or otherwise make
                            available the Website or any portion of the Website
                            to any third party, whether as a demonstration or
                            otherwise;
                          </p>
                        </li>

                        <li>
                          <p>
                            frame, mirror, scrape or data-mine the Website or
                            any content;
                          </p>
                        </li>

                        <li>
                          <p>
                            attempt to access any restricted areas of the
                            Website or Modern&rsquo;s systems;
                          </p>
                        </li>

                        <li>
                          <p>
                            take any action that imposes, or may impose, in
                            Modern's sole discretion, an unreasonable or
                            disproportionately large load on Modern's
                            infrastructure;
                          </p>
                        </li>

                        <li>
                          <p>
                            bypass or circumvent measures employed to prevent or
                            limit access to Website;
                          </p>
                        </li>

                        <li>
                          <p>
                            modify, adapt or hack Website or otherwise attempt
                            to gain unauthorized access to Website or related
                            systems or networks;
                          </p>
                        </li>

                        <li>
                          <p>
                            use Website in any unlawful manner, including but
                            not limited to violation of any person's privacy
                            rights;
                          </p>
                        </li>

                        <li>
                          <p>
                            use Website to store or transmit files, materials,
                            data, text, audio, video, images or other content
                            that infringes on any person's intellectual property
                            rights; and/or
                          </p>
                        </li>

                        <li>
                          <p>
                            use Website to knowingly post transmit, upload, link
                            to, send or store any viruses, malware, Trojan
                            horses, time bombs, or any other similar harmful
                            software.
                          </p>
                        </li>
                      </ol>

                      <ol start="15">
                        <li>Data Transmission</li>
                      </ol>

                      <p>
                        You recognize and agree that: (i) the Internet is not a
                        secure medium, and neither the privacy of your
                        communications, nor use of the Website can be
                        guaranteed; (ii) the nature of Internet communications
                        means that your communications may be susceptible to
                        data corruption, unauthorized access, interception and
                        delays. Modern shall not be responsible or liable for
                        any loss of privacy, disclosure of information, harm,
                        damage or loss that may result from your transmission of
                        any information to us in any connection with Website.
                        Modern does not warrant that: (a) Website will meet your
                        requirements, will operate in an uninterrupted or
                        error-free manner, or will be free of viruses, worms or
                        other harmful components; or (b) that any defects in
                        Website will be corrected.
                      </p>

                      <ol start="16">
                        <li>Disclaimer</li>
                      </ol>

                      <p>
                        THE APP AND THE WEBSITE ARE PROVIDED ON AN &ldquo;AS
                        IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS AND
                        MODERN DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND
                        CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, WITH
                        RESPECT TO THE WEBSITE, THE APP OR THE CONTENT,
                        INCLUDING ANY MERCHANTABILITY, MERCHANTABLE QUALITY,
                        DURABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                        NON-INFRINGEMENT. MODERN DOES NOT REPRESENT OR WARRANT:
                        THAT THE APP OR WEBSITE WILL MEET YOUR REQUIREMENTS;
                        THAT THE OPERATION OF THE APP OR WEBSITE WILL BE
                        UNINTERRUPTED AND ERROR-FREE; OR THE CORRECTNESS,
                        ACCURACY, OR RELIABILITY OF ANY CONTENT OR OTHER
                        INFORMATION PROVIDED THROUGH THE WEBSITE. YOU ASSUME THE
                        ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE APP
                        AND WEBSITE AND AS TO THE CORRECTNESS, ACCURACY AND
                        RELIABILITY OF ANY CONTENT OR OTHER INFORMATION PROVIDED
                        THROUGH THE WEBSITE.
                      </p>

                      <ol start="17">
                        <li>Limitation of Liability</li>
                      </ol>

                      <p>
                        YOU ACKNOWLEDGE THAT YOUR USE OF THE APP AND WEBSITE IS
                        AT YOUR SOLE RISK. IN NO EVENT WILL MODERN BE LIABLE FOR
                        ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                        INCIDENTAL, SPECIAL, PUNITIVE, OR AGGRAVATED DAMAGES,
                        DAMAGES FROM LOSS OF PROFITS OR REVENUES, FAILURE TO
                        REALIZE ANY EXPECTED SAVINGS, BUSINESS INTERRUPTION,
                        LOSS OF OPPORTUNITY, OR CORRUPTION OR LOSS OF DATA, OR
                        FAILURE TO TRANSMIT OR RECEIVE ANY DATA, EVEN IF MODERN
                        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN
                        ADVANCE. THE LIMITATIONS ON LIABILITY IN THIS PARAGRAPH
                        WILL APPLY IRRESPECTIVE OF THE NATURE OF THE CAUSE OF
                        ACTION, DEMAND OR CLAIM, INCLUDING BREACH OF CONTRACT
                        (INCLUDING FUNDAMENTAL BREACH), NEGLIGENCE, TORT OR ANY
                        OTHER LEGAL THEORY, AND WILL SURVIVE A FUNDAMENTAL
                        BREACH OR BREACHES OF THE TERMS AND CONDITIONS OR OF ANY
                        REMEDY CONTAINED HEREIN. FOR GREATER CERTAINTY, IN NO
                        EVENT WILL MODERN BE LIABLE IN RESPECT OF ANY THIRD
                        PARTY CLAIM.
                      </p>

                      <ol start="18">
                        <li>Indemnity</li>
                      </ol>

                      <p>
                        You agree to defend, indemnify and hold Modern, its
                        affiliates and related companies, and any of their
                        respective directors, officers, employees, agents,
                        contractors, harmless from and against all claims,
                        liability, and expenses, including all legal fees and
                        costs, arising out of your breach of any provision of
                        the Terms and Conditions, your access to the Website or
                        your Signatories use of the App.
                      </p>

                      <ol start="19">
                        <li>Mitigation</li>
                      </ol>

                      <p>
                        You agree that Modern can take any steps it deems
                        appropriate to prevent breach of this agreement or to
                        mitigate damages that would otherwise flow from breach.
                      </p>

                      <ol start="20">
                        <li>Governing Law</li>
                      </ol>

                      <p>
                        You agree that the Terms and Conditions will be governed
                        by and construed in accordance with the laws in effect
                        in the Province of Ontario, without giving effect to any
                        choice-of-law rules that may require the application of
                        the laws of another jurisdiction. Each of the parties
                        irrevocably submits to the non-exclusive jurisdiction of
                        the courts of Ontario for the purpose of any suit,
                        action or other proceeding arising out of this Agreement
                        or the subject matter hereof brought by either party or
                        their successors or assigns.
                      </p>

                      <ol start="21">
                        <li>Retention Policy</li>
                      </ol>

                      <p>
                        ModernXMark endeavors to retain documents uploaded,
                        prepared, signed, received and delivered (collectively
                        the "Documents") through its platform and have same
                        available for retrieval by the Client and User for a
                        period of Five (5) days following the close of a
                        transaction (the "Retention Period"). Following expiry
                        of the Retention Period, any and such all Documents will
                        be deleted and will no longer available for retrieval by
                        the Client and User. ModernXMark strongly recommends
                        that any and all Documents be immediately saved by the
                        Client and User in its own server/storage environment.
                        Notwithstanding the foregoing, ModernXMark accepts no
                        responsibility whatsoever and shall not be held liable
                        for any documents which may not be retrieved by the
                        User during or after the Retention Period.  
                      </p>

                      <ol start="22">
                        <li>General</li>
                      </ol>

                      <p>
                        If any provision of the Terms and Conditions are found
                        to be unlawful, void or unenforceable, then such
                        provision will be deemed severable and will not affect
                        the validity and enforceability of any remaining
                        provisions. The language in the Terms and Conditions
                        will not be interpreted strictly for or against any
                        party. Neither the Terms or Conditions nor any rights or
                        obligations hereunder, in whole or in part, may be
                        assigned by you. All of the provisions of these Terms
                        and Conditions that by their nature are intended to
                        survive, including but not limited to ownership,
                        disclaimers, limitations on liability and
                        indemnifications but not warranties, will survive
                        termination and you agree to remain bound by those
                        provisions.
                      </p>

                      {this.props.showButtons && (
                        <form
                          method="post"
                          onSubmit={this.handleSubmit}
                          noValidate
                        >
                          <div>
                            <button
                              type="submit"
                              className="btn btn--mod-2 btn--green"
                              value="Accept"
                              disabled={this.state.networkActive}
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="btn btn--mod-2 btn--red"
                              value="Decline"
                              onClick={
                                this.props.handleDeclineTermsAndConditions
                              }
                              disabled={this.state.networkActive}
                            >
                              Decline
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserAccount: (payload) => dispatch(updateUserAccount(payload)),
})

export default withApi(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)),
)
