import {UPDATE_PARTIES, UPDATE_PARTY, DELETE_PARTY} from '../constants'

export const updateParties = (payload) => ({
  type: UPDATE_PARTIES,
  payload,
})

export const updateParty = (payload) => ({
  type: UPDATE_PARTY,
  payload,
})

export const deleteParty = (payload) => ({
  type: DELETE_PARTY,
  payload,
})
