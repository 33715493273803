import Hashids from '../js/vendor/hashids.js'

export const hashId = (value) => {
  var hashids = new Hashids(
      process.env.REACT_APP_HASH,
      process.env.REACT_APP_PADDING,
    ),
    id = hashids.encode(value)

  return id
}
