import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Dropzone from 'react-dropzone'
import {Progress} from 'react-sweet-progress'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkToken, checkUserAccount} from '../../utils/loginToken'
import _ from 'lodash'
// Components
import FormError from '../common/FormError'
import ReactTableCommon from '../common/ReactTableCommon'
// Styles
import 'react-sweet-progress/lib/style.css'
// Images
import document_close from '../../img/document_close.svg'
import document_delete from '../../img/document_delete.svg'
import document_split from '../../img/document_split.svg'
import document_upload from '../../img/document_upload.svg'
import {networkErrorDetail} from '../../utils/urls'
import withApi from '../../api/withApi'
import Modal, {ACTION_SUCCESS} from '../common/Modal'
import {updateDocumentEdits} from '../../redux/actions/documents'

class DocumentUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      documentShow: false,
      done: false,
      error: '',
      loading: true,
      loginToken: checkToken(this),
      page: 0,
      pages: 1,
      progress: false,
      progressPercent: 0,
      transactionId: this.props.match.params.ti,
      userAccount: checkUserAccount(this),
      showUploadCompleteWarning: false,
      networkActive: false,
    }
  }

  componentWillMount() {
    this.getTableData()
  }

  handleDelete = (e) => {
    e.preventDefault()

    const selectedDocumentId = e.target.value

    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId

    this.props.api
      .documentDelete(companyId, transactionId, selectedDocumentId)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  handleEdit = (e) => {
    if (this.state.edit === true) {
      this.setState({
        edit: false,
      })
    } else {
      this.setState({
        edit: true,
      })
    }
  }

  handleHideModal = (e) => {
    this.props.handleCloseUpload()
  }

  handleInput = (e, id) => {
    var edit = {
      [id]: {id: `${id}`},
    }

    edit[id][e.target.name] = e.target.value
    if (e.target.name === 'name') {
      edit[id]['guid'] = e.target.value
    }

    this.props.updateDocumentEdits(edit)
  }

  handleSplit = (id, name, guid) => {
    const selectedDocumentId = id

    this.props.handleCloseUpload()

    this.props.history.push(
      appPaths.DocumentPrepare(this.state.transactionId, selectedDocumentId),
    )
  }

  handleUpload = (acceptedFiles) => {
    if (acceptedFiles) {
      NProgress.start()
      this.uploadFilesOneAtATime(acceptedFiles)
    }
  }

  /**
   * Need to upload documents one at a time as when we upload them all in parallel it causes issues with the API.
   * With the new update to add parties to transactions as a whole, we are actually adding parties to the documents
   * to keep with the document preparation future ability. There is a case when you upload multiple documents that
   * if there are documents without a specific party it will try to add it to them, but when the requests run
   * in parallel it will be added multiple times to a single document causing an issue when trying to retrieve
   * the parties in general as the mappings should be 1-1 but it ends up being 1-1 multiple times to the same document.
   *
   * This is a unique case where a document doesn't get parties associated to it for some reason and then you go to
   * the upload screen and upload 3 documents at a time. The party will get added to the document that had no parties
   * (assuming there are parties on the transaction already) and it will get added 3 times due to the parallel execution
   * of the upload.
   */
  uploadFilesOneAtATime = (files) => {
    const file = files.pop()
    const FormData = require('form-data')
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', file.name)
    formData.append('guid', file.name)

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId

    this.props.api
      .documentCreate(companyId, transactionId, formData, {
        headers: {
          'Content-Type':
            'multipart/form-data; charset=utf-8; boundary=__X_PAW_BOUNDARY__',
        },
      })
      .then(() => {
        this.setState({
          error: '',
        })
      })
      .catch((error) => {
        var errorStr = networkErrorDetail(error)
        if (errorStr.toLowerCase().indexOf('guid') >= 0) {
          errorStr = 'A document with that name already exists.'
        }

        this.setState({
          error: errorStr,
        })
      })
      .finally(() => {
        if (files.length === 0) {
          NProgress.done()
        } else {
          this.uploadFilesOneAtATime(files)
        }
      })
  }

  /**
   * Handles multiple documents being updated.
   * Upon completion calls upload complete as this method is called when the done button is clicked
   */
  handleMultiDocumentUpdate = () => {
    NProgress.start()

    this.setState({networkActive: true})

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
    } = this.state

    const {documents, edits} = this.props

    let sendableEdits = _.reduce(
      documents,
      (results, value) => {
        if (!_.isNil(edits[value.id])) {
          results.push(edits[value.id])
        }
        return results
      },
      [],
    )

    if (sendableEdits.length === 0) {
      this.uploadComplete()
      return
    }

    this.props.api
      .documentUpdateAll(companyId, transactionId, {data: sendableEdits})
      .then((response) => {
        this.removeUploadCompleteEdits()
        this.uploadComplete()
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()

        this.setState({networkActive: false})
      })
  }

  removeUploadCompleteEdits = () => {
    const {documents} = this.props
    var completeEdits = {}

    _.forEach(documents, (value) => {
      const {id} = value
      completeEdits[id] = null
    })

    this.props.updateDocumentEdits(completeEdits)
  }

  uploadComplete = () => {
    NProgress.start()

    this.setState({networkActive: true})

    const {
      userAccount: {
        company: {id: companyId},
      },
      transactionId,
    } = this.state

    this.props.api
      .documentUploadComplete(companyId, transactionId)
      .then((response) => {
        this.props.handleCloseUpload()
        this.props.handleUpdateTransaction()
      })
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()

        this.setState({networkActive: false})
      })
  }

  timer = (e) => {
    if (this.state.progressPercent >= 100) {
      this.setState({
        progressPercent: 100,
        progress: true,
        done: true,
      })
    } else {
      this.setState({
        progressPercent: this.state.progressPercent + 10,
        done: false,
      })
    }
  }

  didClickFileUploadBrowse = () => {
    this.refs.fileUploader.click()
  }

  onChangeFile = (event) => {
    event.stopPropagation()
    event.preventDefault()
    var file = event.target.files[0]
    this.uploadFilesOneAtATime([file])
  }

  getTableData() {
    NProgress.start()

    const companyId = this.state.userAccount.company.id
    const transactionId = this.state.transactionId
    let sortBy = null
    return this.props.api
      .documentReadAll(companyId, transactionId, sortBy)
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    const {edits} = this.props

    const columns = [
      {
        id: 'documentName',
        Header: 'Document Name',
        accessor: (d) => {
          return (
            <div className="form__group">
              <div className="form__group">
                <div className="row" />
                <input
                  type="text"
                  name="name"
                  className="form__control"
                  value={
                    !_.isNil(edits) &&
                    !_.isNil(edits[d.id]) &&
                    !_.isNil(edits[d.id]['name'])
                      ? edits[d.id]['name']
                      : d.name
                  }
                  onChange={(e) => this.handleInput(e, d.id)}
                  placeholder="Enter document name"
                  title="Document Name"
                  required
                />
                <div className="error" id="documentNameError" />
              </div>
            </div>
          )
        },
        sortable: false,
      },
      {
        id: 'progress',
        Header: 'Progress',
        className: 'flexbox',
        accessor: (d) => {
          return d.page_count > 0 && <Progress percent={100} />
        },
        sortable: false,
      },
      {
        id: 'split',
        Header: 'Manage',
        className: 'flexbox',
        width: 100,
        accessor: (d) => {
          return (
            <div className="flexbox">
              <button
                type="button"
                className="link"
                onClick={() => this.handleSplit(d.id, d.name, d.guid)}
              >
                <img src={document_split} alt="Document Split" />
              </button>
            </div>
          )
        },
        sortable: false,
      },
      {
        id: 'delete',
        Header: 'Delete',
        className: 'flexbox',
        width: 100,
        accessor: (d) => {
          return (
            <div className="flexbox">
              <button
                type="button"
                className="link"
                value={d.id}
                onClick={this.handleDelete}
              >
                <img src={document_delete} alt="Document Delete" />
              </button>
            </div>
          )
        },
        sortable: false,
      },
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        //
      }
    }

    const tableNoResults = () => {
      return (
        //
        <div />
      )
    }

    const tableData = this.props.documents

    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__three">
              <div className="tile">
                <div>
                  <div className="dashbaord__tile full">
                    <div className="inner__tile">
                      <button
                        type="button"
                        className="link right"
                        onClick={this.handleHideModal}
                      >
                        <img src={document_close} alt="DOcument Close" />
                      </button>
                      <h2>Add Document</h2>
                      <div className="docs__upload">
                        <div
                          className={
                            tableData.length > 0
                              ? 'doc__upload'
                              : 'inner no-padding'
                          }
                        >
                          <div>
                            <input
                              type="file"
                              id="file"
                              ref="fileUploader"
                              style={{display: 'none'}}
                              onChange={this.onChangeFile}
                            />

                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                this.handleUpload(acceptedFiles)
                              }
                              multiple={true}
                              clickable={false}
                              noClick={true}
                            >
                              {({getRootProps, getInputProps}) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <img
                                      src={document_upload}
                                      alt="DOcument Upload"
                                    />
                                    <p>
                                      Drag and drop to upload <br /> or{' '}
                                      <button
                                        type="button"
                                        className="link"
                                        onClick={this.didClickFileUploadBrowse}
                                      >
                                        browse
                                      </button>{' '}
                                      to choose a file(s).
                                    </p>
                                    <FormError error={this.state.error} />
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                        <ReactTableCommon
                          tableData={tableData}
                          columns={columns}
                          onRowClick={onRowClick}
                          tableNoResults={tableNoResults}
                          usesItemCountForPageSize={true}
                        />
                      </div>
                    </div>
                    {tableData.length > 0 && (
                      <div className="document_buttons full bottom">
                        <button
                          type="submit"
                          className="col-40 btn btn--mod-2 btn--green"
                          value="Done"
                          onClick={() =>
                            this.setState({showUploadCompleteWarning: true})
                          }
                        >
                          Done
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showUploadCompleteWarning && (
          <Modal
            title="Are you sure?"
            message="After clicking 'Done', documents can no longer be rotated for preparation purposes."
            error={null}
            action="Done"
            actionType={ACTION_SUCCESS}
            disabled={this.state.networkActive}
            onCancel={() => this.setState({showUploadCompleteWarning: false})}
            onAction={() => this.handleMultiDocumentUpdate()}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let documents = _.map(state.documents.data, (document, key) => document)
  documents = _.filter(
    documents,
    (document) =>
      `${document.transaction.id}` === `${ownProps.match.params.ti}` &&
      `${document.owner.id}` === `${state.account.userAccount.id}` &&
      document.upload_in_progress,
  )
  documents = _.sortBy(documents, (document) => document.display_order)

  return {
    account: state.account,
    edits: state.documents.edits,
    documents,
  }
}
const mapDispatchToProps = (dispatch) => ({
  updateDocumentEdits: (payload) => dispatch(updateDocumentEdits(payload)),
})

export default withApi(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentUpload)),
)
