import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import queryString from 'query-string'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {appPaths} from '../../utils/appPaths'
import {checkUserAccount} from '../../utils/loginToken'
import {jsonApiSpecToFlatObject} from '../../utils/jsonapispec'
// Components
import ReactTableCommon from '../common/ReactTableCommon'
// Images
import transactions_icon from '../../img/transactions_icon.svg'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'
import {
  buildColumnNa,
  buildColumnDate,
  buildColumn,
  buildTableEmpty,
} from '../../utils/table'
import Moment from 'react-moment'

class SearchTransaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      error: '',
      loading: true,
      userAccount: checkUserAccount(this),
    }
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search)
    const search = values.q

    this.setState(
      {
        search: search,
      },
      this.getTableData,
    )
  }

  componentDidUpdate() {
    const values = queryString.parse(this.props.location.search)
    const search = values.q

    if (this.state.search !== search) {
      this.setState(
        {
          search: search,
        },
        this.getTableData,
      )
    }
  }

  getTableData() {
    NProgress.start()

    this.setState({
      data: [],
      loading: true,
    })

    const companyId = this.state.userAccount.company.id
    const searchString = encodeURI(this.state.search)

    return this.props.api
      .transactionSearch(companyId, searchString)
      .then((response) => {
        let flatData = jsonApiSpecToFlatObject(
          response.data.data,
          response.data.included,
        )

        this.setState({
          data: flatData,
        })
      })
      .catch((error) => {
        this.setState({
          data: [],
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
        NProgress.done()
      })
  }

  render() {
    const format = 'MMM D, YYYY'
    const columns = [
      buildColumnNa('guid', 'Transaction ID'),
      buildColumnNa('name', 'Transaction Name'),
      buildColumn('created_at', 'Creation Date', null, (d) => {
        if (d.created_at === null || d.created_at === '') {
          return 'N/A'
        } else {
          return <Moment format={format}>{d.created_at.date}</Moment>
        }
      }),
      buildColumn('updated_at', 'Last Updated', null, (d) => {
        if (d.updated_at === null || d.updated_at === '') {
          return 'N/A'
        } else {
          return <Moment format={format}>{d.updated_at.date}</Moment>
        }
      }),
    ]

    const onRowClick = (state, rowInfo, column, instance) => {
      return {
        onClick: (e) => {
          this.props.history.push(appPaths.TransactionRead(rowInfo.original.id))
        },
      }
    }

    const tableNoResults = () => {
      return buildTableEmpty(transactions_icon, this.state.error)
    }

    const tableData = this.state.data

    return (
      <React.Fragment>
        <div>
          <h2>Results</h2>
          <div>
            <ReactTableCommon
              tableData={tableData}
              isLoading={this.state.loading}
              columns={columns}
              onRowClick={onRowClick}
              tableNoResults={tableNoResults}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
})

export default withApi(withRouter(connect(mapStateToProps)(SearchTransaction)))
