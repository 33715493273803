import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import NProgress from 'nprogress'
// Redux
import {connect} from 'react-redux'
// Utils
import {checkToken} from '../../utils/loginToken'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'
import _ from 'lodash'

class RoleReadAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loginToken: checkToken(this),
      roleName: props.value
    }
  }

  componentWillMount() {
    NProgress.start()

    this.props.api
      .roleReadAll()
      .catch((error) => {
        this.setState({
          error: networkErrorDetail(error),
        })
      })
      .finally(() => {
        NProgress.done()
      })
  }

  render() {
    return (
      <React.Fragment>
        <select name="roleId" onChange={this.handleRoleId}>
          {this.props.roles.map((role, i) => (
            <option key={i} value={role.id} selected={this.state.roleName===role.name}>
              {role.name}
            </option>
          ))}
        </select>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  roles: _.map(state.roles.data, (role, key) => role),
})

export default withApi(withRouter(connect(mapStateToProps)(RoleReadAll)))
