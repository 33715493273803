import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import queryString from 'query-string'
import NProgress from 'nprogress'
// Utils validate
import {appPaths} from '../../utils/appPaths'
import {validateInput, showFormErrors} from '../../utils/validate'
// Components
import FormError from '../common/FormError'
// Images
import hand_shake from '../../img/hand_shake.png'
import withApi from '../../api/withApi'
import {networkErrorDetail} from '../../utils/urls'

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      showPassword: true,
      showPasswordValue: false,
      passwordConfirm: '',
      showPasswordConfirm: true,
      showPasswordConfirmValue: false,
      error: '',
    }
  }

  componentWillMount() {
    const values = this.props.match.params;
    const email = values.e;
    const validation_code = values.vc;
    const password_reset_code = values.pr;

    if (email && validation_code) {
      NProgress.start()

      this.props.api.accountValidate(email, validation_code).finally(() => {
        NProgress.done()
      })

      if (password_reset_code) {
        this.props.history.push(
          appPaths.ValidateChangePassword + '/' + password_reset_code,
        )
      }
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    validateInput(this, e)

    // Show or hide "Show Password" feature
    // TODO: should be dynamic
    if (e.target.name === 'password') {
      if (e.target.value) {
        this.setState({showPassword: true})
      } else {
        this.setState({showPassword: false})
      }
    }

    if (e.target.name === 'passwordConfirm') {
      if (e.target.value) {
        this.setState({showPasswordConfirm: true})
      } else {
        this.setState({showPasswordConfirm: false})
      }
    }
  }

  // TODO: should be dynamic
  handleClick = (e) => {
    // Show or hide password value on click
    var passwordTypeValue = this.state.showPasswordValue
    if (passwordTypeValue === true) {
      this.setState({showPasswordValue: false})
    } else {
      this.setState({showPasswordValue: true})
    }
  }

  // TODO: should be dynamic
  handleClickConfirm = (e) => {
    // Show or hide password value on click
    var passwordTypeValue = this.state.showPasswordConfirmValue
    if (passwordTypeValue === true) {
      this.setState({showPasswordConfirmValue: false})
    } else {
      this.setState({showPasswordConfirmValue: true})
    }
  }

  handleHideModal = (e) => {
    this.props.history.push(appPaths.Login)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!showFormErrors(this)) {
      this.setState({
        error: 'Please correct form errors.',
      })
    } else {
      NProgress.start()

      this.props.api
        .passwordReset({
          code: this.props.match.params.pr,
          password: this.state.password,
        })
        .then((response) => {
          this.props.history.push(appPaths.PasswordChanged + '/1')
        })
        .catch((error) => {
          this.setState({
            error: networkErrorDetail(error),
          })
        })
        .finally(() => {
          NProgress.done()
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="fixed">
          <div className="row">
            <div className="modal modal__two">
              <button
                type="button"
                className="close link"
                onClick={this.handleHideModal}
              >
                <i className="fas fa-times" />
              </button>
              <div className="modal__form">
                <div className="modal__inner">
                  <h1>Change Password</h1>
                  <p>
                    Enter a new password. Must include at least one <br /> upper
                    case, numeric, and special character.
                  </p>
                  <br />
                  <form method="post" onSubmit={this.handleSubmit} noValidate>
                    <div className="form__group">
                      <div className="row">
                        <label id="passwordLabel" className="left">
                          New Password
                        </label>
                        {this.state.showPassword && (
                          <button
                            type="button"
                            className="link right small"
                            onClick={this.handleClick}
                            tabIndex="-1"
                          >
                            {this.state.showPasswordValue
                              ? 'Hide Password'
                              : 'Show Password'}
                          </button>
                        )}
                      </div>
                      <input
                        type={
                          this.state.showPasswordValue ? 'text' : 'password'
                        }
                        name="password"
                        ref="password"
                        value={this.state.password}
                        onChange={this.handleInput}
                        placeholder="Enter password"
                        title="Password"
                        pattern="((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,})"
                        required
                      />
                      <div className="error" id="passwordError" />
                    </div>

                    <div className="form__group">
                      <div className="row">
                        <label id="passwordConfirmLabel" className="left">
                          Confirm Password
                        </label>
                        {this.state.showPasswordConfirm && (
                          <button
                            type="button"
                            className="link right small"
                            onClick={this.handleClickConfirm}
                            tabIndex="-1"
                          >
                            {this.state.showPasswordConfirmValue
                              ? 'Hide Password'
                              : 'Show Password'}
                          </button>
                        )}
                      </div>
                      <input
                        type={
                          this.state.showPasswordConfirmValue
                            ? 'text'
                            : 'password'
                        }
                        name="passwordConfirm"
                        ref="passwordConfirm"
                        value={this.state.passwordConfirm}
                        onChange={this.handleInput}
                        placeholder="Enter password"
                        title="Password"
                        required
                      />
                      <div className="error" id="passwordConfirmError" />
                    </div>
                    <FormError error={this.state.error} />
                    <div className="form__group">
                      <div className="row">
                        <button
                          type="submit"
                          className="btn btn--blue-solid"
                          value="Log In"
                        >
                          Save Password
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal__mark">
                <img src={hand_shake} className="modal__img" alt="logo" />
                <div className="top__angle" />
                <div className="bottom__angle" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withApi(withRouter(ChangePassword))
